import React from 'react';
import { Form, Button, Badge, Modal as Mod } from 'react-bootstrap';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import logo from '../../img/logo.png';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Redirect } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { Card, CardBody, Col, Fade } from 'reactstrap';
import jsPDF from "jspdf";
import "jspdf-autotable";

export default class Busqueda extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            posicion_tab: "1",
            idtbl_campo: 0,
            alertEnvioExitoso: false,
            rut: "",
            navigate: "",
            mostrarModalInformacion: false,
            isLoading: false,
            mostrarModal: false,
            usuarioAutorizado: false,
            nombre: '',
            apellido: '',
            cargo: '',
            empresa: '',
            ingresos: [],
            pantete: '',
            temperatura: '',
            sucursales: [],
            id_sucursal: localStorage.getItem("id_sucursal"),
            mascarilla: '',
            fechaSelecta: moment().format("YYYY-MM-DD"),
            visita: '',
            num: '',
            observación: '',
            fechaSalida: '',
            Personas: []
        };
        this.obtenerNombreSucursal = this.obtenerNombreSucursal.bind(this);
        this.validaLogin = this.validaLogin.bind(this);
        this.usoMascarilla = this.usoMascarilla.bind(this);
        this.formatotemperatura = this.formatotemperatura.bind(this);
        this.guardarFecha = this.guardarFecha.bind(this);
        this.existeFactura= this.existeFactura.bind(this)
        this.marcarSalida = this.marcarSalida.bind(this);
        this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
        this.formatoh5 = this.formatoh5.bind(this);
        this.btnNuevoIngreso = this.btnNuevoIngreso.bind(this);
        this.guardarSalida = this.guardarSalida.bind(this);
        this.handleChangeFechaDesde = this.handleChangeFechaDesde.bind(this);
        this.obtenerIngresosDiarios = this.obtenerIngresosDiarios.bind(this);
        this.changeFecha = this.changeFecha.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.onBeforeSaveCell = this.onBeforeSaveCell.bind(this);
        this.existeEncuesta = this.existeEncuesta.bind(this);
        this.usoGuia = this.usoGuia.bind(this);
        this.abrirEncuesta = this.abrirEncuesta.bind(this);
        this.createCustomModalFooter = this.createCustomModalFooter.bind(this);
        this.createCustomModalBody = this.createCustomModalBody.bind(this);
        this.handleChangeCodigoDropBox = this.handleChangeCodigoDropBox.bind(this);
        this.traerSucursales = this.traerSucursales.bind(this);
        this.formatoEntrada = this.formatoEntrada.bind(this);
        this.changeMascarilla = this.changeMascarilla.bind(this);
        this.changeVisita = this.changeVisita.bind(this);
        this.changeNum = this.changeNum.bind(this)
    }

    componentDidMount() {
        this.validaLogin()
        this.traerSucursales();
    }


    traerSucursales() {
        var obj = {};
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/traerSucursales.php', obj).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                    this.setState({ sucursales: datos });
                };
            });
        } catch (error) {
            ////console.log(error);
        }

    }
    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            this.setState({ usuarioAutorizado: false });
        } else {
            this.setState({ usuarioAutorizado: true });
            this.obtenerIngresosDiarios();
        }
    }


    formatotemperatura(row) {
        let theButton;
         if (row > 38 ) {
            theButton = <Badge style={{"color":"white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#ce4552"}} pill variant="danger"> <h5> {row + "°" }  </h5> </Badge>
         }else {
            theButton = <Badge style={{"color":"white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964"}} pill variant="success"> <h5> {row + "°"}  </h5> </Badge>
        }
         return theButton;
      }


    handleChangeFechaDesde(event) {
        event.preventDefault();
        let valor = event.target.value;

        this.setState({ fechaSelecta: valor }, () => { this.obtenerIngresosDiarios() });

    }

    changeMascarilla( value, desc ) {
        for (var i in this.state.ingresos) {
          if (this.state.ingresos[i].mascarilla === value) {
             this.state.ingresos[i].mascarilla = desc;

          }
        }
    }

    changeNum( value, desc ) {
        for (var i in this.state.ingresos) {
          if (this.state.ingresos[i].num === value) {
             this.state.ingresos[i].num = desc;

          }
        }
    }
    changeVisita( value, desc ) {
        for (var i in this.state.ingresos) {
          if (this.state.ingresos[i].visita === value) {
             this.state.ingresos[i].visita = desc;
          }
        }
    }

    changeFecha( value, desc ) {
        for (var i in this.state.ingresos) {
          if (this.state.ingresos[i].fechaSalida === value) {
             this.state.ingresos[i].fechaSalida = desc;
          }
        }
    }

    abrirEncuesta(row) {
        let valor = row;
        const obj = { id: valor };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/traerEncuesta.php', obj, optionAxios).then(response => {
                const datos = response.data;
                if (datos[0] !== undefined) {
                    this.setState({ preguntaA: datos[0].preguntaA })
                    this.setState({ preguntaB: datos[0].preguntaB })
                    this.setState({ preguntaC: datos[0].preguntaC })
                    this.setState({ preguntaD: datos[0].preguntaD })
                    this.setState({ enfermedad: datos[0].enfermedad })
                    this.setState({ pais: datos[0].pais })
                    this.setState({ sintoma: datos[0].sintoma })

                };
            });
        } catch (error) {
            //////console.log(error);
        }

        //////console.log("abrir encueseta");
        this.setState({ mostrarModal: true });

    }

    existeEncuesta(row) {
        let valor = row
        let theButton;
        if (valor > 0) {
            theButton = <Button style={{ "width": "70%", "height": "80%" }} onClick={() => this.abrirEncuesta(row)}>VER </Button>
        } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d0" }} pill variant="success"> <h6> NO COMPLETADA </h6> </Badge>
        }
        return theButton;
    }

    handleChangeCodigoDropBox(event) {
        event.preventDefault();
        let valor = event.target.value
        this.setState({ id_sucursal: valor }, () => { this.obtenerIngresosDiarios() });
    }

    async obtenerIngresosDiarios() {
        const obj = { fecha: this.state.fechaSelecta, id_sucursal: this.state.id_sucursal };
  try {
            await axios.post('https://accesos.sanjoseapps.cl/api/traerVehiculoFecha.php', obj).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                    ////console.log(datos)
                    this.setState({ ingresos: datos })
                };
            });
        } catch (error) {
            //  ////console.log(error);
        }
    }

    usoMascarilla(row) {
        let theButton;
        if (row === '0') {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#e89f4d" }} pill variant="danger"> <h5> NO </h5> </Badge>
        } else if (row === '1') {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964" }} pill variant="success"> <h5> SI </h5> </Badge>
        }
        return theButton;

    }


    guardarSalida(row) {
        let valor = row;
        const obj = { id: valor, fecha: moment().format("YYYY-MM-DD HH:mm:ss") };
        ////console.log(obj);
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/marcarSalida.php', obj).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                    ////console.log(datos);
                };
            });
        } catch (error) {
            ////console.log(error);
        }
        this.obtenerIngresosDiarios();
    }


    marcarSalida(row) {
        let valor = row;
        let theButton;
        theButton = <Button onClick={() => this.guardarSalida(valor)}  > SALIDA</Button>
        return theButton;

    }

    guardarFecha(row) {
        let theButton;
        if (row === '30-11--0001 00:00') {
            theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#e89f4d" }} pill variant="danger">  <h6>  Sin salida marcada </h6>  </Badge>
        } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em" }} pill variant="success"> <h6> {row} </h6> </Badge>
        }
        return theButton;
    }

    formatoh5(row) {
        let columna;
        columna = <Badge> <h5> {row} </h5> </Badge>

        return columna;
    }


    formatoEntrada(row) {
        let columna;
         columna =  <Badge> <h5> {row.format("YYYY-MM-DD hh:mm")} </h5> </Badge>
         return columna;
      }

    obtenerNombreSucursal(id){
           for (var i in this.state.sucursales) {
              if (this.state.sucursales[i].id_sucursal === id) {
                return this.state.sucursales[i].nombre

              }
            }
    }

    usoGuia(row) {
        let valor = row
        let theButton;
        if (valor === null || valor === undefined) {
          valor = ''
        }
        try {
          if (valor.length > 0) {
            theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em" }} pill variant="success"> <h6> N° {valor} </h6> </Badge>
          } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d" }} pill variant="success"> <h6> Sin Guia </h6> </Badge>
          }
        } catch (error) {
          theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d" }} pill variant="success"> <h6> Sin Guia </h6> </Badge>
        }
        return theButton;
      }

    onAfterSaveCell(row, cellName, cellValue) {
        alert(`El campo ${cellName} actualizado al valor  ${cellValue}`);
        /* let rowStr = '';
         for (const prop in row) {
           rowStr += prop + ': ' + row[prop] + '\n';
         }
         alert('Thw whole row :\n' + rowStr);
       */

    }

    existeFactura(row){
        let valor = row
        if (valor === null || valor === undefined){
            valor=''
        }
        let theButton;
        try {
          if( valor.length > 0){
            theButton = <Badge style={{"color":"white", "padding": "0.8em 1.3em 1em 1.3em"}} pill variant="success"> <h6> {valor} </h6> </Badge>
        }else{
          theButton = <Badge style={{"color":"white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d"}} pill variant="success"> <h6> Sin factura </h6> </Badge>
        }
            } catch (error) {
          theButton = <Badge style={{"color":"white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d"}} pill variant="success"> <h6> Sin factura </h6> </Badge>

        }
         return theButton;
    }

    onBeforeSaveCell(row, cellName, cellValue) {
        if (cellName === 'rut') {
            this.setState({ "rut": cellValue });
        }
        if (cellName === 'nombre') {
            this.setState({ "nombre": cellValue });
        }
        if (cellName === 'apellido') {
            this.setState({ "apellido": cellValue });
        }
        if (cellName === 'cargo') {
            this.setState({ "cargo": cellValue });
        }
        if (cellName === 'empresa') {
            this.setState({ "empresa": cellValue });
        }
        if (cellName === 'pantete') {
            this.setState({ "pantete": cellValue });
        }
        if (cellName === 'temperatura') {
            this.setState({ "temperatura": cellValue });
        }
        if (cellName === 'mascarilla') {
            this.setState({ "mascarilla": cellValue });
        }
        if (cellName === 'visita') {
            this.setState({ "visita": cellValue });
        }
        if (cellName === 'num') {
            this.setState({ "num": cellValue });
        }
        if (cellName === 'observación') {
            this.setState({ "observación": cellValue });
        }
        return true;

    }

    btnNuevoIngreso = (onClick) => {
        return (
            <Button onClick={onClick}>Nuevo Ingreso</Button>
        );
    }

    createCustomModalBody = (columns, validateState, ignoreEditable) => {

    }

    createCustomModalHeader(onClose, onSave) {
        const headerStyle = {
            fontWeight: 'bold',
            fontSize: 'large',
            textAlign: 'center',
            backgroundColor: '#eeeeee'
        };
        return (
            <div className='modal-header' style={headerStyle}>
                <h3>Nuevo Ingreso</h3>
                <button className='btn btn-info' onClick={onClose}> X </button>
            </div>
        );
    }
    //...
    beforeClose(e) {
        alert(`[Custom Event]: Modal close event triggered!`);
    }

    beforeSave(e) {
        alert(`[Custom Event]: Modal save event triggered!`);
    }

    handleModalClose(closeModal) {
        // Custom your onCloseModal event here,
        // it's not necessary to implement this function if you have no any process before modal close
        ////console.log('This is my custom function for modal close event');
        closeModal();
    }

    handleSave(save) {
        // Custom your onSave event here,
        // it's not necessary to implement this function if you have no any process before save
        ////console.log('This is my custom function for save event');
        save();
    }

    createCustomModalFooter = (onClose, onSave) => {
        const style = {
            backgroundColor: '#ffffff'
        };
        return (
            <div className='modal-footer' style={style}>
                <button className='btn btn-xs btn-info' onClick={onClose}>Cerrar</button>
                <button className='btn btn-xs btn-danger' onClick={onSave}>Confirmar</button>
            </div>
        );
    }
    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const doc = new jsPDF(orientation, unit, size);
        //doc.addImage(logo, "JPG", 700, 5, 110, 70);
        doc.setFontSize(10);
        var nombre_sucursal = this.obtenerNombreSucursal(this.state.id_sucursal);
        const empresa = "San José Farms";
            const sistema = "Sistema de acceso ";
            const sucursal = "Sucursal " + nombre_sucursal;
        const title = "Reporte del día " + this.state.fechaSelecta;
        const version = "Código: PL.SS.5.2  Versión: 04 " ;

        this.changeMascarilla("0",'NO');
        this.changeMascarilla("1",'SI');
        this.changeNum("0",'NO');
        this.changeNum("1",'SI');
        this.changeVisita("0",'NO');
        this.changeVisita("1",'SI');
        this.changeFecha('30-11--0001 00:00','SIN MARCAR')

       const headers = [[ "LLAVE", "HORA ENTREGA ", "HORA DEVOLUCION", "NOMBRE", "APELLIDO", "CARGO", "EMPRESA", "SUCURSAL"]];
        const data = this.state.ingresos.map(elt => [elt.nombre_llave, elt.horaSalida, elt.horaEntrega, elt.nombre, elt.apellido, elt.cargo, elt.empresa, elt.sucursal]);
        let content = {
            startY: 120,
            head: headers,
            columnStyles: {
                0: {
                    columnWidth: 90
                },
                1: {
                    columnWidth: 90
                },
                2: {
                    columnWidth: 90
                },
                3: {
                    columnWidth: 75
                },
                4: {
                    columnWidth: 75
                },
                5: {
                    columnWidth: 90
                },
                6: {
                    columnWidth: 90
                },
                8: {
                    columnWidth: 60
                },
                9: {
                    columnWidth: 50
                }
            },
            didDrawPage: function (data) {
                // Header
                doc.addImage(logo, 'JPG', 700, 5, 130, 90)
                var str = 'Página ' + doc.internal.getNumberOfPages()
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' de ' + doc.internal.getNumberOfPages()
                }
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                doc.text(str, data.settings.margin.left, pageHeight - 10)
            },
            margin: { top: 95 },
            body: data, theme: 'grid'
        };
        doc.setFontSize(20);
        doc.text(empresa, 40, 30);
        doc.setFontSize(15);
        doc.text(sistema, 40, 50);
        doc.setFontSize(18);
        doc.text(sucursal, 350, 30);
        doc.setFontSize(13);
        doc.text(title, 40, 65);
        doc.setFontSize(8);
        doc.text(version, 700, 90);
        doc.setFontSize(8);
        doc.autoTable(content);
        doc.save("Llaves_"+nombre_sucursal+ "_"+this.state.fechaSelecta+".pdf")
    }


    render() {

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: this.onBeforeSaveCell, // a hook for before saving cell
            afterSaveCell: this.onAfterSaveCell  // a hook for after saving cell
        };

        //...
        const options = {
            insertBtn: this.btnNuevoIngreso
            , insertModalBody: this.createCustomModalBody
            , insertModalHeader: this.createCustomModalHeader
            , insertModalFooter: this.createCustomModalFooter
        };

        var login = localStorage.getItem('idtbl_usuario');
        if (login === null || login === "undefined") {
            return <Redirect to="/" push={true} />
        } else {
            return (
                <React.Fragment>
                    <Col md={12} lg={12}>
                        <Card>

                            {
                                this.state.isLoading === true ?
                                    (<section className="table table-responsive mt-md-5">
                                        <div className="container table">
                                            <div align="center">
                                                <Spinner type="grow" color="success" /> </div>
                                        </div>
                                    </section>)
                                    :
                                    (
                                        <Fade in={true} tag="h5" className="mt-3" transition={{ in: true, timeout: 750 }} >
                                            <CardBody>
                                                <div className="card__title">
                                                    <h5 className="bold-text"> Búsqueda de vehiculos por fechas </h5>
                                                </div>
                                                <div className="form__form-group" style={{ "container": "wrap" }} >
                                                    <div className="row">


                                                        <h3 style={{ "margin": "2px 10px 6px 6px" }}> Fecha Búsqueda</h3>
                                                        <Form.Group controlId="dob">
                                                            <Form.Control type="date" defaultValue={localStorage.getItem('fechaServidor')} max={localStorage.getItem('fechaServidor')} min='2020-12-01' name="dob" onChangeCapture={this.handleChangeFechaDesde} />
                                                        </Form.Group>
                                                        {localStorage.getItem('tipo_usuario') === "1" ?    <h3 style={{ "margin": "2px 10px 6px 6px" }}> SUCURSAL</h3> :""}
                                                        {localStorage.getItem('tipo_usuario') === "1" ?

                                                                <div>
                                                                    <Form.Control as="select" id={"2"} onChangeCapture={this.handleChangeCodigoDropBox} value={this.state.id_sucursal} >
                                                                        < option key={0} value={-1}> {"Seleccione Sucursal"} </option>
                                                                        {this.state.sucursales.map((r) => {
                                                                            return (< option key={r.id_sucursal} value={r.id_sucursal}> {r.nombre} </option>)
                                                                        })}
                                                                    </Form.Control>

                                                                </div>
                                                            : ""}
                                                    </div>
                                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                                    </div>
                                                </div>
                                                    <div className="form__form-group" style={{ "container": "wrap" }} >
                                                    <div className="table" style={{ "margin": "auto", "fontSize": "0.7rem" }} >
                                                        <BootstrapTable scrollTop={'Bottom'} wrapperClasses="table-responsive" data={this.state.ingresos} cellEdit={cellEditProp} options={options} search={true} searchPlaceholder={"Texto para búsqueda..."} hover pagination >
                                                        <TableHeaderColumn width='90' isKey dataField='id' hiddenOnInsert hidden={true} >ID</TableHeaderColumn>
                                                          <TableHeaderColumn width='150' dataFormat={this.formatoh5} dataField='matricula'  dataSort={ true } > <h5> MATRICULA </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='150' dataFormat={this.formatoh5} editable={false}  dataSort={ true } dataField='fecha_ingreso' > <h5>  FECHA INGRESO </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='120' dataFormat={this.formatoh5}  dataSort={ true } dataField='fecha_egreso' > <h5> FECHA SALIDA </h5> </TableHeaderColumn>

                                                            <TableHeaderColumn width='120' dataFormat={this.formatoh5} editable={false} dataSort={ true } dataField='rut'> <h5> RUT CONDUCTOR </h5></TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataFormat= {this.formatoh5} dataSort={ true }dataField='empresa'> <h5> CLIENTE</h5></TableHeaderColumn>
                                                        <TableHeaderColumn width='110' dataFormat= {this.formatoh5}  dataSort={ true } dataField='area'> <h5> AREA </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' editable={ false } dataField='factura' dataFormat={this.existeFactura} dataSort={ true }  hiddenOnInsert > <h5> FACTURA  </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataSort={ true } dataField='guia' dataFormat={this.usoGuia} > <h5> GUIA </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataSort={ true } dataField='kg' dataFormat={this.formatoh5} > <h5> KG ENTRADA </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataFormat= {this.formatoh5 } dataSort={ true } dataField='detalle' > <h5> DETALLE </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataFormat= {this.formatoh5}  dataField='observación'  hidden={true} > <h5> OBSERVACIÓN </h5>  </TableHeaderColumn>
                                                      <TableHeaderColumn width='120' editable={ false } dataField='factura_egreso' dataFormat={this.existeFactura} dataSort={ true }  hiddenOnInsert > <h5> FACTURA SALIDA  </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataSort={ true } dataField='guia_egreso' dataFormat={this.usoGuia} > <h5> GUIA SALIDA </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='120' dataSort={ true } dataField='kg_egreso' dataFormat={this.formatoh5} > <h5> KG SALIDA </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataFormat= {this.formatoh5 } dataSort={ true } dataField='detalle_egreso' > <h5> DETALLE SALIDA </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataFormat= {this.formatoh5}  dataField='observación'  hidden={true} > <h5> OBSERVACIÓN </h5>  </TableHeaderColumn>


                                                        </BootstrapTable>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Fade>
                                    )
                            }
                        </Card>
                    </Col>
                </React.Fragment >
            )
        }
    }
}


