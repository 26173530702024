import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import svgiconproduct from '../../img/logo_san_jose_blanco.png';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };


  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div className="topbar">
        
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link to="/bandeja"  >
            
          <img src={svgiconproduct} alt="" style={{"width":"75px"}}   />      
         </Link>   

         </div>
         
        <div className="topbar__right">
         
 
              <TopbarProfile />

        </div>
      </div>
    );
  }
}

export default Topbar;
