import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CambioContraseña from './components/CambioContraseña';

const CambiarContraseña = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>
      <CambioContraseña />
    </Row>
  </Container>
);

export default CambiarContraseña;
