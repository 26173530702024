import React from 'react';
import { Form, Button, Badge, Modal as Mod } from 'react-bootstrap';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Redirect } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { Card, Col, Fade } from 'reactstrap';
import ModalIngreso from './ModalIngreso';

export default class Bandeja_entrada extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            posicion_tab: "1",
            idtbl_campo: 0,
            alertEnvioExitoso: false,
            rut: "",
            navigate: "",
            mostrarModalInformacion: false,
            isLoading: false,
            usuarioAutorizado: false,
            nombre: '',
            apellido: '',
            carnombrego: '',
            mostrarModal: false,
            empresa: '',
            pantete: '',
            temperatura: '',
            mascarilla: '',
            visita: '',
            num: '',
            observación: '',
            ingresos: [],
            fechaSalida: '',
            preguntaA: '',
            pregunta_F: '0',
            preguntaB: '',
            preguntaC: '',
            preguntaD: '',
            sintomas: '',
            enfermedad: '',
            pais: '',
            Personas: []
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.usoMascarilla = this.usoMascarilla.bind(this);
        this.buscarEncuestaManual = this.buscarEncuestaManual.bind(this);
        this.guardarFecha = this.guardarFecha.bind(this);
        this.marcarSalida = this.marcarSalida.bind(this);
        this.abrirEncuesta = this.abrirEncuesta.bind(this);
        this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
        this.formatoh5 = this.formatoh5.bind(this);
        this.btnNuevoIngreso = this.btnNuevoIngreso.bind(this);
        this.guardarSalida = this.guardarSalida.bind(this);
        this.obtenerIngresosDiarios = this.obtenerIngresosDiarios.bind(this);
        this.onBeforeSaveCell = this.onBeforeSaveCell.bind(this);
        this.existeEncuesta = this.existeEncuesta.bind(this);
        this.formatotemperatura = this.formatotemperatura.bind(this);
        this.createCustomModalFooter = this.createCustomModalFooter.bind(this);
        this.createCustomModalBody = this.createCustomModalBody.bind(this);
        this.formatoEntrada = this.formatoEntrada.bind(this);
    }

    componentDidMount() {
        this.validaLogin()
        this.setState({ preguntaA: this.props.preguntaA });
        this.setState({ preguntaB: this.props.preguntaB });
        this.setState({ preguntaC: this.props.preguntaC });
        this.setState({ preguntaD: this.props.preguntaD });
        this.setState({ nombre: this.props.control_entrada });
        this.setState({ pantete: this.props.vilkun });
        this.setState({ enfermedad: this.props.secos });
        this.setState({ pais: this.props.pais });
        this.setState({ apellido: this.props.apellido });
        this.setState({ pais: this.props.pais });
        this.setState({ rut: this.props.rut });
        this.setState({ idtbl_usuario: this.props.idtbl_usuario });
    }

    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            this.setState({ usuarioAutorizado: false });
        } else {
            this.setState({ usuarioAutorizado: true });
            this.obtenerIngresosDiarios();
        }
    }

    obtenerIngresosDiarios() {
        const obj = { fecha: moment().format("YYYY-MM-DD HH:mm:ss"), id_sucursal: localStorage.getItem("id_sucursal") };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/traerIngresosDiarios.php', obj, optionAxios).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                    console.log(datos);
                    this.setState({ ingresos: datos })
                };
            });
        } catch (error) {
            //  //console.log(error);
        }

    }

    usoMascarilla(row) {
        let theButton;
        if (row === '0') {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#e89f4d" }} pill variant="danger"> <h5> NO </h5> </Badge>
        } else if (row === '1') {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964" }} pill variant="success"> <h5> SI </h5> </Badge>
        }
        return theButton;

    }

    abrirEncuesta(row) {
        let valor = row;
        const obj = { id: valor };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/traerEncuesta.php', obj, optionAxios).then(response => {
                const datos = response.data;
                if (datos[0] !== undefined) {
                    this.setState({ preguntaA: datos[0].preguntaA })
                    this.setState({ preguntaB: datos[0].preguntaB })
                    this.setState({ preguntaC: datos[0].preguntaC })
                    this.setState({ preguntaD: datos[0].preguntaD })
                    this.setState({ enfermedad: datos[0].enfermedad })
                    this.setState({ pais: datos[0].pais })
                    this.setState({ sintoma: datos[0].sintoma })
                };
            });
        } catch (error) {
            //console.log(error);
        }
        this.setState({ mostrarModal: true });
    }

    buscarEncuestaManual(value) {
        for (var i in this.state.ingresos) {
            if (this.state.ingresos[i].id_encuesta === value) {

                return this.state.ingresos[i].manual;

            }

        }
    }


    existeEncuesta(row) {
        let valor = row
        let theButton;
        let manual = this.buscarEncuestaManual(valor)
        console.log(manual)
        if (valor > 0) {
            if (manual !== "1") {
                theButton = <Button style={{ "width": "70%", "height": "80%" }} onClick={() => this.abrirEncuesta(row)}>VER </Button>
            } else {

                theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em" }} pill variant="success"> <h6> ENCUESTA MANUAL </h6> </Badge>

            }
        } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d" }} pill variant="success"> <h6> NO COMPLETADA </h6> </Badge>
        }
        return theButton;
    }

    guardarSalida(row) {
        let valor = row;
        const obj = { id: valor, fecha: moment().format("YYYY-MM-DD hh:m:ss") };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/marcarSalida.php', obj, optionAxios).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                    //console.log(datos);
                };
            });
        } catch (error) {
            //console.log(error);
        }
        this.obtenerIngresosDiarios();
    }

    marcarSalida(row) {
        let valor = row;
        let disable = false
        this.state.ingresos.forEach(element => {
            if (element.id === row) {
                let fecha = element.fechaSalida;
                if (fecha !== '30-11--0001 00:00') {
                    disable = true;
                }
            }
        });
        let theButton;
        theButton = <Button style={{ "width": "90%", "height": "80%" }} disabled={disable} onClick={() => this.guardarSalida(valor)}  > SALIDA</Button>
        return theButton;
    }

    guardarFecha(row) {
        let theButton;
        if (row === '30-11--0001 00:00') {
            theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#e89f4d" }} pill variant="danger">  <h6>  Sin salida marcada </h6>  </Badge>
        } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em" }} pill variant="success"> <h6> {row} </h6> </Badge>
        }
        return theButton;
    }

    formatoh5(row) {
        let columna;
        columna = <Badge> <h5> {row} </h5> </Badge>
        return columna;
    }

    formatoEntrada(row) {
        let columna;
        columna = <Badge> <h5> {row.format("YYYY-MM-DD HH:mm")} </h5> </Badge>
        return columna;
    }



    formatotemperatura(row) {
        let theButton;
        if (row > 38) {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#ce4552" }} pill variant="danger"> <h5> {row + "°"}  </h5> </Badge>
        } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964" }} pill variant="success"> <h5> {row + "°"}  </h5> </Badge>
        }
        return theButton;
    }


    onAfterSaveCell(row, cellName, cellValue) {
        alert(`El campo ${cellName} actualizado al valor  ${cellValue}`);
    }

    onBeforeSaveCell(row, cellName, cellValue) {
        if (cellName === 'rut') {
            this.setState({ "rut": cellValue });
        }
        if (cellName === 'nombre') {
            this.setState({ "nombre": cellValue });
        }
        if (cellName === 'apellido') {
            this.setState({ "apellido": cellValue });
        }
        if (cellName === 'cargo') {
            this.setState({ "cargo": cellValue });
        }
        if (cellName === 'empresa') {
            this.setState({ "empresa": cellValue });
        }
        if (cellName === 'pantete') {
            this.setState({ "pantete": cellValue });
        }
        if (cellName === 'temperatura') {
            this.setState({ "temperatura": cellValue });
        }
        if (cellName === 'mascarilla') {
            this.setState({ "mascarilla": cellValue });
        }
        if (cellName === 'visita') {
            this.setState({ "visita": cellValue });
        }
        if (cellName === 'num') {
            this.setState({ "num": cellValue });
        }
        if (cellName === 'observación') {
            this.setState({ "observación": cellValue });
        }
        return true;

    }

    btnNuevoIngreso = (onClick) => {
        return (
            <Button onClick={onClick}>Nuevo Ingreso</Button>
        );
    }

    createCustomModalBody = (columns, validateState, ignoreEditable) => {
        return (
            <ModalIngreso columns={columns}
                validateState={validateState}
                ignoreEditable={ignoreEditable} />
        );
    }

    createCustomModalHeader(onClose, onSave) {
        const headerStyle = {
            fontWeight: 'bold',
            fontSize: 'large',
            textAlign: 'center',
            backgroundColor: '#eeeeee'
        };
        return (
            <div className='modal-header' style={headerStyle}>
                <h3>Nuevo Ingreso</h3>
                <button className='btn btn-info' onClick={onClose}> X </button>
            </div>
        );
    }

    createCustomModalFooter = (onClose, onSave) => {
        const style = {
            backgroundColor: '#ffffff'
        };
        return (
            <div className='modal-footer' style={style}>
                <button className='btn btn-xs btn-info' onClick={onClose}>Cerrar</button>
                <button className='btn btn-xs tn-danger' onClick={onSave}> Confirmar </button>
            </div>
        );
    }

    render() {


        //...
        const options = {
            insertBtn: this.btnNuevoIngreso
            , insertModalBody: this.createCustomModalBody
            , insertModalHeader: this.createCustomModalHeader
            , insertModalFooter: this.createCustomModalFooter
        };

        //
        var login = localStorage.getItem('idtbl_usuario');

        if (login === null || login === "undefined") {
            return <Redirect to="/" push={true} />
        } else {
            return (
                <React.Fragment>
                    <Col md={12} lg={12}>
                        <Card>
                            {
                                this.state.isLoading === true ?
                                    (<section className="table table-responsive mt-md-5">
                                        <div className="container table">
                                            <div align="center">
                                                <Spinner type="grow" color="success" /> </div>
                                        </div>
                                    </section>
                                    )
                                    :
                                    (
                                        <Fade in={true} tag="h5" className="mt-3" transition={{ in: true, timeout: 750 }} >
                                            <Mod show={this.state.mostrarModal} onHide={() => this.setState({ mostrarModal: false })} keyboard={false}>
                                                <Mod.Header closeButton>
                                                    <Mod.Title>
                                                        <h5 style={{ "text-align": "right" }} className="bold-text">    {localStorage.getItem("id_sucursal") === "1" ? "Código: SCG-RG 2 POE 8" : "Código: SGSST-FOR-34  Versión: 02"}    </h5>
                                                        CUESTIONARIO INGRESO A PLANTA (CONTINGENCIA COVID-19)
                                                    </Mod.Title>
                                                </Mod.Header>
                                                <Mod.Body>
                                                    <div key={1} className="mb-3">
                                                        <p>
                                                            <h4>  A): ¿Presenta alguna enfermedad infectocontagiosa? Si es afirmativo, indique cuál  &nbsp;&nbsp;
                                                                {this.state.preguntaA === "1" ?
                                                                    <Form.Check label="SI" checked inline name="formHorizontalRadios" id="formHorizontalRadios1" disabled type="radio" />
                                                                    : <Form.Check label="NO" checked inline name="formHorizontalRadios" id="formHorizontalRadios1" disabled type="radio" />
                                                                }
                                                                <br></br>
                                                                <Form.Control type="text" maxLength="250" field="rut" placeholder="DESCRIBIR ENFERMEDAD INFECTOCONTAGIOS" value={this.state.enfermedad} disabled ref={'rut'} />
                                                            </h4>
                                                        </p>
                                                    </div>
                                                    <br></br>
                                                    <div key={2} className="mb-3">
                                                        <p>
                                                            <h4>  B): ¿Ha estado en contacto con alguna persona que tenga sospecha o este contagiado con Covid-19?  &nbsp;&nbsp;
                                                                {this.state.preguntaB === "1" ?
                                                                    <Form.Check label="SI" inline name="formHorizontalRadiosb" disabled checked id="formHorizontalRadios1" type="radio" />
                                                                    : <Form.Check label="NO" inline name="formHorizontalRadiosb" disabled checked id="formHorizontalRadios1" type="radio" />
                                                                }
                                                            </h4>
                                                        </p>
                                                    </div>
                                                    <br></br>
                                                    <div key={3} className="mb-3">
                                                        <p>
                                                            <h4>   C): En las ultimas 2 semanas ¿Ha viajado fuera del pais?. Si su respuesta es afirmativa indique que pais:  &nbsp;&nbsp;
                                                                {this.state.preguntaC === "1" ?
                                                                    <Form.Check label="SI" inline checked name="formHorizontalRadiosc" disabled id="formHorizontalRadios1" type="radio" />
                                                                    : <Form.Check label="NO" inline checked name="formHorizontalRadiosc" disabled id="formHorizontalRadios1" type="radio" />
                                                                }
                                                                <br></br>
                                                                <Form.Control type="text" maxLength="50" field="rut" value={this.state.pais} placeholder="INDICAR PAIS" disabled ref={'rut'} />
                                                            </h4>
                                                        </p>
                                                    </div>
                                                    <br></br>
                                                    <div key={4} className="mb-3">
                                                        <p>
                                                            <h4> D): ¿Presenta ud., alguno de los siguientes sintomas: Tos seca, Fiebre sobre 38°C o secreción nazal?. Si es afirmativo indique cuál  &nbsp;&nbsp;
                                                                {this.state.preguntaD === "1" ?
                                                                    <Form.Check label="SI" checked inline name="formHorizontalRadiosd" id="formHorizontalRadios1" disabled type="radio" />
                                                                    : <Form.Check label="NO" checked inline name="formHorizontalRadiosd" id="formHorizontalRadios1" disabled type="radio" />
                                                                }
                                                                <br></br>
                                                                <Form.Control type="text" maxLength="250" placeholder="INDICAR SINTOMAS" value={this.state.sintoma} disabled={true} />
                                                            </h4>
                                                        </p>
                                                    </div>
                                                </Mod.Body>
                                                <Mod.Footer>
                                                    <Button variant="secondary" id="Cancelar" onClick={() => this.setState({ mostrarModal: false })}>
                                                        Cancelar
                                                    </Button>
                                                </Mod.Footer>
                                            </Mod>

                                        </Fade>
                                    )
                            }
                        </Card>
                    </Col>
                </React.Fragment >
            )
        }
    }
}
