import React from 'react';
import {Form, Badge, Button, Modal as Mod } from 'react-bootstrap';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Redirect } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { CardBody, Fade } from 'reactstrap';
import ModalIngreso from './ModalIngreso';
import ModalIngresoQR from './ModalIngresoQR';
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from '../../img/logo.png';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

export default class Comedor_entrada extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            posicion_tab: "1",
            idtbl_campo: 0,
            alertEnvioExitoso: false,
            rut: "",
            navigate: "",
            mostrarModalInformacion: false,
            isLoading: false,
            usuarioAutorizado: false,
            nombre: '',
            apellido: '',
            cargo: '',
            mostrarModal: false,
            mostrarNormal : false,
            mostrarModalEncuesta: false,
            empresa: '',
            pantete: '',
            temperatura: '',
            mascarilla: '',
            visita: '',
            num: '',
            observación: '',
            ingresos: [],
            fechaSalida: '',
            preguntaA: '',
            preguntaB: '',
            preguntaC: '',
            mostrarQR : false,
            preguntaD: '',
            sintomas: '',
            enfermedad: '',
            pais: '',
            Personas: []
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.usoMascarilla = this.usoMascarilla.bind(this);
        this.buscarEncuestaManual = this.buscarEncuestaManual.bind(this);
        this.guardarFecha = this.guardarFecha.bind(this);
        this.marcarSalida = this.marcarSalida.bind(this);
        this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
        this.obtenerNombreSucursal = this.obtenerNombreSucursal.bind(this);
        this.formatoh5 = this.formatoh5.bind(this);
        this.mostrarIngresoTarjetaYNormal = this.mostrarIngresoTarjetaYNormal.bind(this);
        this.btnNuevoIngreso = this.btnNuevoIngreso.bind(this);
        this.createCustomModalFooter = this.createCustomModalFooter.bind(this);
        this.createCustomModalBody = this.createCustomModalBody.bind(this);
        this.mostrarIngresoNormal = this.mostrarIngresoNormal.bind(this)
        this.guardarSalida = this.guardarSalida.bind(this);
        this.obtenerIngresosDiarios = this.obtenerIngresosDiarios.bind(this);
        this.onBeforeSaveCell = this.onBeforeSaveCell.bind(this);
        this.formatotemperatura = this.formatotemperatura.bind(this);
        this.mostrarIngresoTarjeta = this.mostrarIngresoTarjeta.bind(this)
        this.formatoEntrada = this.formatoEntrada.bind(this);
    }

    componentDidMount() {
        this.validaLogin()
    }

    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            this.setState({ usuarioAutorizado: false });
        } else {
            this.setState({ usuarioAutorizado: true });
            this.obtenerIngresosDiarios();
        }
    }
    obtenerNombreSucursal(id){
        for (var i in this.state.sucursales) {
           if (this.state.sucursales[i].id_sucursal === id) {
             return this.state.sucursales[i].nombre
           }
         }
 }



    obtenerIngresosDiarios() {
        const obj = { fecha: moment().format("YYYY-MM-DD HH:mm:ss"), id_sucursal: localStorage.getItem("id_sucursal") };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/traerPersonasComedor.php', obj, optionAxios).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                    this.setState({ ingresos: datos })
                };
            });
        } catch (error) {

        }
    }
    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const doc = new jsPDF(orientation, unit, size);
        //doc.addImage(logo, "JPG", 700, 5, 110, 70);
        doc.setFontSize(10);
    //    var nombre_sucursal = this.obtenerNombreSucursal(this.state.id_sucursal);
        const empresa = "San José Farms";
            const sistema = "Sistema de acceso ";
            const sucursal = "Sucursal San Felipe"
        const title = "Ingreso al casino";
        const version = " " ;


       const headers = [["N°", "ENTRADA ", "RUT", "NOMBRE","AREA" ]];
        const data = this.state.ingresos.map(elt =>[elt.numero , elt.fecha, elt.rut, elt.persona, elt.area]);
        let content = {
            startY: 120,
            head: headers,
            didDrawPage: function (data) {
                // Header
                doc.addImage(logo, 'JPG', 700, 5, 130, 90)
                var str = 'Página ' + doc.internal.pageSize
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' de ' + doc.internal.getNumberOfPages()
                }
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                doc.text(str, data.settings.margin.left, pageHeight - 10)
            },
            margin: { top: 95 },
            body: data, theme: 'grid'
        };
        doc.setFontSize(20);
        doc.text(empresa, 40, 30);
        doc.setFontSize(15);
        doc.text(sistema, 40, 50);
        doc.setFontSize(18);
        doc.text(sucursal, 350, 30);
        doc.setFontSize(13);
        doc.text(title, 40, 65);
        doc.setFontSize(8);
        doc.text(version, 700, 90);
        doc.setFontSize(8);
        doc.autoTable(content);
        doc.save("IngresoCasino_SanFelipe.pdf")
    }


    formateaRut(rut) {
        if (rut !== undefined) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 === 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            return rutPuntos

        }
    }

    usoMascarilla(row) {
        let theButton;
        if (row === '0') {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#e89f4d" }} pill variant="danger"> <h5> NO </h5> </Badge>
        } else if (row === '1') {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964" }} pill variant="success"> <h5> SI </h5> </Badge>
        }
        return theButton;
    }

    buscarEncuestaManual(value) {
        for (var i in this.state.ingresos) {
            if (this.state.ingresos[i].id_encuesta === value) {
                return this.state.ingresos[i].manual;
            }

        }
    }

    guardarSalida(row) {
        let valor = row;
        const obj = { id: valor, fecha: moment().format("YYYY-MM-DD hh:mm:ss") };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/marcarSalida.php', obj, optionAxios).then(response => {
                const datos = response.data;
                if (datos !== undefined) {

                };
            });
        } catch (error) {
        }
        this.obtenerIngresosDiarios();
    }

    marcarSalida(row) {
        let valor = row;
        let disable = false
        this.state.ingresos.forEach(element => {
            if (element.id === row) {
                let fecha = element.fechaSalida;
                if (fecha !== '30-11--0001 00:00') {
                    disable = true;
                }
            }
        });
        let theButton;
        theButton = <Button style={{ "width": "90%", "height": "80%" }} disabled={disable} onClick={() => this.guardarSalida(valor)}  > SALIDA</Button>
        return theButton;
    }

    guardarFecha(row) {
        let theButton;
        if (row === '30-11--0001 00:00') {
            theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#e89f4d" }} pill variant="danger">  <h6>  Sin salida marcada </h6>  </Badge>
        } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em" }} pill variant="success"> <h6> {row} </h6> </Badge>
        }
        return theButton;
    }

    formatoh5(row) {
        let columna;
        columna = <Badge> <h5> {row} </h5> </Badge>
        return columna;
    }

    formatoEntrada(row) {
        let columna;
        columna = <Badge> <h5> {row.format("YYYY-MM-DD HH:mm")} </h5> </Badge>
        return columna;
    }

    formatotemperatura(row) {
        let theButton;
        if (row > 38) {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#ce4552" }} pill variant="danger"> <h5> {row + "°"}  </h5> </Badge>
        } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964" }} pill variant="success"> <h5> {row + "°"}  </h5> </Badge>
        }
        return theButton;
    }

    onAfterSaveCell(row, cellName, cellValue) {
   /*     var persona = row.persona.split(" ")
        var nombre = persona[0]
        var apellido = persona[1]
        const obj = { id: row.id, temperatura: row.temperatura, motivo: row.motivo, nombre: nombre, apellido: apellido, rut: row.rut, cargo: row.cargo, empresa: row.empresa, patente: row.patente, temperatura: row.temperatura, num_credencial: row.num, persona: row.persona, idtbl_usuario: localStorage.getItem('idtbl_usuario') }
        if (obj !== undefined) {
            axios.post('https://accesos.sanjoseapps.cl/api/actualizarIngreso.php', obj).then(response => {
                this.obtenerIngresosDiarios();
            });
        } else {
            alert("Ocurrio un error");
        }*/
    }

    onBeforeSaveCell(row, cellName, cellValue) {

    }
    mostrarIngresoTarjeta() {
         this.setState({ mostrarQR: !this.state.mostrarQR })
     this.obtenerIngresosDiarios()
    }
    mostrarIngresoTarjetaYNormal() {
        this.setState({ mostrarQR: !this.state.mostrarQR })
        this.setState({  mostrarNormal : !this.state.mostrarNormal }) }
        mostrarIngresoNormal() { this.setState({  mostrarNormal : !this.state.mostrarNormal })
        this.obtenerIngresosDiarios()
    }

    btnNuevoIngreso = (onClick) => {
        return (<div> <Button onClick={this.mostrarIngresoNormal} >Nuevo Ingreso</Button> <Button onClick={this.mostrarIngresoTarjeta}>Ingreso tarjeta</Button></div>);
    }

    createCustomModalBody = (columns, validateState, ignoreEditable) => {
        return (
            <ModalIngreso columns={columns}  validateState={validateState}  ignoreEditable={ignoreEditable} />
        );
    }

    createCustomModalHeader(onClose, onSave) {
        const headerStyle = {
            fontWeight: 'bold',
            fontSize: 'large',
            textAlign: 'center',
            backgroundColor: '#eeeeee'
        };
        return (
            <div className='modal-header' style={headerStyle}>
                <h3>Nuevo Ingreso</h3>
                <button className='btn btn-info' onClick={onClose}> X </button>
            </div>
        );
    }

    createCustomModalFooter = (onClose, onSave) => {
        const style = {
            backgroundColor: '#ffffff'
        };
        return (
            <div className='modal-footer' style={style}>
                <button className='btn btn-xs btn-info' onClick={onClose}>Cerrar</button>
                <button className='btn btn-xs tn-danger' onClick={onSave}> Confirmar </button>
            </div>
        );
    }

    render() {

const { ExportCSVButton } = CSVExport;
const columns = [{
  dataField: 'numero',
  text: 'N°'
}, {
  dataField: 'fecha',
  text: 'FECHA INGRESO'
}, {
  dataField: 'rut',
  text: 'RUT'
}, {
    dataField: 'persona',
    text: 'PERSONA'
  }
  , {
    dataField: 'area',
    text: 'AREA'
  }];
        const options = {
            insertBtn: this.btnNuevoIngreso
            , insertModalBody: this.createCustomModalBody
            , insertModalHeader: this.createCustomModalHeader
            , insertModalFooter: this.createCustomModalFooter
        };
        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: this.onBeforeSaveCell, // a hook for before saving cell
            afterSaveCell: this.onAfterSaveCell  // a hook for after saving cell
        };
        var login = localStorage.getItem('idtbl_usuario');
        if (login === null || login === "undefined") {
            return <Redirect to="/" push={true} />
        } else {
            return (
                <React.Fragment>
                    {this.state.isLoading === true ?
                        (<section className="table table-responsive mt-md-5">
                            <div className="container table">
                                <div align="center">
                                    <Spinner type="grow" color="success" /> </div>
                            </div>
                        </section>) : (
                            <Fade in={true} tag="h5" className="mt-3" transition={{ in: true, timeout: 750 }} >
                                <CardBody>
                                      {this.state.mostrarQR === true ?
                                             <Mod show={this.state.mostrarQR} onHide={() => this.setState({ mostrarQR: false })} backdrop="static" keyboard={false}>
                                             <Mod.Header closeButton>
                                               <Mod.Title> INGRESAR AL COMEDOR </Mod.Title>
                                             </Mod.Header>
                                             <Mod.Body>
                                                     <ModalIngresoQR  cerraryMostrar = {this.mostrarIngresoTarjetaYNormal} mostrarModal = {this.mostrarIngresoTarjeta}> </ModalIngresoQR>
                                             </Mod.Body>
                                             <Mod.Footer>
                                               <Button variant="secondary" id="Cancelar" onClick={() => this.setState({ mostrarQR: false })}>
                                                 Cancelar
                                               </Button>
                                             </Mod.Footer>
                                           </Mod> : ""
                                    }
                                      {this.state.mostrarNormal === true ?
                                             <Mod show={this.state.mostrarNormal} onHide={() => this.setState({ mostrarNormal: false })} backdrop="static" keyboard={false}>
                                             <Mod.Header closeButton>
                                               <Mod.Title> INGRESAR AL COMEDOR  </Mod.Title>
                                             </Mod.Header>
                                             <Mod.Body>
                                                     <ModalIngreso  mostrarModal = {this.mostrarIngresoNormal} > </ModalIngreso>
                                             </Mod.Body>
                                             <Mod.Footer>
                                               <Button variant="secondary" id="Cancelar" onClick={() => this.setState({ mostrarNormal: false })}>
                                                 Cancelar
                                               </Button>
                                             </Mod.Footer>
                                           </Mod> : ""
                                    }


                                    <div className="form__form-group" style={{ "container": "wrap" }} >
                                        <div className="table" style={{ "margin": "auto", "fontSize": "0.7rem" }} >
                                            <BootstrapTable  id="table" scrollTop={'Bottom'} wrapperClasses="table-responsive"  data={this.state.ingresos} insertRow cellEdit={cellEditProp} options={options} search={true} searchPlaceholder={"Texto para búsqueda..."} hover pagination >
                                                <TableHeaderColumn width='90' isKey dataField='id' hiddenOnInsert hidden={true} >ID</TableHeaderColumn>
                                                <TableHeaderColumn width='130' dataFormat={this.formatoh5} dataSort={true} editable={false} dataField='fecha' > <h5> ENTRADA </h5> </TableHeaderColumn>
                                                <TableHeaderColumn width='100' dataFormat={this.formatoh5} dataSort={true} editable={false} dataField='rut' > <h5> RUT  </h5> </TableHeaderColumn>
                                                <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataSort={true} editable={true} dataField='persona'> <h5> NOMBRE </h5></TableHeaderColumn>
                                                <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataSort={true} editable={true} dataField='area'> <h5> AREA </h5></TableHeaderColumn>
                                              </BootstrapTable>
                                        </div>
                                    </div>
                                </CardBody>
                            </Fade>
                        )
                    }
                </React.Fragment >
            )
        }
    }
}
