import React from 'react';
import { Button, Badge, Form, Modal as Mod } from 'react-bootstrap';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Redirect } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { CardBody, Fade } from 'reactstrap';
import ModalIngresoVehiculos from './ModalIngresoVehiculos';

export default class Camion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      posicion_tab: "1",
      idtbl_campo: 0,
      alertEnvioExitoso: false,
      rut: "",
      id_vehiculo: 0,
      navigate: "",
      mostrarModalInformacion: false,
      isLoading: false,
      usuarioAutorizado: false,
      patente: '',
      cliente: '',
      detalle: '',
      mostrarModal: false,
      empresa: '',
      pantete: '',
      area: '',
      guia: '',
      tieneDetalle: true,
      checkguia: true,
      tieneFactura: true,
      vacio: true,
      tieneGuia: false,
      tieneKG: true,
      visita: '',
      num: '',
      observación: '',
      vehiculos: [],
      fechaSalida: '',
      preguntaA: '',
      preguntaB: '',
      preguntaC: '',
      preguntaD: '',
      sintomas: '',
      enfermedad: '',
      pais: '',
      Personas: []
    };
    this.nuevaSalida = this.nuevaSalida.bind(this);
    this.checkVacio = this.checkVacio.bind(this);
    this.checkGuia = this.checkGuia.bind(this);
    this.checkFactura = this.checkFactura.bind(this);
    this.validaLogin = this.validaLogin.bind(this);
    this.usoGuia = this.usoGuia.bind(this);
    this.guardarFecha = this.guardarFecha.bind(this);
    this.marcarSalida = this.marcarSalida.bind(this);
    this.formatoh5 = this.formatoh5.bind(this);
    this.handleChangeFactura = this.handleChangeFactura.bind(this)
    this.handleChangeKG = this.handleChangeKG.bind(this)
    this.handleChangeDetalle = this.handleChangeDetalle.bind(this);
    this.handleChangeRut = this.handleChangeRut.bind(this);
    this.handleChangeObservacion = this.handleChangeObservacion.bind(this);
    this.formateaRut = this.formateaRut.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkAmbos = this.checkAmbos.bind(this);
    this.handleChangeGuia = this.handleChangeGuia.bind(this);
    this.btnNuevoIngreso = this.btnNuevoIngreso.bind(this);
    this.guardarSalida = this.guardarSalida.bind(this);
    this.obtenerIngresosDiarios = this.obtenerIngresosDiarios.bind(this);
    this.onBeforeSaveCell = this.onBeforeSaveCell.bind(this);
    this.existeFactura = this.existeFactura.bind(this);
    this.formatoarea = this.formatoarea.bind(this);
    this.createCustomModalFooter = this.createCustomModalFooter.bind(this);
    this.createCustomModalBody = this.createCustomModalBody.bind(this);
    this.formatoFecha = this.formatoFecha.bind(this);    this.onRefresh = this.onRefresh.bind(this);
  }

  componentDidMount() {
    this.validaLogin()
  }


handleChange (event ){
  event.preventDefault();
  this.setState({[event.target.name]:  event.target.value})
  console.log(event.target.name, event.target.value)
}

  handleChangeDetalle(event) {
    event.preventDefault();
    this.setState({ detalle: event.target.value });
  }

  handleChangeObservacion(event) {
    event.preventDefault();
    this.setState({ observación: event.target.value });
  }

  handleChangeKG(event) {
    event.preventDefault();
    this.setState({ kg: event.target.value });
  }

  handleChangeGuia(event) {
    event.preventDefault();
    this.setState({ guia: event.target.value });
  }

  handleChangeFactura(event) {
    event.preventDefault();
    this.setState({ factura: event.target.value });
  }

  handleChangeRut(event) {
    event.preventDefault();
    this.setState({ rut: (this.formateaRut(event.target.value)) });
  }

  formateaRut(rut) {
    if (rut !== undefined) {
      var actual = rut.replace(/^0+/, "");
      if (actual !== '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 === 0 && j <= inicio.length - 1) {
            rutPuntos = "." + rutPuntos;
          }
          j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;

      }
      return rutPuntos
    }
  }


  onRefresh = () => {
    window.location.reload();
  }

  nuevaSalida() {
    this.setState({ mostrarModal: false })
    const obj = { id: this.state.id_vehiculo, rut: this.state.rut, kg: this.state.kg, factura: this.state.factura, guia: this.state.guia, detalle: this.state.detalle, observacion: this.state.observación, fecha: moment().format("YYYY-MM-DD HH:mm:ss"), usuarioAutoriza: localStorage.getItem('idtbl_usuario') };
    var optionAxios = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    //console.log(obj)
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/registroSalidaVehiculo.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          //console.log(datos)
        };
      });

    } catch (error) {
      //console.log(error);
    }
    this.obtenerIngresosDiarios()
    this.onRefresh()
  }

  checkVacio(event) {
    this.setState({ vacio: !this.state.vacio })
    if (this.state.vacio) {
      this.setState({
        tieneDetalle: false,
        tieneKG: false
      })
    } else {
      this.setState({ tieneDetalle: true, tieneKG: true })
    }
  }

  checkFactura(event) {
    this.setState({ tieneFactura: !this.state.tieneFactura })

  }



  checkAmbos(event){
    this.setState({ tieneAmbos : !this.state.tieneAmbos })
    if( this.state.tieneAmbos){
      this.setState({ tieneFactura: true })
      this.setState({ tieneGuia: true })
    }else{
      this.setState({ tieneFactura: false })
      this.setState({ tieneGuia: false })
    }
  }


  checkGuia(event) {
    this.setState({ tieneGuia: !this.state.tieneGuia })
  }
  validaLogin() {
    if (localStorage.getItem('idtbl_usuario') === undefined) {
      this.setState({ usuarioAutorizado: false });
    } else {
      this.setState({ usuarioAutorizado: true });
      this.obtenerIngresosDiarios();
    }
  }

  obtenerIngresosDiarios() {
    const obj = { fecha: moment().format("YYYY-MM-DD HH:mm:ss"), id_sucursal: localStorage.getItem("id_sucursal") };
    var optionAxios = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/traerIngresosDiariosVehiculos.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          //console.log(datos);
          this.setState({ vehiculos: datos })
        };
      });
    } catch (error) {
      //  ////console.log(error);
    }

  }

  usoGuia(row) {
    let valor = row
    let theButton;
    if (valor === null || valor === undefined) {
      valor = ''
    }
    try {
      if (valor.length > 0) {
        theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em" }} pill variant="success"> <h6> N° {valor} </h6> </Badge>
      } else {
        theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d" }} pill variant="success"> <h6> Sin Guia </h6> </Badge>
      }
    } catch (error) {
      theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d" }} pill variant="success"> <h6> Sin Guia </h6> </Badge>
    }
    return theButton;
  }


  existeFactura(row) {
    let valor = row
    if (valor === null || valor === undefined) {
      valor = ''
    }
    let theButton;
    try {
      if (valor.length > 0) {
        theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em" }} pill variant="success"> <h6> {valor} </h6> </Badge>
      } else {
        theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d" }} pill variant="success"> <h6> Sin factura </h6> </Badge>
      }
    } catch (error) {
      theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d" }} pill variant="success"> <h6> Sin factura </h6> </Badge>
    }
    return theButton;
  }

  guardarSalida(row) {
    this.setState({ mostrarModal: true });
    this.state.vehiculos.forEach(element => {
      if (element.id === row) {
        this.setState({ id_vehiculo: element.id })
        this.setState({ patente: element.matricula });
        this.setState({ rut: element.rut });
        this.setState({ cliente: element.empresa });
        this.setState({ area: element.area });
        this.setState({ patente: element.matricula });

      }
    });
  }

  marcarSalida(row) {
    let valor = row;
    let disable = false
    this.state.vehiculos.forEach(element => {
      if (element.id === row) {
        let fecha = element.fecha_egreso;
        if (fecha !== '30-11--0001 00:00') {
          disable = true;
        }
      }
    });
    let theButton;
    theButton = <Button style={{ "width": "90%", "height": "80%" }} disabled={disable} onClick={() => this.guardarSalida(valor)}  > SALIDA</Button>
    return theButton;

  }

  guardarFecha(row) {
    let theButton;
    if (row === '30-11--0001 00:00') {
      theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#e89f4d" }} pill variant="danger">  <h6>  Sin salida marcada </h6>  </Badge>
    } else {
      theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em" }} pill variant="success"> <h6> {row} </h6> </Badge>
    }
    return theButton;
  }

  formatoh5(row) {
    let columna;
    columna = <Badge> <h5> {row} </h5> </Badge>
    return columna;
  }

  formatoFecha(row) {
    let columna;
    columna = <Badge> <h5> {row.format("YYYY-MM-DD HH:mm")} </h5> </Badge>
    return columna;
  }

  formatoarea(row) {
    let theButton;
    if (row > 38) {
      theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#ce4552" }} pill variant="danger"> <h5> {row + "°"}  </h5> </Badge>
    } else {
      theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964" }} pill variant="success"> <h5> {row + "°"}  </h5> </Badge>
    }
    return theButton;
  }


  onBeforeSaveCell(row, cellName, cellValue) {
    if (cellName === 'rut') {
      this.setState({ "rut": cellValue });
    }
    if (cellName === 'patente') {
      this.setState({ "patente": cellValue });
    }
    if (cellName === 'cliente') {
      this.setState({ "cliente": cellValue });
    }
    if (cellName === 'detalle') {
      this.setState({ "detalle": cellValue });
    }
    if (cellName === 'empresa') {
      this.setState({ "empresa": cellValue });
    }
    if (cellName === 'pantete') {
      this.setState({ "pantete": cellValue });
    }
    if (cellName === 'area') {
      this.setState({ "area": cellValue });
    }
    if (cellName === 'guia') {
      this.setState({ "guia": cellValue });
    }
    if (cellName === 'visita') {
      this.setState({ "visita": cellValue });
    }
    if (cellName === 'num') {
      this.setState({ "num": cellValue });
    }
    if (cellName === 'observación') {
      this.setState({ "observación": cellValue });
    }
    return true;

  }

  btnNuevoIngreso = (onClick) => {
    return (
      <Button onClick={onClick}>Nuevo Ingreso</Button>
    );
  }

  createCustomModalBody = (columns, validateState, ignoreEditable) => {
    return (
      <ModalIngresoVehiculos columns={columns}
        validateState={validateState}
        ignoreEditable={ignoreEditable

        } />

    );
  }

  createCustomModalHeader(onClose, onSave) {
    const headerStyle = {
      fontWeight: 'bold',
      fontSize: 'large',
      textAlign: 'center',
      backgroundColor: '#eeeeee'
    };
    return (
      <div className='modal-header' style={headerStyle}>
        <h3>Nuevo Ingreso</h3>
        <button className='btn btn-info' onClick={onClose}> X </button>

      </div>
    );
  }

  createCustomModalFooter = (onClose, onSave) => {
    const style = {
      backgroundColor: '#ffffff'
    };
    return (
      <div className='modal-footer' style={style}>
        <button className='btn btn-xs btn-info' onClick={onClose}>Cerrar</button>
        <button className='btn btn-xs tn-danger' onClick={onSave}> Confirmar </button>
      </div>
    );
  }

  render() {

    //...
    const options = {
      insertBtn: this.btnNuevoIngreso
      , insertModalBody: this.createCustomModalBody
      , insertModalHeader: this.createCustomModalHeader
      , insertModalFooter: this.createCustomModalFooter
    };

    //
    var login = localStorage.getItem('idtbl_usuario');

    if (login === null || login === "undefined") {
      return <Redirect to="/" push={true} />
    } else {
      return (
        <React.Fragment>
          {
            this.state.isLoading === true ?
              (<section className="table table-responsive mt-md-5">
                <div className="container table">
                  <div align="center">
                    <Spinner type="grow" color="success" /> </div>
                </div>
              </section>
              )
              :
              (
                <Fade in={true} tag="h5" className="mt-3" transition={{ in: true, timeout: 750 }} >
                  <CardBody>
                    <div className="form__form-group" style={{ "container": "wrap" }} >
                      <div className="table" style={{ "margin": "auto", "fontSize": "0.7rem" }} >
                        <BootstrapTable scrollTop={'Bottom'} wrapperClasses="table-responsive" insertRow data={this.state.vehiculos} options={options} search={true} searchPlaceholder={"Texto para búsqueda..."} hover pagination >
                          <TableHeaderColumn width='90' isKey dataField='id' hiddenOnInsert hidden={true} >ID</TableHeaderColumn>
                          <TableHeaderColumn width='130' dataFormat={this.formatoh5} dataSort={true} editable={false} dataField='fecha_ingreso' > <h5> ENTRADA </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='100' dataFormat={this.formatoh5} dataSort={true} editable={false} dataField='rut' > <h5> RUT CONDUCTOR  </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataSort={true} editable={false} dataField='matricula'> <h5> PATENTE  </h5></TableHeaderColumn>
                          <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataSort={true} dataField='empresa'> <h5> CLIENTE</h5></TableHeaderColumn>
                          <TableHeaderColumn width='110' dataFormat={this.formatoh5} dataSort={true} dataField='area'> <h5> AREA </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' editable={false} dataField='factura' dataFormat={this.existeFactura} dataSort={true} hiddenOnInsert > <h5> FACTURA  </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' dataSort={true} dataField='guia' dataFormat={this.usoGuia} > <h5> GUIA </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' dataSort={true} dataField='kg' dataFormat={this.formatoh5} > <h5> KG ENTRADA </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataSort={true} dataField='detalle' > <h5> DETALLE </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataField='observación' hidden={true} > <h5> OBSERVACIÓN </h5>  </TableHeaderColumn>

                          <TableHeaderColumn width='120' editable={false} dataSort={true} dataField='fecha_egreso' dataFormat={this.guardarFecha} hiddenOnInsert >  <h5> SALIDA </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' editable={false} dataField='id' dataFormat={this.marcarSalida} hiddenOnInsert > <h5> MARCAR SALIDA </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' editable={false} dataField='factura_egreso' dataFormat={this.existeFactura} dataSort={true} hiddenOnInsert > <h5> FACTURA SALIDA  </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' dataSort={true} dataField='guia_egreso' dataFormat={this.usoGuia} > <h5> GUIA SALIDA </h5> </TableHeaderColumn>

                          <TableHeaderColumn width='120' dataSort={true} dataField='kg_egreso' dataFormat={this.formatoh5} > <h5> KG SALIDA </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataSort={true} dataField='detalle_egreso' > <h5> DETALLE SALIDA </h5> </TableHeaderColumn>
                          <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataField='observación' hidden={true} > <h5> OBSERVACIÓN </h5>  </TableHeaderColumn>

                        </BootstrapTable>
                      </div>
                    </div>
                  </CardBody>
                </Fade>
              )
          }
          {this.state.mostrarModal === true ?
            <Mod show={this.state.mostrarModal} onHide={() => this.setState({ mostrarModal: false })} backdrop="static" keyboard={false}>
              <Mod.Header closeButton>
                <Mod.Title>MARCAR SALIDA</Mod.Title>
              </Mod.Header>
              <Mod.Body>
                <div className='form-group' key={1} >
                  <div> <h4>FECHA SALIDA</h4> </div>
                  <Form.Control type="text" maxLength="50" disabled field="fecha" ref={"FECHA"} value={moment().format("DD-MM-YYYY HH:mm:ss")} required />
                </div>
                <div className='form-group' key={27} >
                  <div style={{ display: "flex" }}> <h4>PATENTE</h4> <h3 style={{ color: "red" }}> {" *"} </h3>   </div>
                  <Form.Control className="mobileBox" field="patente" placeholder="PATENTE" ref={"patente"} disabled value={this.state.patente} name="patente" onChange={this.handleChange} required type="text" maxLength={"6"} />
                </div>

                <div className='form-group' key={4} >
                  <div> <h4>RUT CHOFER</h4> </div>
                  <Form.Control type="text" maxLength="12" name="rut" field="rut" placeholder="RUT CHOFER" value={this.state.rut} onChange={this.handleChangeRut} ref={'rut'} required />
                </div>
                <div className='form-group' key={6} >
                  <div> <h4>CLIENTE</h4> </div>
                  <Form.Control type="text" maxLength="50" field="apellido" value={this.state.cliente} disabled placeholder="CLIENTE" ref={"cliente"} name="apellido" onChange={this.handleChange} required />
                </div>
                <div className='form-group' key={5} >
                  <div> <h4>AREA</h4> </div>
                  <Form.Control type="text" maxLength="50" field="area" value={this.state.area} disabled placeholder="AREA" ref={"area"} name="area" onChange={this.handleChange} required />
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <input class="form-check-input" checked={this.state.vacio} onClick={this.checkVacio} type="checkbox" id="invalidCheck" required />
                    <label class="form-check-label" for="invalidCheck">
                      <h4>CARGA VACIA</h4>
                    </label>
                  </div>
                </div>
                <div class="form-group" hidden={this.state.vacio} >
            <div class="form-check">
              <input class="form-check-input" checked={!this.state.tieneGuia} onClick={this.checkGuia} type="checkbox" id="checkguia" />
              <label class="form-check-label" for="checkguia">
                <h4>GUIA</h4>
              </label>
              <input class="form-check-input" style={{ "margin": "4.8px 0px 0px 20px" }} checked={!this.state.tieneFactura} onClick={this.checkFactura} type="checkbox" id="checkFactura" />
              <label class="form-check-label" style={{ "margin": "0px 0px 0px 35px" }} for="checkFactura">
                <h4>FACTURA</h4>
              </label>
                  <input class="form-check-input" style={{ "margin": "4.8px 0px 0px 20px" }} checked={this.state.tieneAmbos} onClick={this.checkAmbos} type="checkbox" id="checkAmbos" />
              <label class="form-check-label" style={{ "margin": "0px 0px 0px 35px" }} for="checkAmbos">
                <h4>AMBOS</h4>
              </label>
            </div>
          </div> <div hidden={(this.state.vacio)} >
                  <div className='form-group' hidden={(this.state.tieneGuia)} key={12} >
                    <div> <h4>GUIA</h4> </div>
                    <Form.Control type="textarea" rows={2} maxLength="250" disabled={this.state.tieneGuia} field="guia" placeholder="GUIA" name="guia" onChange={this.handleChange} required />
                  </div>
                </div>
                <div className='form-group' hidden={this.state.tieneFactura} key={53} >
                  <div> <h4>FACTURA</h4> </div>
                  <Form.Control type="textarea" rows={2} maxLength="250" disabled={this.state.tieneFactura} field="factura" placeholder="FACTURA" name="factura" onChange={this.handleChange}  required />
                </div>
                <div className='form-group' hidden={this.state.vacio} key={15} >
                  <div> <h4>DETALLE</h4> </div>
                  <Form.Control type="text" maxLength="50" field="detalle" disabled={this.state.tieneDetalle} value={this.state.detalle} placeholder="DETALLE" name="detalle" onChange={this.handleChange}  required />
                </div>

                <div className='form-group' hidden={this.state.vacio} key={7} >
                  <div> <h4>KG</h4> </div>
                  <Form.Control type="number" maxLength="50" field="kg" disabled={this.state.tieneKG} value={this.state.kg} placeholder="KG" ref={"kg"} name="kg" onChange={this.handleChange}  required />
                </div>

                <div className='form-group' key={14} >
                  <div> <h4>OBSERVACIÓN</h4> </div>
                  <Form.Control type="textarea" rows={2} maxLength="250" field="observación" placeholder="OBSERVACIÓN" ref={"obaervacion"} onChange={this.handleChangeObservacion} required />
                </div>

              </Mod.Body>
              <Mod.Footer>
                <Button id="Cancelar" onClick={this.nuevaSalida}>
                  MARCAR SALIDA
                </Button>
              </Mod.Footer>
            </Mod>
            : ""
          }
        </React.Fragment >
      )
    }
  }
}
