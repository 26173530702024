import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Form } from 'react-bootstrap';
import { Card, CardBody, Col } from 'reactstrap';

class RegistroLlave extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            nombre: "",
            id_sucursal: localStorage.getItem("id_sucursal"),
            sucursales: [],
            alertEnvioExitoso: false
        };
        this.registrarLlaves = this.registrarLlaves.bind(this);
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.traerSucursales = this.traerSucursales.bind(this);
        this.handleChangeCodigoDropBox = this.handleChangeCodigoDropBox.bind(this);
    }



    componentDidMount() {

        this.traerSucursales();
    }
    traerSucursales(){

        var obj = {};
         try {
                 axios.post('https://accesos.sanjoseapps.cl/api/traerSucursales.php', obj).then(response => {
                     const datos = response.data;
                     if (datos !== undefined) {
                         console.log(datos);
                       this.setState({sucursales: datos});
                     };
                 });

         } catch (error) {
             console.log(error);
         }
     }

    handleChangeCodigoDropBox(event) {
        event.preventDefault();
        let valor = event.target.value
        console.log(valor);
        this.setState({ id_sucursal: valor } );

    }


    registrarLlaves(event){
        event.preventDefault();
        const obj = { nombre : this.state.nombre,  id_sucursal : this.state.id_sucursal };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
                axios.post('https://accesos.sanjoseapps.cl/api/registrarLlave.php', obj, optionAxios).then(response => {
                    const datos = response.data;
                    console.log(datos)
                    if (datos !== undefined) {
                      alert("llave ingresada")
                    };
                });
        } catch (error) {
            console.log(error);
        }
    }

    handleChangeNombre(event) {
        event.preventDefault();
        this.setState({ nombre: event.target.value });
    }


    render() {
        return (
            <React.Fragment>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h4 className="bold-text">En esta pestaña se podrá crear llaves por sucursal</h4>
                            </div>
                            <Alert variant="success" show={this.state.alertEnvioExitoso} onClose={() => this.setState({ alertEnvioExitoso: false })} dismissible>
                                <Alert.Heading>¡Registro Exitoso!</Alert.Heading>
                                <p>
                                    Se ha registrado la llave {this.state.nombre}.
                                </p>
                            </Alert>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>
                                        <h4> NOMBRE LLAVE </h4>
                                    </div>
                                    <div>
                                        <Form.Control type="text" placeholder="Nombre" value={this.state.nombre} onChange={this.handleChangeNombre} maxLength={45} required />
                                    </div>
                                </div>
                            </div>
                            <br></br>

                                                      <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>   <h4>  SUCURSAL </h4> </div>
                                    <div>
                                             <Form.Control as="select" id={"2"}  onChange={this.handleChangeCodigoDropBox} >
                                             < option key={0} value={-1}> { "Seleccione Sucursal"} </option>
                                            {this.state.sucursales.map((r) => {
                                                return (< option key={r.id_sucursal} value={r.id_sucursal}> { r.nombre} </option>)
                                            })}
                                        </Form.Control>

                                 </div>
                                </div>
                            </div>

                            <br></br>

                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>
                                        <Form.Control onClick={this.registrarLlaves} type="submit" value="Registrar llave" />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}

export default RegistroLlave;
