import React from 'react';
import { Card, Col, CardBody, Container, Row } from 'reactstrap';
import RegistroEstacionamiento from './components/RegistroEstacionamiento';
const RegistrarEstacionamiento = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>

      <Col md={12} lg={12}>
        <Card>
          <CardBody>

            <RegistroEstacionamiento />


          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default RegistrarEstacionamiento;
