import React from 'react';
import { Form, Button, Badge } from 'react-bootstrap';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Redirect } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import { Spinner } from 'reactstrap';
import { Card, CardBody, Col, Fade } from 'reactstrap';

export default class Llaves extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      posicion_tab: "1",
      idtbl_campo: 0,
      alertEnvioExitoso: false,
      rut: "",
      mostrarModalInformacion: false,
      isLoading: false,
      usuarioAutorizado: false,
      sucursales: [],
      id_sucursal: localStorage.getItem("id_sucursal"),
      listado_llaves: [],
      id_llave: '',
      ingresos: [],
      fechaSalida: '',
      personas: [],
      llaves: []
    };
    this.validaLogin = this.validaLogin.bind(this);
    this.usoMascarilla = this.usoMascarilla.bind(this);
    this.formatoh5 = this.formatoh5.bind(this);
    this.obtenerIngresosDiarios = this.obtenerIngresosDiarios.bind(this);
    this.traerSucursales = this.traerSucursales.bind(this);
    this.traerUsuarios = this.traerUsuarios.bind(this);
    this.handleChangePersonas = this.handleChangePersonas.bind(this);
    this.handleChangeCodigoDropBox = this.handleChangeCodigoDropBox.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.traerLLaves = this.traerLLaves.bind(this);
    this.handleChangeLlave = this.handleChangeLlave.bind(this)
    this.registrarllave = this.registrarllave.bind(this);
    this.marcarSalida = this.marcarSalida.bind(this);
    this.guardarSalida = this.guardarSalida.bind(this);
    this.guardarFecha= this.guardarFecha.bind(this);
  }

  componentDidMount() {
    this.validaLogin();
    this.traerSucursales();
    this.traerUsuarios();
    this.traerLLaves();
    this.obtenerIngresosDiarios();
  }


  handleChangeCodigoDropBox(event) {
    event.preventDefault();
    let valor = event.target.value
    this.setState({ id_sucursal: valor }, () => { this.traerUsuarios(); this.traerLLaves() });
  }

  handleChangeLlave(event) {
    event.preventDefault();
    let valor = event.target.value
    this.setState({ id_llave: valor });

  }

  registrarllave() {
    const obj = { rut: this.state.rut, id_sucursal: this.state.id_sucursal, id_llave: this.state.id_llave, fecha: moment().format("YYYY-MM-DD HH:mm:ss") };
    var optionAxios = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/registroLlave.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
       ////console.log(datos);
       alert("registro Ingresado")
          this.obtenerIngresosDiarios();
        };
      });
    } catch (error) {
      ////console.log(error);
    }
  }

  marcarSalida(row){
    let valor = row;
    let disable = false;
    var id_llave = '';
    this.state.listado_llaves.forEach(element => {
        if(element.id === valor) {
            let fecha = element.horaSalida;
            id_llave = element.id_llaves;
            if( fecha !== '0000-00-00 00:00:00'){
                disable = true;
            }
        }
    });
    let theButton;


    theButton = <Button style={{"width":"90%", "height":"80%"}}  disabled={disable}  onClick= { ()=> this.guardarSalida(valor,id_llave)}  > DEVOLUCIÓN</Button>
    return theButton;
}


guardarSalida(valor,id_llave){

    const obj = { id_llave: id_llave, id: valor, fecha: moment().format("YYYY-MM-DD HH:mm:ss") };
    //console.log(obj)
    var optionAxios = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/devolucionLlaves.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
         //console.log(datos);
          this.obtenerIngresosDiarios();
        };
      });
    } catch (error) {
      ////console.log(error);
    }
  }


  guardarFecha(row) {
    let theButton;
    if(row ==='0000-00-00 00:00:00' )
    {
      theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em ", "background-color": "#e89f4d"}} pill variant="success"> <h6> NO DEVUELTA </h6> </Badge>
    }else{
        theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em"}} pill variant="success"> <h6> {row} </h6> </Badge>
    }
    return theButton;
}

  traerSucursales() {
    var obj = {};
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/traerSucursales.php', obj).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          this.setState({ sucursales: datos });
        };
      });

    } catch (error) {
      ////console.log(error);
    }

  }


  traerLLaves() {
    var obj = { id_sucursal: this.state.id_sucursal };
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/traerLlaves.php', obj).then(response => {
        const datos = response.data;
        ////console.log(datos);
        if (datos !== undefined) {
          this.setState({ llaves: datos });
        };
      });

    } catch (error) {
      ////console.log(error);
    }

  }

  traerUsuarios() {
    var obj = { id_sucursal: this.state.id_sucursal };
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/traerPersonas.php', obj).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          this.setState({ personas: datos });
        };
      });

    } catch (error) {
      ////console.log(error);
    }

  }

  handleChangePersonas(input, e) {
    // ////console.log("value", input)
    //  this.setState({ id_sucursal: valor }, () => { this.obtenerIngresosDiarios() });
  }

  handleChange(selectedOptions) {
    if (selectedOptions.length > 0) {
      let valor = selectedOptions[0];
      const parametros = valor.split(" ");
      this.setState({ rut: parametros[0] }, () => this.obtenerIngresosDiarios());

    }
  }

  validaLogin() {
    if (localStorage.getItem('idtbl_usuario') === undefined) {
      this.setState({ usuarioAutorizado: false });
    } else {
      this.setState({ usuarioAutorizado: true });
      this.obtenerIngresosDiarios();
    }
  }

  obtenerIngresosDiarios() {
    const obj = { id_sucursal: this.state.id_sucursal, fecha: moment().format("YYYY-MM-DD HH:mm:ss") };
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/traerListadoLlaves.php', obj).then(response => {
        const datos = response.data;
        //console.log(datos);
        if (datos !== undefined) {
          this.setState({ listado_llaves: datos })
        };
      });
    } catch (error) {
      //  ////console.log(error);
    }
  }

  usoMascarilla(row) {
    let theButton;
    if (row === '0') {
      theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#ce4552" }} pill variant="danger"> <h5> NO </h5> </Badge>
    } else if (row === '1') {
      theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964" }} pill variant="success"> <h5> SI </h5> </Badge>
    }
    return theButton;
  }

0

  formatoh5(row) {
    let columna;
    columna = <Badge> <h5> {row} </h5> </Badge>
    return columna;
  }


  render() {

    //
    var login = localStorage.getItem('idtbl_usuario');
    if (login === null || login === "undefined") {
      return <Redirect to="/" push={true} />
    } else {
      return (
        <React.Fragment>
          <Col md={12} lg={12}>
            <Card>
              {
                this.state.isLoading === true ?
                  (<section className="table table-responsive mt-md-5">
                    <div className="container table">
                      <div align="center">
                        <Spinner type="grow" color="success" /> </div>
                    </div>
                  </section>)
                  :
                  (
                    <Fade in={true} tag="h5" className="mt-3" transition={{ in: true, timeout: 750 }} >
                        <div className="form__form-group" style={{ "container": "wrap" }} >
                          <div className="row">

                            {localStorage.getItem('tipo_usuario') === "1" ? <h3 style={{ "margin": "2px 10px 6px 6px" }}> SUCURSAL</h3> : ""}
                            {localStorage.getItem('tipo_usuario') === "1" ?

                              <div>
                                <Form.Control as="select" id={"2"} onChangeCapture={this.handleChangeCodigoDropBox} value={this.state.id_sucursal} >
                                  < option key={0} value={-1}> {"Seleccione Sucursal"} </option>
                                  {this.state.sucursales.map((r) => {
                                    return (< option key={r.id_sucursal} value={r.id_sucursal}> {r.nombre} </option>)
                                  })}
                                </Form.Control>

                              </div>
                              : ""}

                            <h3 style={{ "margin": "2px 10px 6px 6px" }}> LLAVE</h3>

                            <div>
                              <Form.Control as="select" id={"3"} onChangeCapture={this.handleChangeLlave} value={this.state.id_llave} >
                                < option key={0} value={-1}> {"Seleccione Llave"} </option>
                                {this.state.llaves.map((r) => {
                                  return (< option key={r.id_llave} value={r.id_llave}> {r.nombre_llave} </option>)
                                })}
                              </Form.Control>
                            </div>
                            <h3 style={{ "margin": "2px 10px 6px 6px" }}> PERSONA</h3>
                            <Form.Group controlId="dob">

                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="Persona"
                                onInputChange={(e) => this.handleChangePersonas(e)}
                                options={this.state.personas.map((r) => { return (r.rut + " - " + r.nombre + " " + r.apellido) })}
                                placeholder="Escoger Persona..."
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <div style={{ "margin": "0px 30px 66px 36px" }}>
                              <Button id="Cancelar" onClick={() => this.registrarllave()}>
                                Asignar llave
                              </Button>
                            </div>
                           </div>
                        </div>
                        <div className="form__form-group" style={{ "container": "wrap" }} >
                          <div className="table" style={{ "margin": "auto", "fontSize": "0.7rem" }} >

                            <BootstrapTable scrollTop={'Bottom'} wrapperClasses="table-responsive" data={this.state.listado_llaves} search={true} searchPlaceholder={"Texto para búsqueda..."} hover pagination >
                              <TableHeaderColumn width='90' isKey dataField='id' hiddenOnInsert hidden={true} >ID</TableHeaderColumn>
                              <TableHeaderColumn width='100' dataFormat={this.formatoh5} editable={false} dataField='nombre_llave' > <h5> LLAVE  </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='120' dataFormat={this.formatoh5} editable={false} dataField='usuario'> <h5> SOLICITADO POR </h5></TableHeaderColumn>
                              <TableHeaderColumn width='120' dataFormat={this.formatoh5} editable={false} dataField='horaEntrega'> <h5> FECHA ENTREGA</h5></TableHeaderColumn>
                              <TableHeaderColumn width='120' dataFormat={this.guardarFecha}  dataField='horaSalida' > <h5> SALIDA </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='120' dataField='activa'  hidden={true}  dataFormat={this.usoMascarilla} > <h5> DISPONIBLE </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='140' editable={false} dataField='id' dataFormat={this.marcarSalida} hiddenOnInsert >  <h5> DEVOLUCIÓN </h5> </TableHeaderColumn>
                            </BootstrapTable>
                          </div>
                        </div>
                    </Fade>
                  )
              }
            </Card>
          </Col>
        </React.Fragment >
      )
    }
  }
}


