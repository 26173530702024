import React from 'react';
import { Card, Col,CardBody, Container, Row } from 'reactstrap';
import Bandeja_entrada from './components/Bandeja_entrada';


const Bandeja = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>
      
      <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                            <div className="card__title">
                                        <h5 style={{"text-align": "right"}} className="bold-text">  {localStorage.getItem("id_sucursal") === "1" ?  "Código: SCG-RG 10 POE 10" : "Código: PL.SS.5.2  Versión: 04"}  </h5>
                                            <h4 className="bold-text"> Personas ingresadas hoy en la sucursal de {localStorage.getItem("nombre_sucursal")}</h4>
                                        </div>
         <Bandeja_entrada></Bandeja_entrada>
     
      </CardBody>
                 </Card>
            </Col>
    </Row>
  </Container>
);

export default Bandeja;
