import React from 'react';
import { Col, Container, Row, Card, CardBody, } from 'reactstrap';
import Llaves from './components/Llaves';

const AsignacionLLaves = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>

      <Col md={20} lg={20}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 style={{ "text-align": "right" }} className="bold-text">  {localStorage.getItem("id_sucursal") === "1" ?  "Código: SCG-RG 2 POE 35" : "Código: PL.SS.5.3 Versión: 01"}  </h5>
              <h4 className="bold-text"> Asignación de llaves en la sucursal de {localStorage.getItem("nombre_sucursal")}</h4>
            </div>
            <Llaves />

          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default AsignacionLLaves;


