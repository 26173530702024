import React, { PureComponent } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { Alert } from 'reactstrap';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux'

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      mail: '',
      password: '',
      showButton: false,
      usuario: [],
      alertError: false,
      BtnLoginColorVerde: "active",
      BtnRegistrarColorVerde: "",
      navigate: false
    };
    this.obtenerUsuario = this.obtenerUsuario.bind(this);
    this.handleChangeMail = this.handleChangeMail.bind(this);
    this.handleChangePass = this.handleChangePass.bind(this);

  }

  componentDidMount() {

    localStorage.setItem('idtbl_usuario', undefined)
    localStorage.setItem('nombre_usuario', undefined)
    localStorage.setItem('apellido_usuario', undefined)
    localStorage.setItem('rut_usuario', undefined)
    localStorage.setItem('dv_usuario', undefined)
    localStorage.setItem('mail_usuario', undefined)
    localStorage.setItem('estado_registro', undefined)
    localStorage.setItem('fechaServidor', undefined)
    localStorage.setItem('fechaAyer', undefined)

  }
  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleChangeMail(event) {
    event.preventDefault();
    this.setState({ mail: event.target.value });
  }

  handleChangePass(event) {
    event.preventDefault();
    this.setState({ password: event.target.value });
  }

  async obtenerUsuario(event) {
    event.preventDefault();
    const obj = { mail: this.props.mail, password: this.props.password };
    console.log(obj)
    try {
      const res = await axios.post('https://accesos.sanjoseapps.cl/api/obtenerUsuario.php', obj);
      this.setState({ usuario: res.data[0] });
      if (this.state.usuario !== undefined) {
        localStorage.setItem('idtbl_usuario', this.state.usuario.idtbl_usuario)
        localStorage.setItem('nombre_usuario', this.state.usuario.nombre_usuario)
        localStorage.setItem('apellido_usuario', this.state.usuario.apellido_usuario)
        localStorage.setItem('rut_usuario', this.state.usuario.rut_usuario)
        localStorage.setItem('dv_usuario', this.state.usuario.dv_usuario)
        localStorage.setItem('mail_usuario', this.state.usuario.mail_usuario)
        localStorage.setItem('tipo_usuario', this.state.usuario.tipo_usuario)
        localStorage.setItem('fechaServidor', this.state.usuario.fechaServidor)
        localStorage.setItem('id_sucursal', this.state.usuario.id_sucursal)
        if (this.state.usuario.recepcion === "1") { localStorage.setItem('recepcion', true); } else { localStorage.setItem('recepcion', false); }
        if (this.state.usuario.casino === "1") { localStorage.setItem('casino', true); } else { localStorage.setItem('casino', false); }
        if (this.state.usuario.camion === "1") { localStorage.setItem('camion', true); } else { localStorage.setItem('camion', false); }
        if (this.state.usuario.salida_personas === "1") { localStorage.setItem('salida_personas', true); } else { localStorage.setItem('salida_personas', false); }
        if (this.state.usuario.aprobacion === "1") { localStorage.setItem('aprobacion', true); } else { localStorage.setItem('aprobacion', false); }
        if (this.state.usuario.busqueda_casino === "1") { localStorage.setItem('busqueda_casino', true); } else { localStorage.setItem('busqueda_casino', false); }
        if (this.state.usuario.busqueda_persona === "1") { localStorage.setItem('busqueda_persona', true); } else { localStorage.setItem('busqueda_persona', false); }
        if (this.state.usuario.busqueda_llave === "1") { localStorage.setItem('busqueda_llave', true); } else { localStorage.setItem('busqueda_llave', false); }
        if (this.state.usuario.mantenedor_usuario === "1") { localStorage.setItem('mantenedor_usuario', true); } else { localStorage.setItem('mantenedor_usuario', false); }
        if (this.state.usuario.registrar_personas === "1") { localStorage.setItem('registrar_personas', true); } else { localStorage.setItem('registrar_personas', false); }
        if (this.state.usuario.mantenedor_sucursal === "1") { localStorage.setItem('mantenedor_sucursal', true); } else { localStorage.setItem('mantenedor_sucursal', false); }
        if (this.state.usuario.mantenedor_llaves === "1") { localStorage.setItem('mantenedor_llaves', true); } else { localStorage.setItem('mantenedor_llaves', false); }
        if (this.state.usuario.mantenedor_estacionamiento === "1") { localStorage.setItem('mantenedor_estacionamiento', true); } else { localStorage.setItem('mantenedor_estacionamiento', false); }
        if (this.state.usuario.busqueda_vehiculo === "1") { localStorage.setItem('busqueda_vehiculo', true); } else { localStorage.setItem('busqueda_vehiculo', false); }
        if (this.state.usuario.diseño === "1") { localStorage.setItem('diseño', true); } else { localStorage.setItem('diseño', false); }
        if (this.state.usuario.motivo === "1") { localStorage.setItem('motivo', true); } else { localStorage.setItem('motivo', false); }
        if (this.state.usuario.temperatura === "1") { localStorage.setItem('temperatura', true) } else { localStorage.setItem('temperatura', false) }
        if (this.state.usuario.mascarilla === "1") { localStorage.setItem('mascarilla', true) } else { localStorage.setItem('mascarilla', false) }
        if (this.state.usuario.cargo === "1") { localStorage.setItem('cargo', true) } else { localStorage.setItem('cargo', false) }
        if (this.state.usuario.observacion === "1") { localStorage.setItem('observacion', true) } else { localStorage.setItem('observacion', false) }
        if (this.state.usuario.visita === "1") { localStorage.setItem('visita', true) } else { localStorage.setItem('visita', false) }
        if (this.state.usuario.patente === "1") { localStorage.setItem('patente', true) } else { localStorage.setItem('patente', false) }
        if (this.state.usuario.empresa === "1") { localStorage.setItem('empresa', true) } else { localStorage.setItem('empresa', false) }
        localStorage.setItem('fechaAyer', this.state.usuario.fechaAyer)
        this.setState({ navigate: true })
      } else {
        this.setState({ alertError: true });
        setTimeout(() => { this.setState({ alertError: false }) }, 5000)
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;
    if (this.state.navigate) {
      if( localStorage.getItem("casino") === "true" && localStorage.getItem('recepcion')=== "false"){
        return <Redirect to="/ingresoCasino" push={true} />

      }else{
        return <Redirect to="/bandeja" push={true} />

      }

    }
    return (
      <div>
        <Alert color="danger" isOpen={this.state.alertError} >
          <p><span className="bold-text">Error!</span> El mail o contraseña no son correctas.
          </p>
        </Alert>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Correo</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="mail"
                component="input"
                type="text"
                placeholder="Correo electrónico"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Contraseña</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field
                name="password"
                component="input"
                type={showPassword ? 'text' : 'password'}
                placeholder="Contraseña"
              />
              <button
                className={`form__form-group-button${showPassword ? ' active' : ''}`}
                onClick={e => this.showPassword(e)}
                type="button"
              ><EyeIcon />
              </button>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="remember_me"
                component={renderCheckBoxField}
                label="Recordar contraseña"
              />
            </div>
          </div>
          <Link className="btn btn-primary account__btn account__btn--small" to="/pages/one" onClick={this.obtenerUsuario}>Ingresar</Link>
        </form>
      </div>
    );
  }
}

const selector = formValueSelector('log_in_form')

export default compose(
  connect(state => {
    const { password, mail } = selector(state, 'password', 'mail')
    return {
      password,
      mail
    }
  }), reduxForm({

    form: 'log_in_form',
  }))(LogInForm);
