import React from 'react';
import { Card, Col, CardBody, Container, Row } from 'reactstrap';
import RegistroLlave from './components/RegistroLlave';
const RegistrarLLave = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>

      <Col md={12} lg={12}>
        <Card>
          <CardBody>

            <RegistroLlave />


          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default RegistrarLLave;
