import React from 'react';
import { Form, Badge } from 'react-bootstrap';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Redirect } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Spinner } from 'reactstrap';
import { Card, CardBody, Col, Fade } from 'reactstrap';

export default class Busqueda extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      posicion_tab: "1",
      idtbl_campo: 0,
      alertEnvioExitoso: false,
      rut: "",
      navigate: "",
      mostrarModalInformacion: false,
      isLoading: false,
      usuarioAutorizado: false,
      sucursales: [],
      id_sucursal: localStorage.getItem("id_sucursal"),
      nombre: '',
      apellido: '',
      cargo: '',
      empresa: '',
      ingresos: [],
      pantete: '',
      temperatura: '',
      mascarilla: '',
      visita: '',
      num: '',
      observación: '',
      fechaSalida: '',
      personas: []
    };
    this.validaLogin = this.validaLogin.bind(this);
    this.usoMascarilla = this.usoMascarilla.bind(this);
    this.formatoh5 = this.formatoh5.bind(this);
    this.obtenerIngresosDiarios = this.obtenerIngresosDiarios.bind(this);
    this.traerSucursales = this.traerSucursales.bind(this);
    this.traerPersonas = this.traerPersonas.bind(this);
    this.handleChangePersonas = this.handleChangePersonas.bind(this);
    this.handleChangeCodigoDropBox = this.handleChangeCodigoDropBox.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.validaLogin();
    this.traerSucursales();
    this.traerPersonas();
  }


  handleChangeCodigoDropBox(event) {
    event.preventDefault();
    let valor = event.target.value
    ////console.log(valor);
    this.setState({ id_sucursal: valor }, () => { this.traerPersonas() });


}

  traerSucursales() {
    var obj = {};
    try {
        axios.post('https://accesos.sanjoseapps.cl/api/traerSucursales.php', obj).then(response => {
            const datos = response.data;
            if (datos !== undefined) {
                this.setState({ sucursales: datos });
            };
        });

    } catch (error) {
        ////console.log(error);
    }

}

traerPersonas() {
  var obj = {id_sucursal: this.state.id_sucursal};
  try {
      axios.post('https://accesos.sanjoseapps.cl/api/traerPersonas.php', obj).then(response => {
          const datos = response.data;
          if (datos !== undefined) {
              this.setState({ personas: datos });
          };
      });

  } catch (error) {
      ////console.log(error);
  }

}

handleChangePersonas(input, e){
 // ////console.log("value", input)
  //  this.setState({ id_sucursal: valor }, () => { this.obtenerIngresosDiarios() });
}

handleChange(selectedOptions) {
  if(selectedOptions.length > 0 ){
 let valor =  selectedOptions[0];
 const parametros = valor.split(" ");
  this.setState({rut: parametros[0]},()=> this.obtenerIngresosDiarios() );

  }
}

  validaLogin() {
    if (localStorage.getItem('idtbl_usuario') === undefined) {
      this.setState({ usuarioAutorizado: false });
    } else {
      this.setState({ usuarioAutorizado: true });
      this.obtenerIngresosDiarios();
    }
  }


  obtenerIngresosDiarios() {
    const obj = { rut: this.state.rut};
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/traerPersonaRut.php', obj).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          this.setState({ ingresos: datos })
        };
      });
    } catch (error) {
      //  ////console.log(error);
    }

  }

  usoMascarilla(row) {
    let theButton;
    if (row === '0') {
      theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#ce4552" }} pill variant="danger"> <h5> NO </h5> </Badge>
    } else if (row === '1') {
      theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964" }} pill variant="success"> <h5> SI </h5> </Badge>
    }
    return theButton;

  }



  formatoh5(row) {
    let columna;
    columna = <Badge> <h5> {row} </h5> </Badge>

    return columna;
  }


  render() {

    //
    var login = localStorage.getItem('idtbl_usuario');
    if (login === null || login === "undefined") {
      return <Redirect to="/" push={true} />
    } else {
      return (
        <React.Fragment>
          <Col md={12} lg={12}>
            <Card>
              {
                this.state.isLoading === true ?
                  (<section className="table table-responsive mt-md-5">
                    <div className="container table">
                      <div align="center">
                        <Spinner type="grow" color="success" /> </div>
                    </div>
                  </section>)
                  :
                  (
                    <Fade in={true} tag="h5" className="mt-3" transition={{ in: true, timeout: 750 }} >
                      <CardBody>
                        <div className="card__title">
                          <h4 className="bold-text"> Búsqueda de ingresos por persona</h4>
                        </div>
                        <div className="form__form-group" style={{ "container": "wrap" }} >
                          <div className="row">
                            <h3 style={{ "margin": "2px 10px 6px 6px" }}> PERSONA</h3>
                            <Form.Group controlId="dob">
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="Persona"
                              onInputChange={(e)=> this.handleChangePersonas(e)}
                              options={this.state.personas.map( (r) => { return ( r.rut + " - " +r.nombre + " "  + r.apellido  )}  ) }
                              placeholder="Escoger Persona..."
                              onChange={this.handleChange}
                           />
                            </Form.Group>

                            {localStorage.getItem('tipo_usuario') === "1" ? <h3 style={{ "margin": "2px 10px 6px 6px" }}> SUCURSAL</h3> : ""}
                            {localStorage.getItem('tipo_usuario') === "1" ?

                              <div>
                                <Form.Control as="select" id={"2"} onChangeCapture={this.handleChangeCodigoDropBox} value={this.state.id_sucursal} >
                                  < option key={0} value={-1}> {"Seleccione Sucursal"} </option>
                                  {this.state.sucursales.map((r) => {
                                    return (< option key={r.id_sucursal} value={r.id_sucursal}> {r.nombre} </option>)
                                  })}
                                </Form.Control>

                              </div>
                              : ""}
                          </div>
                        </div>
                        <div className="form__form-group" style={{ "container": "wrap" }} >
                          <div className="table" style={{ "margin": "auto", "fontSize": "0.7rem" }} >

                            <BootstrapTable scrollTop={'Bottom'} wrapperClasses="table-responsive" data={this.state.ingresos} search={true} searchPlaceholder={"Texto para búsqueda..."} hover pagination >
                              <TableHeaderColumn width='90' isKey dataField='id' hiddenOnInsert hidden={true} >ID</TableHeaderColumn>

                              <TableHeaderColumn width='150' dataFormat={this.formatoh5} editable={false} dataSort={ true } dataField='fecha' > <h5> ENTRADA </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='100' dataFormat={this.formatoh5} dataSort={ true } editable={false} dataField='rut' > <h5> RUT  </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataSort={ true } editable={false} dataField='nombre'> <h5> NOMBRE </h5></TableHeaderColumn>
                              <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataSort={ true } editable={false} dataField='apellido'> <h5> APELLIDO</h5></TableHeaderColumn>
                              <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataField='cargo' hidden={true} > <h5> CARGO </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataField='empresa' hidden={true} > <h5> EMPRESA </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='100' dataFormat={this.formatoh5} dataField='patente' hidden={true} > <h5> PATENTE </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='115' dataFormat={this.formatoh5} dataSort={ true } dataField='temperatura'> <h5> TEMPERATURA </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='100' hidden={true} dataField='visita' dataFormat={this.usoMascarilla} > <h5> VISITA </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='140' editable={false} dataField='fechaSalida' dataFormat={this.formatoh5} hiddenOnInsert >  <h5> SALIDA </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='135' dataField='mascarilla' dataFormat={this.usoMascarilla} > <h5> USO MASCARILLA</h5> </TableHeaderColumn>
                              <TableHeaderColumn width='150' dataFormat={this.formatoh5} hidden={true} dataField='num' > <h5> NUMERO CREDENCIAL </h5> </TableHeaderColumn>
                              <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataField='motivo' hidden={true}> <h5> MOTIVO </h5>  </TableHeaderColumn>

                            </BootstrapTable>
                          </div>
                        </div>
                      </CardBody>
                    </Fade>
                  )
              }
            </Card>
          </Col>
        </React.Fragment >
      )
    }
  }
}
