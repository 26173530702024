


import React from 'react';
import { Form, Button, Modal as Mod } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Card, Col } from 'reactstrap';
import svgiconproduct from '../../img/logo.png';

export default class Cuestionario extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            rut: '',
            btnDisabled:false,
            tienerut: false,
            mostrarModal: true,
            pregunta_A: "0",
            pregunta_B: "0",
            pregunta_C: "0",
            pregunta_D: "0",
            pregunta_F: "0",
            enfermedad : "",
            pais:"",
            sintomas: ""
        };
        this.getParameterByName = this.getParameterByName.bind(this);
        this.handlePreguntaA = this.handlePreguntaA.bind(this);
        this.handlePreguntaB = this.handlePreguntaB.bind(this);
        this.handleChangeRut = this.handleChangeRut.bind(this);
        this.handlePreguntaC = this.handlePreguntaC.bind(this);
        this.handlePreguntaD = this.handlePreguntaD.bind(this);
        this.handlePreguntaF = this.handlePreguntaF.bind(this);
        this.handlePais = this.handlePais.bind(this);
        this.handleSintomas = this.handleSintomas.bind(this);
        this.handleConcluir = this.handleConcluir.bind(this);
        this.handleEnfermedad = this.handleEnfermedad.bind(this);
    }

    componentDidMount() {
        //   this.validaLogin()
        var rut_obtenido = this.getParameterByName('rut');
        this.setState({ rut: rut_obtenido });
        if (rut_obtenido.length < 5) {
           this.setState({ tienerut: true });
       }
    }

    formateaRut(rut) {
        if (rut !== undefined) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 === 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
                if (rutPuntos.length > 9) {
                    this.setState({ tienerut: false });
                }

            }

            return rutPuntos;
        }
    }

    handleChangeRut(event) {
        event.preventDefault();
        this.setState({ rut: (this.formateaRut(event.target.value)) });
      }

    handlePreguntaA(event) {
        this.setState({ pregunta_A: event });
    }

    handlePreguntaB(event) {
        this.setState({ pregunta_B: event });
    }

    handlePreguntaC(event) {
        this.setState({ pregunta_C: event });
    }

    handlePreguntaD(event) {
        this.setState({ pregunta_D: event });
    }
    handlePreguntaF(event) {
        this.setState({ pregunta_F: event });
    }
    handleEnfermedad(event){
        event.preventDefault();
        this.setState({ enfermedad: event.target.value });
    }

    handleRutEscrito(event){
        event.preventDefault();
        if(this.state.rut )
        this.setState({ sintomas: event.target.value });
    }

    handlePais(event){
        event.preventDefault();
        this.setState({ pais: event.target.value });
    }
    handleSintomas(event){
        event.preventDefault();
        this.setState({ sintomas: event.target.value });
    }
    handleConcluir(event){
        event.preventDefault();
        try {
            var optionAxios = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const obj = {id_sucursal: localStorage.getItem("id_sucursal"),rut: this.state.rut, preguntaA :this.state.pregunta_A, pregunta_F : this.state.pregunta_F , preguntaB :this.state.pregunta_B, preguntaC :this.state.pregunta_C, preguntaD :this.state.pregunta_D, pais : this.state.pais, sintomas: this.state.sintomas, fecha : moment().format("YYYY-MM-DD HH:mm:ss"), enfermedad : this.state.enfermedad, manual:false }
            axios.post('https://accesos.sanjoseapps.cl/api/llenarEncuesta.php', obj,optionAxios).then(response => {
                const datos = response.data;
                if (datos !== undefined){
                    alert("Formulario Completado!");
                    window.opener = null;
                    window.open("about:blank", "_self");
                    window.close();
                    this.setState({btnDisabled: true});
                }
            });
        } catch (error) {
          console.log(error);
        }
    }

    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    render() {
        return (
            <React.Fragment>
                <Col md={12} lg={12} style={{ "background": "00000" }} >
                    <Card>  <Mod show={this.state.mostrarModal} backdrop="static" keyboard={false}>
                        <Mod.Header >
                            <Mod.Title>USUARIO AUTORIZADO</Mod.Title>
                        </Mod.Header>
                        <Mod.Body>
                            <div class="row">
                                <div class="column" style={{ float: "left", width: "90.33%" }}>
                                <div style={{ display: "flex" , marginLeft: "25px"}}> <h4>RUT   </h4>
                                 <Form.Control type="text" maxLength="16" field="rut" placeholder="RUT" style={{ marginLeft: "25px" }} value={this.state.rut} onChange={this.handleChangeRut} ref={'rut'} required />
                                </div>
                                </div>
                            </div>
                            <br></br>
                        </Mod.Body>
                        <Mod.Footer>
                            <Button variant="primary" id="Ingresar" disabled={this.state.tienerut} onClick={() => this.setState({ mostrarModal: false })} >
                            INGRESAR A LA ENCUESTA
                            </Button>
                        </Mod.Footer>
                    </Mod>
                        <table style={{ "border": "1px", "border-style": "solid", "border-width": "1px" }}>
                            <thead>
                                <tr style={{ "border": "1px", "border-style": "solid", "border-width": "1px" }} >
                                    <th style={{ "border": "1px", "border-style": "solid", "border-width": "1px", "width": "20%" }} rowspan="2" > <img src={svgiconproduct} alt="" style={{ "width": "90%" }} /> </th>
                                    <th style={{ "border": "1px", "border-style": "solid", "border-width": "1px", "text-align": "-webkit-center" }} >HIGIENE</th>
                                    <th style={{ "border": "1px", "border-style": "solid", "border-width": "1px", "text-align": "-webkit-center" }}>SGC-RG 8 POE 8</th>
                                </tr>
                                <tr style={{ "border": "1px", "border-style": "solid", "border-width": "1px" }}>
                                    <td style={{ "border": "1px", "border-style": "solid", "border-width": "1px", "text-align": "-webkit-center" }} rowspan="2"><span style={{ "font-weight": "bold" }}>PRESENTACION PERSONAL E HIGIENE</span></td>
                                    <td style={{ "border": "1px", "border-style": "solid", "border-width": "1px", "text-align": "-webkit-center" }} rowspan="2">REV.02</td>
                                </tr>
                                <tr>
                                </tr>
                            </thead>
                        </table>
                        <div style={{ "text-align": "-webkit-center" }}>
                            <p> <h3>Estimado colaborador, visitante, contratista:</h3> </p>
                            <h4>
                                Producto de la Pandemia declarada por la OMS le solicitamos seguir las siguientes indicaciones y responder breve cuestionario
                            </h4>
                        </div>
                        <p> <h3> Recomendaciones: </h3></p>
                        <p> <h4> a) Lavar y sanitizar sus manos al ingresar a cualquier área de la planta, en especial en áreas de manipulacion y cada vez que sea necesario. Utilice mascarilla y mantenga la distancia social de al menos un metro. </h4> </p>
                        <p> <h4> b) Utilizar chaleco reflectante y zapatos de seguridad. </h4> </p>
                        <p> <h4> c) Ingresar sin joyas, reloj, bolsos o carteras a zonas de proceso y almacenamiento. </h4> </p>
                        <p> <h4> d) No comer, ni masticar chicle, o beber al interior de las áreas de proceso. </h4> </p>
                        <p> <h4> e) No fumar al interior de las instalaciones de la planta Baika.</h4> </p>
                        <p> <h3> Preguntas: </h3></p>
                        <div key={1} className="mb-3">
                            <p>
                                <h4>
                                f) ¿Presenta alguna enfermedad infectocontagiosa? Si es afirmativo, indique cuál:  &nbsp;&nbsp;
                                    <Form.Check label="SI" inline name="formHorizontalRadios" id="formHorizontalRadios1" onClick={() => this.handlePreguntaA(true)} type="radio" />
                                    <Form.Check label="NO" inline name="formHorizontalRadios" id="formHorizontalRadios1"  onClick={() => this.handlePreguntaA(false)} type="radio" />
                                    <br></br>
                                    <Form.Control type="text" maxLength="250" field="rut" placeholder="DESCRIBIR ENFERMEDAD INFECTOCONTAGIOS"  onChange={this.handleEnfermedad} ref={'rut'} disabled={!this.state.pregunta_A} />
                                </h4>
                             </p>
                        </div>
                        <br></br>
                        <div key={2} className="mb-3">
                            <p>
                                <h4> g) ¿Ha estado en contacto con alguna persona con sospecha o infectado con "Coronavirus" ? &nbsp;&nbsp;
                                    <Form.Check label="SI" inline name="formHorizontalRadiosb" onClick={() => this.handlePreguntaB(true)} id="formHorizontalRadios2" type="radio" />
                                    <Form.Check label="NO" inline name="formHorizontalRadiosb" onClick={() => this.handlePreguntaB(false)}  id="formHorizontalRadios2" type="radio" />
                                </h4>
                            </p>
                        </div>
                        <br></br>
                        <div key={3} className="mb-3">
                            <p>
                                <h4> h)  En las ultimas dos semanas ¿Ha viajado fuera del pais?. Si su respuesta es afirmativa indique qué pais: &nbsp;&nbsp;
                                    <Form.Check label="SI" inline name="formHorizontalRadiosc" onClick={() => this.handlePreguntaC(true)} id="formHorizontalRadios3" type="radio" />
                                    <Form.Check label="NO" inline name="formHorizontalRadiosc" onClick={() => this.handlePreguntaC(false)}  id="formHorizontalRadios3" type="radio" />
                                    <br></br>
                                    <Form.Control type="text" maxLength="50" field="rut" placeholder="INDICAR TEMPERATURA "  onChange={this.handlePais} ref={'yr,'}  disabled={!this.state.pregunta_C} />
                                </h4>
                            </p>
                        </div>
                        <br></br>
                        <div key={4} className="mb-3">
                            <p>
                                <h4> i) En los ultimos dos dias, ¿ha presentado, alguno de los siguientes sintomas: Tos seca, fiebre superior a 38°C o secrecion nazal?. Si es afirmativo indique cuál(es):  &nbsp;&nbsp;
                                    <Form.Check label="SI" inline name="formHorizontalRadiosd" id="formHorizontalRadios4" onClick={() => this.handlePreguntaD(true)} type="radio" />
                                    <Form.Check label="NO" inline name="formHorizontalRadiosd" id="formHorizontalRadios4" onClick={() => this.handlePreguntaD(false)}  type="radio" />
                                    <br></br>
                                    <Form.Control type="text" maxLength="250" placeholder="INDICAR SÍNTOMAS" onChange={this.handleSintomas}  disabled={!this.state.pregunta_D} />
                                </h4>
                            </p>
                        </div>
                        <div key={5} className="mb-3">
                            <p>
                                <h4>j): ¿Su temperatura actual es igual o superior 37,5° C?  &nbsp;&nbsp;
                                    <Form.Check label="SI" inline name="formHorizontalRadiosf" id="formHorizontalRadios5" onClick={() => this.handlePreguntaF(true)} type="radio" />
                                    <Form.Check label="NO" inline name="formHorizontalRadiosf" id="formHorizontalRadios5" onClick={() => this.handlePreguntaF(false)}  type="radio" />
                                    <br></br>
                                </h4>
                            </p>
                            En caso que alguna de sus respuestas sea SI, la empresa aplicará alguna medida preventiva tal como: Evaluar su ingreso.
                        </div>
                        <Button type="submit" onClick={this.handleConcluir} disabled={this.btnDisabled} > ENVIAR CUESTIONARIO </Button>
                    </Card>
                </Col>
            </React.Fragment >
        );
    }
}
