import React, { Component } from 'react';
import {Modal as Mod } from 'react-bootstrap';
import axios from 'axios'; import Image from 'react-bootstrap/Image'
import moment from 'moment';
import QrReader from 'react-camera-qr'
import aprobar from './../../img/aprobar.png'
import denegar from './../../img/denegar.png'
class ModalIngreso extends Component {
  constructor() {
    super();
    this.state = {
      rut: "",
      nombre: '',
      apellido: '',
      result: 'No result',
      cargo: '',
      empresa: '',
      patente: '',
      temperatura: '',
      mascarilla: '1',
      visita: '0',
      num: '0',
      motivo: '',
      link: 'https://accesos.sanjoseapps.cl/api/credenciales/sinfoto.png',
      rutcompletado: true,
      observación: '',
      activo: '0',
      fechaSalida: '',
       mostrarModalAcceso: false,
      mostrarModalEncuesta: false,
      mostrarModal: false,
      fecha: ''
    };
    this.handleConcluir = this.handleConcluir.bind(this);
    this.cargarUsuario = this.cargarUsuario.bind(this);
    this.handleMostrarModalEncuesta = this.handleMostrarModalEncuesta.bind(this)
    this.handleMostrarModal = this.handleMostrarModal.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this)
    this.desformatearRut = this.desformatearRut.bind(this)
  }

  handleMostrarModal(event) {
    event.preventDefault();
    this.setState({ mostrarModal: true });
  }

  desformatearRut(rut) {
    if (rut !== undefined) {
      var actual = rut.replace(/^0+/, "");
      if (actual !== '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        return actualLimpio
      }
    }
  }

  revisarImg(rut_usuario) {
    const obj = { rut: this.desformatearRut(rut_usuario) };
    try {
      var optionAxios = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://accesos.sanjoseapps.cl/api/buscarImagenRut.php', obj, optionAxios).then(response => {
        const datos = response.data
        if (datos !== undefined) {
          let valor = response.data;
          valor = "https://accesos.sanjoseapps.cl/api/credenciales/" + valor;
          this.setState({ link: valor })
        } else {
          //console.log("No existen codigos sin asignar");
        }
      })
    } catch (error) {
      console.error(error);
    }
  }

  handleScan = data => {
    if (data) {
      this.setState({ rut: this.formateaRut(data) })
      this.revisarImg(this.state.rut);
      this.setState({  mostrarModalAcceso: true })
    }
  }

  handleError = err => {
    console.error(err)
  }

  handleMostrarModalEncuesta(event) {
    event.preventDefault();
    this.setState({ rutcompletado: true });
    this.setState({ mostrarModalEncuesta: true }, () => this.handleConcluir(event));
  }

  handleConcluir(event) {
    event.preventDefault();
    try {
      var optionAxios = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      const obj = { rut: this.state.rut, preguntaA: this.state.pregunta_A, preguntaB: this.state.pregunta_B, preguntaC: this.state.pregunta_C, preguntaD: this.state.pregunta_D, pais: this.state.pais, sintomas: this.state.sintomas, fecha: moment().format("YYYY-MM-DD HH:mm:ss"), enfermedad: this.state.enfermedad, manual: this.state.mostrarModalEncuesta }
      axios.post('https://accesos.sanjoseapps.cl/api/llenarEncuesta.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          this.setState({ btnDisabled: true });
          this.setState({ mostrarModalEncuesta: false });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  formateaRut(rut) {
    if (rut !== undefined) {
      var actual = rut.replace(/^0+/, "");
      if (actual !== '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 === 0 && j <= inicio.length - 1) {
            rutPuntos = "." + rutPuntos;
          }
          j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
        if (rutPuntos.length > 9) {
          this.cargarUsuario(rutPuntos);
          this.setState({ rutcompletado: false });
        }
      }
      return rutPuntos
    }
  }

  cargarUsuario(rut) {
    try {
      const obj = { rut: rut }
      var optionAxios = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://accesos.sanjoseapps.cl/api/traerPersona.php', obj, optionAxios).then(response => {
        const datos = response.data;

        if (datos == []) {
          alert("Persona no identificada.")
          this.props.cerraryMostrar();
        }
        if (datos[0] !== undefined) {
          this.setState({ nombre: datos[0].nombre })
          if (datos[0].nombre.length > 0) {
            this.setState({ apellido: datos[0].apellido })
            this.setState({ cargo: datos[0].cargo })
            this.setState({ empresa: datos[0].empresa })
            this.setState({ patente: datos[0].patente })
            this.setState({ activo: datos[0].activo })
            if (datos[0].activo != "1") {
              alert("Persona no autorizada para el ingreso.")
              this.props.mostrarModal();
            } else {
              this.getFieldValue()
            }
          }
        } else {
          alert("Persona no identificada.")
          this.props.cerraryMostrar();
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  getFieldValue() {
    const newRow = {};
    newRow["id"] = 0
    newRow["rut"] = this.state.rut;
    newRow["persona"] = this.state.nombre + " " + this.state.apellido
    newRow["nombre"] = this.state.nombre;
    newRow["apellido"] = this.state.apellido;
    newRow["cargo"] = this.state.cargo;
    newRow["empresa"] = this.state.empresa;
    newRow["patente"] = this.state.patente;
    newRow["temperatura"] = this.state.temperatura
    newRow["mascarilla"] = this.state.mascarilla
    newRow["visita"] = this.state.visita
    newRow["motivo"] = this.state.motivo
    newRow["num"] = this.state.num
    newRow["observación"] = this.state.observación
    newRow["fechaSalida"] = '30-11--0001 00:00';
    newRow["manual"] = this.state.mostrarModalEncuesta;
    let fecha = moment().format("YYYY-MM-DD HH:mm:ss");
    newRow["fecha"] = fecha;
    var optionAxios = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    const obj = { rut: this.state.rut, nombre: this.state.nombre, id_sucursal: localStorage.getItem("id_sucursal"), apellido: this.state.apellido, cargo: this.state.cargo, empresa: this.state.empresa, patente: this.state.patente, visita: this.state.visita, motivo: this.state.motivo, temperatura: this.state.temperatura, mascarilla: this.state.mascarilla, num: this.state.num, observacion: this.state.observación, fecha: moment().format("YYYY-MM-DD HH:mm:ss"), usuarioAutoriza: localStorage.getItem('idtbl_usuario') };
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/registrarIngresoCasino.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          newRow["id"] = datos;
          setTimeout(() => { this.setState({  mostrarModalAcceso: false }) }, 3000)
        };
      });
      this.setState({ mostrarModal: true });
    } catch (error) {
      console.log(error);
    }

    return newRow;
  }

  render() {
    return (
      <div className='modal-body'>
        <div>
          <QrReader delay={300} onError={this.handleError} onScan={this.handleScan} style={{ width: '100%' }} />
        </div>
        <div>
        </div>
        <Mod show={this.state. mostrarModalAcceso} onHide={() => this.setState({  mostrarModalAcceso: false })} backdrop="static" keyboard={false}>
          <Mod.Header closeButton>
            <Mod.Title>USUARIO AUTORIZADO</Mod.Title>
          </Mod.Header>
          <Mod.Body>
            <div class="row">
              <div class="column" style={{ float: "left", width: "33.33%" }}>
                <Image src={this.state.link} roundedCircle style={{ margin: "1px" }}  ></Image>   </div>
              <div class="column" style={{ float: "left", width: "43.33%", "margin-top": "5%", "margin-left": "3%" }} >  Bienvenido  <h4> {this.state.nombre + " " + this.state.apellido}  </h4>   <h4> Cargo :  {this.state.cargo}</h4>  <h4> Empresa:  {this.state.empresa}</h4> </div>
              <div class="column" style={{ float: "left", "margin-top": "10%", "margin-right": "10%", "margin-left": "-5%" }} > {this.state.activo === '1' ? <img src={aprobar} alt="" style={{ "width": "75px" }} /> : <img src={denegar} alt="" style={{ "width": "75px" }} />} </div>
            </div>
            <br></br>
          </Mod.Body>
        </Mod>
      </div>
    );
  }
}


export default ModalIngreso;
