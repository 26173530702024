import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';

class ModalIngreso extends Component {
  constructor() {
    super();
    this.state = {
      rut: "",
      area: '',
      kg_egreso : '',
      fecha_egreso: '',
      factura_egreso: '',
      guia_egreso : '',
      detalle_egreso : '',
      cliente: '',
      detalle: '',
      empresa: '',
      patente: '',
      nombre_chofer: '',
      guia: '',
      mascarilla: '1',
      visita: '0',
      num: '0',
      motivo: '',
      factura: '',
      rutcompletado: true,
      vacio: true,
      kg: "0",
      observación: '',
      fechaSalida: '',
      mostrarModalEncuesta: false,
      tieneAmbos: false,
      tieneDetalle: true,
      checkguia: true,
      tieneFactura: true,
      tieneGuia: false,
      tieneKG: true,
      mostrarModal: false,
      fecha: ''
    };
    this.checkVacio = this.checkVacio.bind(this);
    this.checkGuia = this.checkGuia.bind(this);
    this.checkAmbos = this.checkAmbos.bind(this);
    this.checkFactura = this.checkFactura.bind(this);
    this.handleChangeRut = this.handleChangeRut.bind(this);
    this.handleChangePatente = this.handleChangePatente.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formateaRut = this.formateaRut.bind(this);
    this.formateaPatente = this.formateaPatente.bind(this);
    this.cargarPatente = this.cargarPatente.bind(this);
    this.handleMostrarModalEncuesta = this.handleMostrarModalEncuesta.bind(this)
    this.handleMostrarModal = this.handleMostrarModal.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }


handleChange (event ){
  event.preventDefault();
  this.setState({[event.target.name]:  event.target.value})
  //console.log(event.target.name, event.target.value)
}
  onRefresh = () => {
    window.location.reload();
  }

  checkVacio(event) {
    this.setState({ vacio: !this.state.vacio })
    if (this.state.vacio) {
        this.setState({
        tieneDetalle: false,
        tieneKG: false
      })
    } else {
      this.setState({ tieneDetalle: true, tieneKG: true })
    }
  }

  checkFactura(event) {
    this.setState({ tieneFactura: !this.state.tieneFactura })
  }

  checkGuia(event) {
    this.setState({ tieneGuia: !this.state.tieneGuia })
  }

  checkAmbos(event){
    this.setState({ tieneAmbos : !this.state.tieneAmbos })
    if( this.state.tieneAmbos){
      this.setState({ tieneFactura: true })
      this.setState({ tieneGuia: true })
    }else{
      this.setState({ tieneFactura: false })
      this.setState({ tieneGuia: false })
    }
  }

  handleMostrarModal(event) {
    event.preventDefault();
    this.setState({ mostrarModal: true });
  }

  handleMostrarModalEncuesta(event) {
    event.preventDefault();
    this.setState({ rutcompletado: true });
    this.setState({ mostrarModalEncuesta: true }, () => this.handleConcluir(event));
  }

  handleChangePatente(event) {
    event.preventDefault();
    this.setState({ patente: event.target.value.toUpperCase() });
    if (event.target.value.length > 5) {
      this.cargarPatente(event.target.value)
    }
  }

  handleChangeRut(event) {
    event.preventDefault();
    this.setState({ rut: (this.formateaRut(event.target.value)) });
  }

  formateaRut(rut) {
    if (rut !== undefined) {
      var actual = rut.replace(/^0+/, "");
      if (actual !== '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 === 0 && j <= inicio.length - 1) {
            rutPuntos = "." + rutPuntos;
          }
          j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;

      }
      return rutPuntos
    }
  }

  formateaPatente(patente) {
    if (patente !== undefined) {
      var actual = patente.replace(/^0+/, "");
      if (actual !== '' && actual.length > 2) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var patentePuntos = "";
        var i = 0;
        var j = 2;
        for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          patentePuntos = letra + patentePuntos;
          if (j % 3 === 0 && j <= inicio.length - 1) {
            patentePuntos = "-" + patentePuntos;
          }
          j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 2);
        patentePuntos = patentePuntos + "-" + dv;
      }
      return patentePuntos
    }
  }

  cargarPatente(patente) {
    try {
      const obj = { patente: patente }
      var optionAxios = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://accesos.sanjoseapps.cl/api/traerPatente.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos[0] !== undefined) {
          this.setState({ area: datos[0].area })
          this.setState({ rut: datos[0].rut })
          this.setState({ nombre_chofer: datos[0].nombre})
          this.setState({ cliente: datos[0].empresa })
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  getFieldValue() {
    const newRow = {};
    newRow["id"] = 0
    newRow["rut"] = this.state.rut;
    newRow["area"] = this.state.area;
    newRow["empresa"] = this.state.cliente;
    newRow["detalle"] = this.state.detalle;
    newRow["matricula"] = this.state.patente;
    newRow["guia"] = this.state.guia
    newRow["factura"] = this.state.factura
    newRow["num"] = this.state.num
    newRow["observación"] = this.state.observación
    newRow["kg"] = this.state.kg
    newRow["fecha_egreso"] = '30-11--0001 00:00';
    newRow["manual"] = this.state.mostrarModalEncuesta;
    newRow["kg_egreso"] = this.state.kg_egreso  ;
    newRow["detalle_egreso"] = this.state.detalle_egreso;
    newRow["factura_egreso"] = this.state.factura_egreso;
    newRow["fecha_ingreso"] = moment().format("YYYY-MM-DD HH:mm:ss");
    newRow["guia_egreso"] = this.state.guia_egreso;
    let fecha = moment().format("YYYY-MM-DD HH:mm:ss");
    newRow["fecha"] = fecha;
    var optionAxios = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    const obj = { rut: this.state.rut, kg: this.state.kg, nombre: this.state.nombre_chofer, area: this.state.area, factura: this.state.factura, guia: this.state.guia, id_sucursal: localStorage.getItem("id_sucursal"), cliente: this.state.cliente, detalle: this.state.detalle, empresa: this.state.empresa, visita: this.state.visita, motivo: this.state.motivo, patente: this.state.patente, mascarilla: this.state.mascarilla, num: this.state.num, observacion: this.state.observación, fecha: moment().format("YYYY-MM-DD HH:mm:ss"), usuarioAutoriza: localStorage.getItem('idtbl_usuario') };
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/registroVehiculo.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          newRow["id"] = datos;
        };
      });
      if (newRow["id"] === "0") {
        axios.post('https://accesos.sanjoseapps.cl/api/registroVehiculo.php', obj, optionAxios).then(response => {
          const datos = response.data;
          if (datos !== undefined) {
            newRow["id"] = datos;
          };
        });
        this.setState({ mostrarModal: true });
      }
    } catch (error) {
      console.log(error);
    }
    this.onRefresh();
    return newRow;
  }

  render() {
    return (
      <div className='modal-body' style={{ "maxWidth": "500px" }}  >
        <div>
          <div className='form-group' key={1} >
            <div> <h4>FECHA</h4> </div>
            <Form.Control type="text" maxLength="50" disabled field="fecha" ref={"FECHA"} value={moment().format("DD-MM-YYYY HH:mm:ss")} required />
          </div>
          <div className='form-group' key={27} >
            <div style={{ display: "flex" }}> <h4>PATENTE</h4> <h3 style={{ color: "red" }}> {" *"} </h3>   </div>
            <Form.Control className="mobileBox" field="patente" placeholder="PATENTE" ref={"patente"}  onChange={this.handleChangePatente} required type="text" maxLength={"10"} />
          </div>
          <div className='form-group' key={4} >
            <div> <h4>RUT CHOFER</h4> </div>
            <Form.Control type="text" maxLength="12" field="rut" placeholder="RUT CHOFER" value={this.state.rut} onChange={this.handleChangeRut} ref={'rut'} required />
          </div>
          <div className='form-group' key={41} >
            <div> <h4>NOMBRE CHOFER</h4> </div>
            <Form.Control type="text" maxLength="50" field="nombre" placeholder="NOMBRE CHOFER" name="nombre_chofer" onChange={this.handleChange} ref={'nombre_chofer'} required />
          </div>
          <div className='form-group' key={6} >
            <div> <h4>CLIENTE</h4> </div>
            <Form.Control type="text" maxLength="50" field="cliente"placeholder="CLIENTE" ref={"cliente"} name="cliente" onChange={this.handleChange} required />
          </div>
          <div className='form-group' key={5} >
            <div> <h4>AREA</h4> </div>
            <Form.Control type="text" maxLength="50" field="area" placeholder="AREA" ref={"area"} name="area" onChange={this.handleChange} required />
          </div>
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" checked={this.state.vacio} onClick={this.checkVacio} type="checkbox" id="invalidCheck" required />
              <label class="form-check-label" for="invalidCheck">
                <h4>CARGA VACIA</h4>
              </label>
            </div>
          </div>
          <div class="form-group" hidden={this.state.vacio} >
            <div class="form-check">
              <input class="form-check-input" checked={!this.state.tieneGuia} onClick={this.checkGuia} type="checkbox" id="checkguia" />
              <label class="form-check-label" for="checkguia">
                <h4>GUIA</h4>
              </label>
              <input class="form-check-input" style={{ "margin": "4.8px 0px 0px 20px" }} checked={!this.state.tieneFactura} onClick={this.checkFactura} type="checkbox" id="checkFactura" />
              <label class="form-check-label" style={{ "margin": "0px 0px 0px 35px" }} for="checkFactura">
                <h4>FACTURA</h4>
              </label>
                  <input class="form-check-input" style={{ "margin": "4.8px 0px 0px 20px" }} checked={this.state.tieneAmbos} onClick={this.checkAmbos} type="checkbox" id="checkAmbos" />
              <label class="form-check-label" style={{ "margin": "0px 0px 0px 35px" }} for="checkAmbos">
                <h4>AMBOS</h4>
              </label>
            </div>
          </div>
          <div hidden={(this.state.vacio)} >
            <div className='form-group' hidden={(this.state.tieneGuia)} key={12} >
              <div> <h4>GUIA</h4> </div>
              <Form.Control type="text" rows={2} maxLength="250" disabled={this.state.tieneGuia} field="guia" placeholder="GUIA" ref={"guia"} onChange={this.handleChange} name="guia" required />
            </div>
          </div>
          <div className='form-group' hidden={this.state.tieneFactura} key={53} >
            <div> <h4>FACTURA</h4> </div>
            <Form.Control type="text" rows={2} maxLength="250" disabled={this.state.tieneFactura} field="factura" placeholder="FACTURA" ref={"factura"} name="factura" onChange={this.handleChange} required />
          </div>
          <div className='form-group' hidden={this.state.vacio} key={15} >
            <div> <h4>DETALLE</h4> </div>
            <Form.Control type="text" maxLength="50" field="detalle" disabled={this.state.tieneDetalle} value={this.state.detalle} placeholder="DETALLE" ref={"detalle"} name="detalle" onChange={this.handleChange} required />
          </div>
          <div className='form-group' hidden={this.state.vacio} key={7} >
            <div> <h4>KG</h4> </div>
            <Form.Control type="number" maxLength="50" field="kg" disabled={this.state.tieneKG} value={this.state.kg} placeholder="KG" ref={"kg"} onChange={this.handleChange}  name="kg" required />
          </div>
          <div className='form-group' key={14} >
            <div> <h4>OBSERVACIÓN</h4> </div>
            <Form.Control type="textarea" rows={2} maxLength="250" field="observación" placeholder="OBSERVACIÓN" ref={"observacion"}  name="observación" onChange={this.handleChange} required />
          </div>
        </div>
      </div>
    );
  }
}


export default ModalIngreso;
