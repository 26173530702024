import React, { Component } from 'react';
import { Form, Button, Modal as Mod } from 'react-bootstrap';
import axios from 'axios';import Image from 'react-bootstrap/Image'
import moment from 'moment';
import QrReader from 'react-camera-qr'
import QRCode from 'qrcode.react';
import aprobar from './../../img/aprobar.png'

import denegar from './../../img/denegar.png'
class ModalSalidaQR extends Component {
  constructor() {
    super();
    this.state = {
      rut: "",
      nombre: '',
      apellido: '',
      result: 'No result',
      cargo: '',
      empresa: '',
      patente: '',
      temperatura: '',
      mascarilla: '1',
      visita: '0',
      num: '0',
      motivo: '',
      link: 'https://accesos.sanjoseapps.cl/api/credenciales/sinfoto.png',
      rutcompletado: true,
      pregunta_A: "0",
      pregunta_B: "0",
      pregunta_C: "0",
      pregunta_D: "0",
      enfermedad: "",
      pais: "",
      sintomas: "",
      observación: '',
      activo: '0',
      fechaSalida: '',
      mostrarModalAcesso: false,
      mostrarModalEncuesta: false,
      mostrarModal: false,
      fecha: ''
    };
    this.formateaRut = this.formateaRut.bind(this);
    this.cargarUsuario = this.cargarUsuario.bind(this);
    this.handleMostrarModalEncuesta = this.handleMostrarModalEncuesta.bind(this)
    this.handleMostrarModal = this.handleMostrarModal.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this)
    this.desformatearRut = this.desformatearRut.bind(this)
    this.marcarSalida = this.marcarSalida.bind(this)
  }

  handleMostrarModal(event) {
    event.preventDefault();
    this.setState({ mostrarModal: true });
  }

  desformatearRut(rut) {
    if (rut !== undefined) {
      var actual = rut.replace(/^0+/, "");
      if (actual !== '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        return actualLimpio
      }
    }
  }

  revisarImg(rut_usuario){
    const obj = { rut: this.desformatearRut(rut_usuario) };
    try {
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        axios.post('https://accesos.sanjoseapps.cl/api/buscarImagenRut.php',  obj,optionAxios).then(response => {
            const datos = response.data
            if (datos !== undefined) {
                //console.log(datos);
                let valor = response.data;
                valor = "https://accesos.sanjoseapps.cl/api/credenciales/" + valor;
                this.setState({ link: valor })
            } else {
                ////console.log("No existen codigos sin asignar");
            }
        })
    } catch (error) {
        console.error(error);
    }
}

  handleScan = data => {
    if (data) {
      this.setState({ rut: this.formateaRut(data) })
      this.revisarImg(this.state.rut);
      this.setState({ mostrarModalAcesso: true })
    }
  }
  handleError = err => {
    console.error(err)
  }

  handleMostrarModalEncuesta(event) {
    event.preventDefault();
    this.setState({ rutcompletado: true });
    this.setState({ mostrarModalEncuesta: true }, () => this.handleConcluir(event));
  }


  marcarSalida(){
       const obj = { id: this.state.id, fecha : moment().format("YYYY-MM-DD hh:mm:ss") };
    var optionAxios = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/marcarSalidaQR.php', obj,optionAxios).then(response => {
              const datos = response.data;
              if (datos !== undefined) {
                //console.log(datos)
                this.props.mostrarModal();
              };
      });
      } catch (error) {
      ////console.log(error);
  }
}

  formateaRut(rut) {
    if (rut !== undefined) {
      var actual = rut.replace(/^0+/, "");
      if (actual !== '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 === 0 && j <= inicio.length - 1) {
            rutPuntos = "." + rutPuntos;
          }
          j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
        if (rutPuntos.length > 9) {
          this.cargarUsuario(rutPuntos);
          this.setState({ rutcompletado: false });
        }
      }
      return rutPuntos
    }
  }

  cargarUsuario(rut) {
    try {
      const obj = { rut: rut }
      var optionAxios = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://accesos.sanjoseapps.cl/api/traerPersonaRutSalida.php', obj, optionAxios).then(response => {
        const datos = response.data;
        //console.log(datos)
        if(datos == []){
          alert("Persona no identificada.")
          this.props.mostrarModal();
        }
        if (datos[0] !== undefined) {
          this.setState({ nombre: datos[0].nombre })
         if (datos[0].nombre.length > 0){
          this.setState({ apellido: datos[0].apellido })
          this.setState({ cargo: datos[0].cargo })
          this.setState({ empresa: datos[0].empresa })
          this.setState({ patente: datos[0].patente })
          this.setState({ rut: datos[0].rut })
          this.setState({ id: datos[0].id})
          this.setState({ fecha: datos[0].fecha })
        }
      }else{
        alert("Persona no identificada.")
        this.props.mostrarModal();
      }
      });
    } catch (error) {
      //console.log(error);
    }
  }

  getFieldValue() {
    const newRow = {};
    newRow["id"] = 0
    newRow["rut"] = this.state.rut;
    newRow["persona"] = this.state.nombre + " " + this.state.apellido
    newRow["nombre"] = this.state.nombre;
    newRow["apellido"] = this.state.apellido;
    newRow["cargo"] = this.state.cargo;
    newRow["empresa"] = this.state.empresa;
    newRow["patente"] = this.state.patente;
    newRow["temperatura"] = this.state.temperatura
    newRow["mascarilla"] = this.state.mascarilla
    newRow["visita"] = this.state.visita
    newRow["motivo"] = this.state.motivo
    newRow["num"] = this.state.num
    newRow["observación"] = this.state.observación
    newRow["fechaSalida"] = '30-11--0001 00:00';
    newRow["manual"] = this.state.mostrarModalEncuesta;
    let fecha = moment().format("YYYY-MM-DD HH:mm:ss");
    newRow["fecha"] = fecha;
    var optionAxios = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    const obj = { rut: this.state.rut, nombre: this.state.nombre, id_sucursal: localStorage.getItem("id_sucursal"), apellido: this.state.apellido, cargo: this.state.cargo, empresa: this.state.empresa, patente: this.state.patente, visita: this.state.visita, motivo: this.state.motivo, temperatura: this.state.temperatura, mascarilla: this.state.mascarilla, num: this.state.num, observacion: this.state.observación, fecha: moment().format("YYYY-MM-DD HH:mm:ss"), usuarioAutoriza: localStorage.getItem('idtbl_usuario') };
    //console.log(obj)
    try {

      axios.post('https://accesos.sanjoseapps.cl/api/registroIngreso.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          newRow["id"] = datos;
          this.setState({ mostrarModalAcesso: false })
          this.props.mostrarModal();
        };
      });

      this.setState({ mostrarModal:   true });
    } catch (error) {
      //console.log(error);
    }

    return newRow;
  }

  render() {
    return (
      <div className='modal-body'>
        <div>
          <QrReader  delay={300}  onError={this.handleError}   onScan={this.handleScan} style={{ width: '100%' }}/>
        </div>
        <div>
        </div>
        <Mod show={this.state.mostrarModalAcesso} onHide={() =>  this.setState({ mostrarModalAcesso: false })}  backdrop="static" keyboard={false}>
          <Mod.Header closeButton>
            <Mod.Title> MARCAR SALIDA</Mod.Title>
          </Mod.Header>
          <Mod.Body>
            <div class="row">
              <div class="column" style={{ float: "left", width: "33.33%" }}>
                <Image  src={this.state.link} roundedCircle style={{margin: "1px"}}  ></Image>   </div>
              <div class="column" style={{ float: "left", width: "43.33%" ,"margin-top": "5%", "margin-left": "3%"}} >  Bienvenido  <h4> {this.state.nombre + " " + this.state.apellido}  </h4>   <h4> Cargo :  {this.state.cargo}</h4>  <h4> Empresa:  {this.state.empresa}</h4> <h4> Entrada:  {this.state.fecha}</h4> </div>
            </div>
            <br></br>

          </Mod.Body>
          <Mod.Footer>
          <Button variant="secondary" id="Cancelar" onClick={() =>  this.marcarSalida() }>
              MARCAR SALIDA
           </Button>
          <Button variant="secondary" id="Cancelar" onClick={() =>
          this.setState({ mostrarModalAcesso: false },       this.props.mostrarModal()) }>
              Cancelar
           </Button>
          </Mod.Footer>
        </Mod>
      </div>
    );
  }
}


export default ModalSalidaQR;
