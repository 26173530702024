import React from 'react';
import { Form, Button, Badge, Modal as Mod } from 'react-bootstrap';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Redirect } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { Card, CardBody, Col, Fade } from 'reactstrap';
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from '../../img/logo.png';
export default class Busqueda extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            posicion_tab: "1",
            idtbl_campo: 0,
            alertEnvioExitoso: false,
            rut: "",
            navigate: "",
            mostrarModalInformacion: false,
            isLoading: false,
            mostrarModal: false,
            usuarioAutorizado: false,
            nonSelectable:[],
            nombre: '',
            apellido: '',
            cargo: '',
            empresa: '',
            ingresos: [],
            pantete: '',
            temperatura: '',
            sucursales: [],
            id_sucursal: localStorage.getItem("id_sucursal"),
            mascarilla: '',
            fechaSelecta: moment().format("YYYY-MM-DD"),
            visita: '',
            num: '',
            observación: '',
            fechaSalida: '',
            Personas: [],
            lista_id_para_envio: []
        };
        this.obtenerNombreSucursal = this.obtenerNombreSucursal.bind(this);
        this.validaLogin = this.validaLogin.bind(this);
        this.formatoApruebo = this.formatoApruebo.bind(this);
        this.usoMascarilla = this.usoMascarilla.bind(this);
        this.formatotemperatura = this.formatotemperatura.bind(this);
        this.guardarFecha = this.guardarFecha.bind(this);
        this.marcarSalida = this.marcarSalida.bind(this);
        this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
        this.formatoh5 = this.formatoh5.bind(this);
        this.btnNuevoIngreso = this.btnNuevoIngreso.bind(this);
        this.Aprobar = this.Aprobar.bind(this);
        this.guardarSalida = this.guardarSalida.bind(this);
        this.handleChangeFechaDesde = this.handleChangeFechaDesde.bind(this);
        this.obtenerIngresosDiarios = this.obtenerIngresosDiarios.bind(this);
        this.changeFecha = this.changeFecha.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onBeforeSaveCell = this.onBeforeSaveCell.bind(this);
        this.existeEncuesta = this.existeEncuesta.bind(this);
        this.abrirEncuesta = this.abrirEncuesta.bind(this);
        this.createCustomModalFooter = this.createCustomModalFooter.bind(this);
        this.createCustomModalBody = this.createCustomModalBody.bind(this);
        this.handleChangeCodigoDropBox = this.handleChangeCodigoDropBox.bind(this);
        this.traerSucursales = this.traerSucursales.bind(this);
        this.formatoEntrada = this.formatoEntrada.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.changeMascarilla = this.changeMascarilla.bind(this);
        this.changeVisita = this.changeVisita.bind(this);
        this.changeNum = this.changeNum.bind(this)
    }

    componentDidMount() {
        this.validaLogin()
        this.traerSucursales();
    }


    traerSucursales() {
        var obj = {};
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/traerSucursales.php', obj).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                    this.setState({ sucursales: datos });
                };
            });
        } catch (error) {
            //console.log(error);
        }

    }
    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            this.setState({ usuarioAutorizado: false });
        } else {
            this.setState({ usuarioAutorizado: true });
            this.obtenerIngresosDiarios();
        }
    }


    formatotemperatura(row) {
        let theButton;
         if (row > 38 ) {
            theButton = <Badge style={{"color":"white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#ce4552"}} pill variant="danger"> <h5> {row + "°" }  </h5> </Badge>
         }else {
            theButton = <Badge style={{"color":"white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964"}} pill variant="success"> <h5> {row + "°"}  </h5> </Badge>
        }
         return theButton;
      }


    handleChangeFechaDesde(event) {
        event.preventDefault();
        let valor = event.target.value;

        this.setState({ fechaSelecta: valor }, () => { this.obtenerIngresosDiarios() });

    }

    changeMascarilla( value, desc ) {
        for (var i in this.state.ingresos) {
          if (this.state.ingresos[i].mascarilla === value) {
             this.state.ingresos[i].mascarilla = desc;

          }
        }
    }

    changeNum( value, desc ) {
        for (var i in this.state.ingresos) {
          if (this.state.ingresos[i].num === value) {
             this.state.ingresos[i].num = desc;

          }
        }
    }
    changeVisita( value, desc ) {
        for (var i in this.state.ingresos) {
          if (this.state.ingresos[i].visita === value) {
             this.state.ingresos[i].visita = desc;
          }
        }
    }

    changeFecha( value, desc ) {
        for (var i in this.state.ingresos) {
          if (this.state.ingresos[i].fechaSalida === value) {
             this.state.ingresos[i].fechaSalida = desc;
          }
        }
    }

    changeAprobado( value, desc ) {
        for (var i in this.state.ingresos) {
          if (this.state.ingresos[i].nombre_aprueba === null) {
             this.state.ingresos[i].nombre_aprueba = desc;
          }
        }
    }

    abrirEncuesta(row) {
        let valor = row;
        const obj = { id: valor };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/traerEncuesta.php', obj, optionAxios).then(response => {
                const datos = response.data;
                if (datos[0] !== undefined) {
                    this.setState({ preguntaA: datos[0].preguntaA })
                    this.setState({ preguntaB: datos[0].preguntaB })
                    this.setState({ preguntaC: datos[0].preguntaC })
                    this.setState({ preguntaD: datos[0].preguntaD })
                    this.setState({ enfermedad: datos[0].enfermedad })
                    this.setState({ pais: datos[0].pais })
                    this.setState({ sintoma: datos[0].sintoma })

                };
            });
        } catch (error) {
            ////console.log(error);
        }

        ////console.log("abrir encueseta");
        this.setState({ mostrarModal: true });

    }

    existeEncuesta(row) {
        let valor = row
        let theButton;
        if (valor > 0) {
            theButton = <Button style={{ "width": "70%", "height": "80%" }} onClick={() => this.abrirEncuesta(row)}>VER </Button>
        } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d" }} pill variant="success"> <h6> NO COMPLETADA </h6> </Badge>
        }
        return theButton;
    }

    handleChangeCodigoDropBox(event) {
        event.preventDefault();
        let valor = event.target.value
        this.setState({ id_sucursal: valor }, () => { this.obtenerIngresosDiarios() });
    }

    async obtenerIngresosDiarios() {
        const obj = { fecha: this.state.fechaSelecta, id_sucursal: this.state.id_sucursal };
        //console.log(obj)
        try {
            await axios.post('https://accesos.sanjoseapps.cl/api/traerIngresosDiarios.php', obj).then(response => {
                const datos = response.data;
                //console.log(datos);
                if (datos !== undefined) {
                   var nonSelectable = datos.filter( function(item) { return item.id_usuario_aprueba !== "0" })
                    //console.log(nonSelectable)
                   var noSeleccionable = nonSelectable.map(item => item.id)
                   //console.log(noSeleccionable)
                    this.setState({ nonSelectable: noSeleccionable})
                    this.setState({ ingresos: datos })
                };
            });
        } catch (error) {
            //  //console.log(error);
        }
    }

    usoMascarilla(row) {
        let theButton;
        if (row === '0') {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#e89f4d" }} pill variant="danger"> <h5> NO </h5> </Badge>
        } else if (row === '1') {
            theButton = <Badge style={{ "color": "white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964" }} pill variant="success"> <h5> SI </h5> </Badge>
        }
        return theButton;

    }


    guardarSalida(row) {
        let valor = row;
        const obj = { id: valor, fecha: moment().format("YYYY-MM-DD HH:mm:ss") };
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/marcarSalida.php', obj).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                };
            });
        } catch (error) {
            //console.log(error);
        }
        this.obtenerIngresosDiarios();
    }


    marcarSalida(row) {
        let valor = row;
        let theButton;
        theButton = <Button onClick={() => this.guardarSalida(valor)}  > SALIDA</Button>
        return theButton;

    }

    guardarFecha(row) {
        let theButton;
        if (row === '30-11--0001 00:00') {
            theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#e89f4d" }} pill variant="danger">  <h6>  Sin salida marcada </h6>  </Badge>
        } else {
            theButton = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em" }} pill variant="success"> <h6> {row} </h6> </Badge>
        }
        return theButton;
    }

    formatoh5(row) {
        let columna;
        columna = <Badge> <h5> {row} </h5> </Badge>
        return columna;
    }

    formatoApruebo(row) {
        let columna;
        if(row !== null ){
            columna = <Badge> <h5> {row} </h5> </Badge>
        }else{
            columna = <Badge style={{ "color": "white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#e89f4d" }} pill variant="danger">  <h6>  No aprobado </h6>  </Badge>
        }
        return columna;
    }

    formatoEntrada(row) {
        let columna;
         columna =  <Badge> <h5> {row.format("YYYY-MM-DD hh:mm")} </h5> </Badge>
         return columna;
      }

    obtenerNombreSucursal(id){
           for (var i in this.state.sucursales) {
              if (this.state.sucursales[i].id_sucursal === id) {
                return this.state.sucursales[i].nombre
              }
            }
    }


    Aprobar() {
        try {
            let data = this.state.lista_id_para_envio;
            var lista_id = "";
            data.map((item) => { lista_id = lista_id + " " + item + "," });
            lista_id = lista_id.slice(0, -1);
            lista_id = ("(" + lista_id + ")");
            const obj = { lista_id: lista_id, idtblusuario: localStorage.getItem('idtbl_usuario') }
            //console.log(obj);
            if (this.state.lista_id_para_envio !== undefined) {
                axios.post('https://accesos.sanjoseapps.cl/api/aprobarEntrada.php', obj).then(response => {
                    this.setState({ alertEnvioExitoso: true, codigodelaobra: undefined });
                    this.obtenerIngresosDiarios();
                });
            }
            else {
                alert("Ocurrio un error");
            }
            this.mostrarModal(false);
        } catch (error) {

        }
    }

    onAfterSaveCell(row, cellName, cellValue) {
        var persona = row.persona.split(" ")
        var nombre = persona[0]
        var apellido = persona[1]
        const obj = { id: row.id, motivo: row.motivo, nombre : nombre, apellido: apellido, rut : row.rut, cargo: row.cargo, empresa: row.empresa, patente: row.patente, temperatura : row.temperatura, num_credencial: row.num, persona: row.persona,  idtbl_usuario : localStorage.getItem('idtbl_usuario') }
        if (obj !== undefined) {
            axios.post('https://accesos.sanjoseapps.cl/api/actualizarIngreso.php', obj).then(response => {
                this.obtenerIngresosDiarios();
            });
        } else {
            alert("Ocurrio un error");
        }
    }

    onBeforeSaveCell(row, cellName, cellValue) {
        if (cellName === 'rut') {
            this.setState({ "rut": cellValue });
        }
        if (cellName === 'nombre') {
            this.setState({ "nombre": cellValue });
        }
        if (cellName === 'apellido') {
            this.setState({ "apellido": cellValue });
        }
        if (cellName === 'cargo') {
            this.setState({ "cargo": cellValue });
        }
        if (cellName === 'empresa') {
            this.setState({ "empresa": cellValue });
        }
        if (cellName === 'pantete') {
            this.setState({ "pantete": cellValue });
        }
        if (cellName === 'temperatura') {
            this.setState({ "temperatura": cellValue });
        }
        if (cellName === 'mascarilla') {
            this.setState({ "mascarilla": cellValue });
        }
        if (cellName === 'visita') {
            this.setState({ "visita": cellValue });
        }
        if (cellName === 'num') {
            this.setState({ "num": cellValue });
        }
        if (cellName === 'observación') {
            this.setState({ "observación": cellValue });
        }
        return true;

    }

    btnNuevoIngreso = (onClick) => {
        return (
            <Button onClick={onClick}>Nuevo Ingreso</Button>
        );
    }

    createCustomModalBody = (columns, validateState, ignoreEditable) => {

    }

    createCustomModalHeader(onClose, onSave) {
        const headerStyle = {
            fontWeight: 'bold',
            fontSize: 'large',
            textAlign: 'center',
            backgroundColor: '#eeeeee'
        };
        return (
            <div className='modal-header' style={headerStyle}>
                <h3>Nuevo Ingreso</h3>
                <button className='btn btn-info' onClick={onClose}> X </button>
            </div>
        );
    }
    //...
    beforeClose(e) {
        alert(`[Custom Event]: Modal close event triggered!`);
    }

    beforeSave(e) {
        alert(`[Custom Event]: Modal save event triggered!`);
    }

    handleModalClose(closeModal) {
        closeModal();
    }

    handleSave(save) {
        save();
    }

    createCustomModalFooter = (onClose, onSave) => {
        const style = {
            backgroundColor: '#ffffff'
        };
        return (
            <div className='modal-footer' style={style}>
                <button className='btn btn-xs btn-info' onClick={onClose}>Cerrar</button>
                <button className='btn btn-xs btn-danger' onClick={onSave}>Confirmar</button>
            </div>
        );
    }
    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const doc = new jsPDF(orientation, unit, size);
        //doc.addImage(logo, "JPG", 700, 5, 110, 70);
        doc.setFontSize(10);
        var nombre_sucursal = this.obtenerNombreSucursal(this.state.id_sucursal);
        const empresa = "San José Farms";
            const sistema = "Sistema de acceso ";
            const sucursal = "Sucursal " + nombre_sucursal;
        const title = "Reporte del día " + this.state.fechaSelecta;
        const version = "Código: PL.SS.5.2  Versión: 04 " ;

        this.changeMascarilla("0",'NO');
        this.changeMascarilla("1",'SI');
        this.changeNum("0",'NO');
        this.changeNum("1",'SI');
        this.changeVisita("0",'NO');
        this.changeVisita("1",'SI');
        this.changeFecha('30-11--0001 00:00','SIN MARCAR')
        this.changeAprobado('',' No aprobado')

       const headers = [["ENTRADA ", "SALIDA", "RUT", "NOMBRE", "CARGO", "EMPRESA", "T°", "MASCARILLA", "MOTIVO", "VISITA", "CREDENCIAL", "RESPONSABLE", "APROBADO" ]];
        const data = this.state.ingresos.map(elt => [elt.fecha, elt.fechaSalida, elt.rut, elt.persona, elt.cargo, elt.empresa, elt.temperatura, elt.mascarilla , elt.motivo, elt.visita, elt.num, elt.nombre_responsable, elt.nombre_aprueba]);
        let content = {
            startY: 120,
            head: headers,
            columnStyles: {
                0: {
                    columnWidth:60
                },
                1: {
                    columnWidth: 60
                },
                2: {
                    columnWidth: 65
                },
                3: {
                    columnWidth: 70
                },
                4: {
                    columnWidth: 65
                },
                5: {
                    columnWidth: 60
                },
                6: {
                    columnWidth: 30//temperatura
                },
                7: {
                    columnWidth: 50
                },
                8: {
                    columnWidth: 60
                },
                9: {
                    columnWidth: 50
                },
                10: {
                    columnWidth: 60
                },
                11: {
                    columnWidth: 60
                },
                12: {
                    columnWidth: 70
                }
            },
            didDrawPage: function (data) {
                // Header
                doc.addImage(logo, 'JPG', 700, 5, 130, 90)
                var str = 'Página ' + doc.internal.pageSize
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' de ' + doc.internal.getNumberOfPages()
                }
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                doc.text(str, data.settings.margin.left, pageHeight - 10)
            },
            margin: { top: 95 },
            body: data, theme: 'grid'
        };
        doc.setFontSize(20);
        doc.text(empresa, 40, 30);
        doc.setFontSize(15);
        doc.text(sistema, 40, 50);
        doc.setFontSize(18);
        doc.text(sucursal, 350, 30);
        doc.setFontSize(13);
        doc.text(title, 40, 65);
        doc.setFontSize(8);
        doc.text(version, 700, 90);
        doc.setFontSize(8);
        doc.autoTable(content);
        doc.save("Ingreso_"+nombre_sucursal+ "_"+this.state.fechaSelecta+".pdf")
    }

    onRowSelect(row, isSelected) {
        var rowStr = "";
        for (var prop in row) {
            rowStr += prop + ": '" + row[prop] + "' ";
        }
        if (isSelected) {
            let array = this.state.lista_id_para_envio;
            array.push(row.id);
            this.setState({ lista_id_para_envio: array });
            //console.log(this.state.lista_id_para_envio)
        }
        else
        {
            let array = this.state.lista_id_para_envio;
            var index = array.indexOf(row.id)
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ lista_id_para_envio: array });
            }
            //console.log(this.state.lista_id_para_envio)
        }
    }

    onSelectAll(isSelected, currentDisplayAndSelectedData) {
        //console.log(isSelected);
        let array = [];
        if (isSelected) {
            for (let i = 0; i < currentDisplayAndSelectedData.length; i++) {
                let data = currentDisplayAndSelectedData[i];
                array.push(data.id)
            }
            this.setState({ lista_id_para_envio: array });
        }
        else
        {
            this.setState({ lista_id_para_envio: array });
        }
        //console.log(array)
    }

    render() {

        const selectRowProp = {
            mode: "checkbox",
            clickToSelect: true,
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
            nonSelectable: this.state.nonSelectable,
            nonSelectableStyle: { backgroundColor: 'gray' }

        };

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: this.onBeforeSaveCell, // a hook for before saving cell
            afterSaveCell: this.onAfterSaveCell  // a hook for after saving cell
        };

        //...
        const options = {
            insertBtn: this.btnNuevoIngreso
            , insertModalBody: this.createCustomModalBody
            , insertModalHeader: this.createCustomModalHeader
            , insertModalFooter: this.createCustomModalFooter,
            sizePerPageList: [ {
                text: '20', value: 20
              },{
               text: '50', value: 50
              },{
                  text: '100', value: 1000
              },{
                text: 'Todos', value: this.state.ingresos.length
              } ], // you can change the dropdown list for size per page
              sizePerPage: 50,  // which size per page you want to locate as default
              prePage: 'Anterior', // Previous page button text
              nextPage: 'Siguiente', // Next page button text
              firstPage: 'Primera', // First page button text
              lastPage: 'Última', // Last page button text
              paginationPosition: 'top'  // default is bottom, top and both is all availabl

        };

        var login = localStorage.getItem('idtbl_usuario');
        if (login === null || login === "undefined") {
            return <Redirect to="/" push={true} />
        } else {
            return (
                <React.Fragment>
                    <Col md={12} lg={12}>
                        <Card>

                            <Mod show={this.state.mostrarModal} onHide={() => this.setState({ mostrarModal: false })} keyboard={false}>
                                <Mod.Header closeButton>

                                    <Mod.Title>
                                    <h5 style={{"text-align": "right"}} className="bold-text">      SGSST-FOR-34 REV.02 </h5>
                                        CUESTIONARIO INGRESO A PLANTA (CONTINGENCIA COVID-19)
                                        </Mod.Title>
                                </Mod.Header>
                                <Mod.Body>
                                    <div key={1} className="mb-3">
                                        <p>
                                            <h4>  A): ¿Presenta alguna enfermedad infectocontagiosa? Si es afirmativo, indique cuál  &nbsp;&nbsp;
                                    {this.state.preguntaA === "1" ?
                                                    <Form.Check label="SI" checked inline name="formHorizontalRadios" id="formHorizontalRadios1" disabled type="radio" />
                                                    : <Form.Check label="NO" checked inline name="formHorizontalRadios" id="formHorizontalRadios1" disabled type="radio" />
                                                }
                                                <br></br>
                                                <Form.Control type="text" maxLength="250" field="rut" placeholder="DESCRIBIR ENFERMEDAD INFECTOCONTAGIOS" value={this.state.enfermedad} disabled ref={'rut'} />
                                            </h4>
                                        </p>
                                    </div>
                                    <br></br>
                                    <div key={2} className="mb-3">
                                        <p>
                                            <h4>  B): ¿Ha estado en contacto con alguna persona que tenga sospecha o este contagiado con Covid-19?  &nbsp;&nbsp;
                                {this.state.preguntaB === "1" ?
                                                    <Form.Check label="SI" inline name="formHorizontalRadiosb" disabled checked id="formHorizontalRadios1" type="radio" />
                                                    : <Form.Check label="NO" inline name="formHorizontalRadiosb" disabled checked id="formHorizontalRadios1" type="radio" />
                                                }
                                            </h4>
                                        </p>
                                    </div>
                                    <br></br>
                                    <div key={3} className="mb-3">
                                        <p>
                                            <h4>   C): En las ultimas 2 semanas ¿Ha viajado fuera del pais?. Si su respuesta es afirmativa indique que pais:  &nbsp;&nbsp;
                                {this.state.preguntaC === "1" ?
                                                    <Form.Check label="SI" inline checked name="formHorizontalRadiosc" disabled id="formHorizontalRadios1" type="radio" />
                                                    : <Form.Check label="NO" inline checked name="formHorizontalRadiosc" disabled id="formHorizontalRadios1" type="radio" />
                                                }
                                                <br></br>
                                                <Form.Control type="text" maxLength="50" field="rut" value={this.state.pais} placeholder="INDICAR PAIS" disabled ref={'rut'} />
                                            </h4>
                                        </p>
                                    </div>
                                    <br></br>
                                    <div key={4} className="mb-3">
                                        <p>
                                            <h4> D): ¿Presenta ud., alguno de los siguientes sintomas: Tos seca, Fiebre sobre 38°C o secrecion nazal?. Si es afirmativo indique cuál  &nbsp;&nbsp;
                                {this.state.preguntaD === "1" ?
                                                    <Form.Check label="SI" checked inline name="formHorizontalRadiosd" id="formHorizontalRadios1" disabled type="radio" />
                                                    : <Form.Check label="NO" checked inline name="formHorizontalRadiosd" id="formHorizontalRadios1" disabled type="radio" />
                                                }
                                                <br></br>
                                                <Form.Control type="text" maxLength="250" placeholder="INDICAR SINTOMAS" value={this.state.sintoma} disabled={true} />
                                            </h4>
                                        </p>
                                    </div>
                                </Mod.Body>
                                <Mod.Footer>
                                    <Button variant="secondary" id="Cancelar" onClick={() => this.setState({ mostrarModal: false })}>
                                        Cancelar
                        </Button>
                                </Mod.Footer>
                            </Mod>

                            <Button onClick={() => this.exportPDF()}>Generar PDF  </Button>

                            {
                                this.state.isLoading === true ?
                                    (<section className="table table-responsive mt-md-5">
                                        <div className="container table">
                                            <div align="center">
                                                <Spinner type="grow" color="success" /> </div>
                                        </div>
                                    </section>)
                                    :
                                    (
                                        <Fade in={true} tag="h5" className="mt-3" transition={{ in: true, timeout: 750 }} >
                                            <CardBody>
                                                <div className="card__title">
                                                    <h5 className="bold-text"> Búsqueda de ingresos</h5>
                                                </div>
                                                <div className="form__form-group" style={{ "container": "wrap" }} >
                                                    <div className="row">
                                                        <h3 style={{ "margin": "2px 10px 6px 6px" }}> Fecha Búsqueda</h3>
                                                        <Form.Group controlId="dob">
                                                            <Form.Control type="date" defaultValue={localStorage.getItem('fechaServidor')} max={localStorage.getItem('fechaServidor')} min='2020-12-01' name="dob" onChangeCapture={this.handleChangeFechaDesde} />
                                                        </Form.Group>
                                                        {localStorage.getItem('tipo_usuario') === "1" ?    <h3 style={{ "margin": "2px 10px 6px 6px" }}> SUCURSAL</h3> :""}
                                                        {localStorage.getItem('tipo_usuario') === "1" ?

                                                                <div>
                                                                    <Form.Control as="select" id={"2"} onChangeCapture={this.handleChangeCodigoDropBox} value={this.state.id_sucursal} >
                                                                        < option key={0} value={-1}> {"Seleccione Sucursal"} </option>
                                                                        {this.state.sucursales.map((r) => {
                                                                            return (< option key={r.id_sucursal} value={r.id_sucursal}> {r.nombre} </option>)
                                                                        })}
                                                                    </Form.Control>

                                                                </div>
                                                            : ""}

                                                    </div>

                                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>

                                                    </div>
                                                </div>

                                                <Button variant="success" id="Aceptar" onClick={() => this.Aprobar()} >APROBAR SELECCION</Button>
                                                    <div className="form__form-group" style={{ "container": "wrap" }} >

                                                    <div className="table" style={{ "margin": "auto", "fontSize": "0.7rem" }} >
                                                        <BootstrapTable scrollTop={'Bottom'} wrapperClasses="table-responsive" selectRow={selectRowProp}  data={this.state.ingresos} cellEdit={cellEditProp} options={options} search={true} searchPlaceholder={"Texto para búsqueda..."} hover pagination >
                                                        <TableHeaderColumn width='90' isKey dataField='id' hiddenOnInsert hidden={true} >ID</TableHeaderColumn>
                                                            <TableHeaderColumn width='150' dataFormat={this.formatoh5} editable={false}  dataSort={ true } dataField='fecha' > <h5> ENTRADA </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='100' dataFormat={this.formatoh5} editable={false} dataSort={ true } dataField='rut' > <h5> RUT  </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='120' dataFormat={this.formatoh5}  dataSort={ true } dataField='persona'> <h5> NOMBRE </h5></TableHeaderColumn>
                                                            <TableHeaderColumn width='115' dataFormat= {this.formatotemperatura} dataSort={ true } dataField='temperatura'> <h5> TEMPERATURA </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='135' dataFormat={this.usoMascarilla} dataField='mascarilla'> <h5> USO MASCARILLA</h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='140' dataFormat={this.guardarFecha} editable={false} dataSort={ true } dataField='fechaSalida'  hiddenOnInsert >  <h5> SALIDA </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='120' dataFormat={this.existeEncuesta} editable={false} dataField='id_encuesta' hiddenOnInsert > <h5> ENCUESTA </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataField='cargo' hidden={true} > <h5> CARGO </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataField='empresa' hidden={true} > <h5> EMPRESA </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='100' dataFormat={this.formatoh5} dataField='patente' hidden={true} > <h5> PATENTE </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='100' dataFormat={this.usoMascarilla} dataField='visita' hidden={true}  > <h5> VISITA </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='150' dataFormat={this.formatoh5} hidden={true} dataField='num' > <h5> NUMERO CREDENCIAL </h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='120' dataFormat={this.formatoh5} dataField='motivo'> <h5> MOTIVO </h5>  </TableHeaderColumn>
                                                            <TableHeaderColumn width='135' dataFormat={this.formatoh5} dataField='nombre_responsable' > <h5> RESPONSABLE</h5> </TableHeaderColumn>
                                                            <TableHeaderColumn width='135' dataFormat={this.formatoApruebo} dataField='nombre_aprueba' > <h5> APROBADO POR</h5> </TableHeaderColumn>

                                                        </BootstrapTable>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Fade>
                                    )
                            }
                        </Card>
                    </Col>
                </React.Fragment >
            )
        }
    }
}


