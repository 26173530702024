import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    changeToGreen: PropTypes.func.isRequired, 
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };


  render() {
    const { changeToDark, changeToLight,changeToGreen } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {(localStorage.getItem('recepcion')=== "true") ?  <SidebarLink title="Recepción personas" icon="home" route="/bandeja" onClick={this.hideSidebar}>  </SidebarLink>    : ""  }
          {localStorage.getItem('casino') === "true"  ?    <SidebarLink title="Entrada al casino" icon="dinner" route="/ingresoCasino" onClick={this.hideSidebar}>  </SidebarLink>       : ""  }
          {localStorage.getItem('camion') === "true" ?    <SidebarLink title="Recepción camiones" icon="car"  route="/vehiculo" onClick={this.hideSidebar} />          : ""  }
          {localStorage.getItem('salida_personas') === "true" ?   <SidebarLink title="Salida personas" icon="home" route="/salida" onClick={this.hideSidebar}>  </SidebarLink>       : ""  }
          {localStorage.getItem('aprobacion') === "true" ?    <SidebarLink title="Aprobacion de ingresos" icon="rocket" route="/busqueda" onClick={this.hideSidebar} />   : ""  }
          {localStorage.getItem('busqueda_llave') === "true" ?     <SidebarLink title="Asignación Llaves" icon="lock" route="/asignacionLlave" onClick={this.hideSidebar}>  </SidebarLink>   : ""  }
        </ul>
        <SidebarCategory title="Búsqueda" icon="eye">
          {localStorage.getItem('busqueda_casino') === "true" ?   <SidebarLink title="Casino por fecha" route="/busquedaCasino" onClick={this.hideSidebar} /> : ""  }
          {localStorage.getItem('busqueda_persona') === "true" ?  <SidebarLink title="Persona por fecha" route="/busqueda" onClick={this.hideSidebar} /> : ""  }
          {localStorage.getItem('busqueda_persona') === "true" ?     <SidebarLink title="Persona por rut" route="/busquedaUsuario" onClick={this.hideSidebar} />  : ""  }
          {localStorage.getItem('busqueda_llave') === "true" ?   <SidebarLink title="Llaves por fecha" route="/busquedaLlaveFecha" onClick={this.hideSidebar} />   : ""  }
          {localStorage.getItem('busqueda_llave') === "true" ?    <SidebarLink title="Llaves por nombre" route="/busquedaLlaveNombre" onClick={this.hideSidebar} />    : ""  } 
          {localStorage.getItem('busqueda_vehiculo') === "true" ?   <SidebarLink title="Vehiculo por fecha" route="/busquedaVehiculoFecha" onClick={this.hideSidebar} />   : ""  }            
        </SidebarCategory>    
        {localStorage.getItem('registrar_personas') === "true" ?  <SidebarLink title="Registrar Persona" icon="magnifier" route="/registroPersona" onClick={this.hideSidebar} />   : ""  }   
        { localStorage.getItem('tipo_usuario') === "1" ?  
            <SidebarCategory title="Mantenedores" icon="list">
            <SidebarLink title="Mantenedor Usuario" route="/registroUsuario" onClick={this.hideSidebar} />
      
            <SidebarLink title="Mantenedor Sucursal" route="/registroSucursal" onClick={this.hideSidebar} />
            <SidebarLink title="Mantenedor Llaves" route="/registroLlave" onClick={this.hideSidebar} />
            <SidebarLink title="Mantenedor Estacionamiento" route="/registroEstacionamiento" onClick={this.hideSidebar} />
          </SidebarCategory> 
            : "" }
            
        {localStorage.getItem('diseño') === "true" ?  
          <SidebarCategory title="Diseño" icon="layers">
            <button type="button" className="sidebar__link" onClick={changeToGreen  }>
              <p className="sidebar__link-title">Tema Original</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Tema Claro</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Tema Oscuro</p>
            </button><kbd></kbd>
        </SidebarCategory>                
          : ""  } 
          <SidebarLink title="Cerrar Sesión" icon="exit" route="/" onClick={this.hideSidebar} />          
      </div>
    );
  }
}

export default SidebarContent;
