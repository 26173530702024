import React from 'react';
import { Card, Col, CardBody, Container, Row } from 'reactstrap';
import Camion from './components/Camion'

const Vehiculos = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 style={{ "text-align": "right" }} className="bold-text">  {localStorage.getItem("id_sucursal") === "1" ?  "Código: SCG-RG 8 POE 35" : "Código: PL.SS.5.2  Versión: 04"}  </h5>
              <h4 className="bold-text"> Vehiculos ingresadas hoy en la sucursal de {localStorage.getItem("nombre_sucursal")}</h4>
            </div>
            <Camion> </Camion>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Vehiculos;
