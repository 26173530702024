import {
  CHANGE_THEME_TO_DARK,
  CHANGE_THEME_TO_LIGHT,
  CHANGE_THEME_TO_GREEN
} from '../actions/themeActions';

const initialState = {
  className: 'theme-green',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME_TO_DARK:
      return { className: 'theme-dark' };
    case CHANGE_THEME_TO_LIGHT:
      return { className: 'theme-light' };
    case CHANGE_THEME_TO_GREEN:
      return { className: 'theme-green' };
  
      default:
      return state;
  }
}
