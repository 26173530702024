import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import Bandeja from '../Bandeja/index';
import RegistroUsuario from '../RegistroUsuario/index';
import RegistroPersona from '../RegistroPersona/index';
import CambioContraseña from '../CambioContraseña/index';
import Busqueda from '../Busqueda/index';
import Cuestionario from '../Cuestionario/index';
import Cuestionario_SGC_RG from '../Cuestionario_SGC_RG/index';
import BusquedaUsuario from '../Busqueda_usuario/index';
import AsignacionLlave from '../AsignacionLlaves/index';
import RegistrarLlave from '../RegistroLlave/index';
import RegistrarSucursal from '../RegistroSucursal/index';
import BandejaSalida from '../Salida/index';
import Vehiculos from '../Vehiculos/index';
import Busqueda_llave_fecha from '../Busqueda_llave_fecha/index';
import Busqueda_llave_nombre from '../Busqueda_llave_nombre/index';
import Busqueda_vehiculo_fecha from '../Busqueda_vehiculo_fecha/index';
import RegistrarEstacionamiento from '../RegistroEstacionamiento/index';
import Casino from '../Casino/index';
import Busqueda_casino from '../Busqueda_casino/index';
const Pages = () => (
  <Switch>
    <Route exact path="/bandeja" component={Bandeja} />   
    <Route path="/salida" component={BandejaSalida} />
    <Route path="/ingresoCasino" component={Casino} />
    
    <Route path="/registroUsuario" component={RegistroUsuario} />
    <Route path="/registroLlave" component={RegistrarLlave} />
    <Route path="/cambioContraseña" component={CambioContraseña} />
    <Route path="/busqueda" component={Busqueda} />
    <Route path="/vehiculo" component={Vehiculos} />
    <Route path="/busquedaLlaveFecha" component={Busqueda_llave_fecha} />
    <Route path="/registroEstacionamiento" component={RegistrarEstacionamiento} />
    <Route path="/busquedaLlaveFecha" component={Busqueda_llave_fecha} /> 
    <Route path="/busquedaLlaveNombre" component={Busqueda_llave_nombre} />
    <Route path="/busquedaCasino" component={Busqueda_casino} />
    <Route path="/busquedaVehiculoFecha" component={Busqueda_vehiculo_fecha} />
    <Route path="/busquedaUsuario" component={BusquedaUsuario} />
    <Route path="/asignacionLlave" component={ AsignacionLlave} />
    <Route path="/registroSucursal" component={RegistrarSucursal} />
    <Route path="/registroPersona" component={RegistroPersona} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" component={Pages} />
    </div>
  </div>
);  

const Router = () => (
  <MainWrapper basename={'/Entrada'}  >
    <main>
    <HashRouter >
      <Switch>
        <Route exact path="/Entrada/" component={LogIn} />
        <Route exact path="/" component={LogIn} />
        <Route path="/cuestionarioCovid" component={Cuestionario} />
        <Route path="/cuestionarioCovid_SGC_RG" component={Cuestionario_SGC_RG} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
  </HashRouter>
    </main>
  </MainWrapper>
);

export default Router;