import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Form, Button } from 'react-bootstrap';
import { Card, CardBody, Col, Input } from 'reactstrap';

class RegistroPersona extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            rut: "",
            apellido: "",
            cargo: "",
            empresa: "",
            alertEnvioExitoso: false,
            nombre: "",
            segundaEmpresa: "",
            dv: "",
            idSucursal: 0,
            sucursales: [],
            imagen: '',
            link: 'https://accesos.sanjoseapps.cl/api/credenciales/sinfoto.png',
            activo: false
        };
        this.handleChangeRut = this.handleChangeRut.bind(this);
        this.registrarUsuario = this.registrarUsuario.bind(this);
        this.formateaRut = this.formateaRut.bind(this);
        this.desformatearRut = this.desformatearRut.bind(this);
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.handleChangeApellido = this.handleChangeApellido.bind(this);
        this.handleChangeCargo = this.handleChangeCargo.bind(this);
        this.usuarioActivo = this.usuarioActivo.bind(this);
        this.handleChangeCodigoDropBox = this.handleChangeCodigoDropBox.bind(this);
        this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
        this.handleChangeSegundaEmpresa = this.handleChangeSegundaEmpresa.bind(this);
    }

    componentDidMount() {
    }


    formateaRut(rut) {
        if (rut !== undefined) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 === 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            return rutPuntos

        }
    }

    handleChangeNombre(event) {
        event.preventDefault();
        this.setState({ nombre: event.target.value });
    }

    handleChangeApellido(event) {
        event.preventDefault();
        this.setState({ apellido: event.target.value });
    }

    handleChangeCargo(event) {
        event.preventDefault();
        this.setState({ cargo: event.target.value });
    }

    handleChangeEmpresa(event) {
        event.preventDefault();
        this.setState({ empresa: event.target.value });
    }

    handleChangeSegundaEmpresa(event) {
        event.preventDefault();
        this.setState({ segundaEmpresa: event.target.value });
    }

    usuarioActivo() {
        if (this.state.activo) {
            this.setState({ activo: false });
        } else {
            this.setState({ activo: true });
        }
    }

    handleChangeCodigoDropBox(event) {
        event.preventDefault();
        let valor = event.target.value
        this.setState({ idSucursal: valor });
    }

    registrarUsuario(event) {
        event.preventDefault();
        let estado
        if (this.state.activo) {
            estado = 1
        } else {
            estado = 0
        }
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const obj = { cargo: this.state.cargo, empresa: this.state.empresa, apellido: this.state.apellido, nombre: this.state.nombre, rut: this.state.rut, idSucursal: this.state.idSucursal };
        try {
            axios.post('https://accesos.sanjoseapps.cl/api/registrarPersona.php', obj, optionAxios).then(response => {
                const datos = response.data;
                if (datos !== undefined) {
                    this.setState({ alertEnvioExitoso: true });
                    setTimeout(() => { this.setState({ alertEnvioExitoso: false }) }, 10000)

                    this.uploadFile(this.state.imagen)
                };
            });
        } catch (error) {
            //console.log(error);
        }
    }

    handleChangeRut(event) {
        event.preventDefault();
        this.setState({ rut: (this.formateaRut(event.target.value)) });
    }


    onChange = e => {
        this.setState({
            imagen: e.target.files[0]
        })

    }

    async uploadFile(file) {
        const formData = new FormData();
        let nombre = this.desformatearRut(this.state.rut)
        formData.append('avatar', file)
        //console.log(nombre)
        formData.append('rut_foto', nombre)
        //console.log(formData)
        return await axios.post('https://accesos.sanjoseapps.cl/api/subirImagen.php', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }

    desformatearRut(rut) {
        if (rut !== undefined) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");

                return actualLimpio
            }

        }

    }

    render() {
        return (
            <React.Fragment>

                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h4 className="bold-text">Creación de Persona</h4>
                            </div>
                            <Alert variant="success" show={this.state.alertEnvioExitoso} onClose={() => this.setState({ alertEnvioExitoso: false })} dismissible>
                                <Alert.Heading>¡Registro Exitoso!</Alert.Heading>
                                <p>
                                    Se ha registrado el usuario {this.state.nombre}.
                                </p>
                            </Alert>
                            <div className="row">
                                <div className="col" style={{ "marginLeft": "0" }}>
                                    <img src={this.state.link} style={{ width: "20%" }} ></img>
                                    <input type="file" accept=".pdf,.jpg,.jpeg,.png" required name="id_proof" onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div> <h4>RUT</h4> </div>
                                    <div>
                                        <Form.Control type="text" maxLength="12" field="rut" placeholder="RUT" value={this.state.rut} onChange={this.handleChangeRut} required />
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>
                                        <h4>     NOMBRES </h4>
                                    </div>
                                    <div>
                                        <Form.Control type="text" placeholder="Nombre" value={this.state.nombre} onChange={this.handleChangeNombre} maxLength={45} required />
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>
                                        <h4>     APELLIDOS</h4>
                                    </div>
                                    <div>
                                        <Form.Control type="text" placeholder="Apellido" maxLength={45} value={this.state.apellido} onChange={this.handleChangeApellido} required />
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>  <h4>  CARGO </h4></div>
                                    <div>
                                        <Form.Control type="text" placeholder="Cargo" value={this.state.cargo} onChange={this.handleChangeCargo} maxLength={45} required />
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>  <h4>  EMPRESA </h4> </div>
                                    <div>
                                        <Form.Control type="text" placeholder="Empresa" value={this.state.empresa} onChange={this.handleChangeEmpresa} maxLength={45} required />
                                    </div>
                                </div>
                            </div>
                            <br></br>


                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.usuarioActivo()} />  <h4> PESONA HABILITADA PARA EL INGRESO  </h4>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>
                                        <Button onClick={this.registrarUsuario} > Registrar persona </Button>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}

export default RegistroPersona;
