import React from 'react';
import { Card, Col,CardBody, Container, Row } from 'reactstrap';
import { Tabs, Tab } from 'react-bootstrap';
import RegistroPersona from './components/RegistroPersona';
import EditarPersona from './components/EditarPersona';
const RegistrarUsuario = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row>

      <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
      <Tabs defaultActiveKey="NoEnviados" id="uncontrolled-tab-example" >
        <Tab eventKey="NoEnviados" title="Registrar Nueva Persona">
        <RegistroPersona />
      </Tab>
        <Tab eventKey="Editar" title="Editar Persona">
        <EditarPersona />
        </Tab>
      </Tabs>


      </CardBody>
                 </Card>
            </Col>
    </Row>
  </Container>
);

export default RegistrarUsuario;
