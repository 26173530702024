import React  from 'react';
import { Badge, Button, Modal as Mod } from 'react-bootstrap';
import axios from 'axios';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Redirect } from "react-router-dom";
import { Spinner } from 'reactstrap';
import ModalSalidaQR from './ModalSalidaQR'
import { Card, CardBody, Col, Fade} from 'reactstrap';

export default class Bandeja_salida extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            posicion_tab: "1",
            idtbl_campo: 0,
            alertEnvioExitoso: false,
            rut: "",
            navigate: "",
            mostrarModalInformacion:false,
            isLoading: false,
            usuarioAutorizado: false,
            nombre:'',
            apellido:'',
            cargo: '',
            mostrarModal:false,
            empresa: '',
            pantete: '',
            temperatura: '',
            mascarilla: '',
            visita:'',
            num:  '',
            observación:  '',
            ingresos: [],
            fechaSalida: '',
            preguntaA:'',
            preguntaB:'',
            preguntaC:'',
            preguntaD:'',
            sintomas : '',
            enfermedad:'',
            pais:'',
            Personas:[]
        };
        this.validaLogin = this.validaLogin.bind(this);
        this.usoMascarilla = this.usoMascarilla.bind(this);
        this.buscarEncuestaManual =  this.buscarEncuestaManual.bind(this);
        this.guardarFecha = this.guardarFecha.bind(this);
        this.marcarSalida = this.marcarSalida.bind(this);
        this.abrirEncuesta = this.abrirEncuesta.bind(this);
        this.formatoh5 = this.formatoh5.bind(this);
      this.btnNuevoIngreso = this.btnNuevoIngreso.bind(this);
        this.createCustomModalFooter = this.createCustomModalFooter.bind(this);
        this.createCustomModalBody = this.createCustomModalBody.bind(this);
        this.guardarSalida = this.guardarSalida.bind(this);
        this.obtenerIngresosDiarios = this.obtenerIngresosDiarios.bind(this);
        this.existeEncuesta = this.existeEncuesta.bind(this);
        this.mostrarSalidaTarjeta = this.mostrarSalidaTarjeta.bind(this);
        this.formatotemperatura = this.formatotemperatura.bind(this);
        this.formatoEntrada = this.formatoEntrada.bind(this);
    }

    componentDidMount() {
        this.validaLogin()
    }

    validaLogin() {
        if (localStorage.getItem('idtbl_usuario') === undefined) {
            this.setState({ usuarioAutorizado: false });
        } else {
            this.setState({ usuarioAutorizado: true });
            this.obtenerIngresosDiarios();
        }
    }

    obtenerIngresosDiarios(){
        const obj = { fecha : moment().format("YYYY-MM-DD HH:mm:ss"), id_sucursal: localStorage.getItem("id_sucursal")};
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
          axios.post('https://accesos.sanjoseapps.cl/api/traerIngresosDiarios.php', obj, optionAxios).then(response => {
                  const datos = response.data;
                  if (datos !== undefined) {
                       this.setState({ingresos: datos})
                };});
          } catch (error) {
        //  ////console.log(error);
      }

    }

    usoMascarilla(row){
        let theButton;
        if (row === '0') {
            theButton = <Badge style={{"color":"white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#e89f4d"}} pill variant="danger"> <h5> NO </h5> </Badge>
        } else if (row === '1') {
            theButton = <Badge style={{"color":"white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964"}} pill variant="success"> <h5> SI </h5> </Badge>
        }
        return theButton;
    }

    abrirEncuesta(row){
        let valor = row;
        const obj = { id: valor};
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
          axios.post('https://accesos.sanjoseapps.cl/api/traerEncuesta.php', obj,optionAxios).then(response => {
                  const datos = response.data;
                  if (datos[0] !== undefined) {
                        this.setState({preguntaA: datos[0].preguntaA})
                        this.setState({preguntaB: datos[0].preguntaB})
                        this.setState({preguntaC: datos[0].preguntaC})
                        this.setState({preguntaD: datos[0].preguntaD})
                        this.setState({enfermedad: datos[0].enfermedad})
                        this.setState({pais: datos[0].pais})
                        this.setState({sintoma: datos[0].sintoma})
                    };});
          } catch (error) {
       }
       this.setState({mostrarModal : true });
      }

    buscarEncuestaManual( value ) {
        for (var i in this.state.ingresos) {
            if (this.state.ingresos[i].id_encuesta === value) {
                return  this.state.ingresos[i].manual;
            }
        }
    }


    mostrarSalidaTarjeta() {
        this.setState({ mostrarQR: !this.state.mostrarQR })
    this.obtenerIngresosDiarios()
   }

    existeEncuesta(row){
        let valor = row
         let theButton;
        let manual = this.buscarEncuestaManual(valor)
        if( valor > 0){
            if( manual !== "1"){
                 theButton =   <Button style={{"width":"70%", "height":"80%"}} onClick={() => this.abrirEncuesta(row) }>VER </Button>
            }else{

                theButton = <Badge style={{"color":"white", "padding": "0.8em 1.3em 1em 1.3em"}} pill variant="success"> <h6> ENCUESTA MANUAL </h6> </Badge>

            }
        }else{
            theButton = <Badge style={{"color":"white", "padding": "0.8em 1.3em 1em 1.3em", "background-color": "#e89f4d"}} pill variant="success"> <h6> NO COMPLETADA </h6> </Badge>
        }
         return theButton;
    }

    guardarSalida(row){
        let valor = row;
        const obj = { id: valor, fecha : moment().format("YYYY-MM-DD hh:mm:ss") };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
          axios.post('https://accesos.sanjoseapps.cl/api/marcarSalida.php', obj,optionAxios).then(response => {
                  const datos = response.data;
                  if (datos !== undefined) {
                      ////console.log(datos);
                 };});
          } catch (error) {
          ////console.log(error);
      }
      this.obtenerIngresosDiarios();
    }


    marcarSalida(row){
        let valor = row;
        let disable = false
        this.state.ingresos.forEach(element => {
            if(element.id === row) {
                let fecha = element.fechaSalida;
                if( fecha !== '30-11--0001 00:00'){
                    disable = true;
                }
            }
        });
        let theButton;
        theButton = <Button style={{"width":"90%", "height":"80%"}}  disabled={disable}  onClick= { ()=> this.guardarSalida(valor)}  > SALIDA</Button>
        return theButton;

    }

    guardarFecha(row) {
        let theButton;
        if(row ==='30-11--0001 00:00' ){
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em", "background-color": "#e89f4d"}} pill variant="danger">  <h6>  Sin salida marcada </h6>  </Badge>
        }else{
            theButton = <Badge style={{"color":"white", "padding": "0.7em 0.7em 0.7em 0.7em"}} pill variant="success"> <h6> {row} </h6> </Badge>
        }
        return theButton;
    }

    formatoh5(row) {
      let columna;
       columna =  <Badge> <h5> {row} </h5> </Badge>
       return columna;
    }

    formatoEntrada(row) {
        let columna;
         columna =  <Badge> <h5> {row.format("YYYY-MM-DD HH:mm")} </h5> </Badge>
         return columna;
      }

    formatotemperatura(row) {
        let theButton;
         if (row > 38 ) {
            theButton = <Badge style={{"color":"white", "padding": "0.9em 1.5em 0.9em 1.5em", "background-color": "#ce4552"}} pill variant="danger"> <h5> {row + "°" }  </h5> </Badge>
         }else {
            theButton = <Badge style={{"color":"white", "padding": "0.9em 1.5em 1em 1.5em", "background-color": "#95b964"}} pill variant="success"> <h5> {row + "°"}  </h5> </Badge>
        }
         return theButton;
      }

      btnNuevoIngreso = (onClick) => {
        return (<div> <Button onClick={this.mostrarSalidaTarjeta}>Marcar salida con tarjeta</Button></div>);
    }

    createCustomModalBody = (columns, validateState, ignoreEditable) => {
        return (
            <ModalSalidaQR columns={columns}  validateState={validateState}  ignoreEditable={ignoreEditable} />
        );
    }

    createCustomModalHeader(onClose, onSave) {
        const headerStyle = {
            fontWeight: 'bold',
            fontSize: 'large',
            textAlign: 'center',
            backgroundColor: '#eeeeee'
        };
        return (
            <div className='modal-header' style={headerStyle}>
                <h3>Nuevo Ingreso</h3>
                <button className='btn btn-info' onClick={onClose}> X </button>
            </div>
        );
    }

    createCustomModalFooter = (onClose, onSave) => {
        const style = {
            backgroundColor: '#ffffff'
        };
        return (
            <div className='modal-footer' style={style}>
                <button className='btn btn-xs btn-info' onClick={onClose}>Cerrar</button>
                <button className='btn btn-xs tn-danger' onClick={onSave}> Confirmar </button>
            </div>
        );
        }
      render() {
        const options = {
            insertBtn: this.btnNuevoIngreso
            , insertModalBody: this.createCustomModalBody
            , insertModalHeader: this.createCustomModalHeader
            , insertModalFooter: this.createCustomModalFooter
        };
        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: this.onBeforeSaveCell, // a hook for before saving cell
            afterSaveCell: this.onAfterSaveCell  // a hook for after saving cell
        };
        var login = localStorage.getItem('idtbl_usuario');
        if (login === null || login === "undefined" )  {
            return <Redirect to="/" push={true} />
        } else {
            return (
                <React.Fragment>
                    <Col md={12} lg={12}>
                        <Card>
                            {
                                    this.state.isLoading === true ?
                                    (   <section className="table table-responsive mt-md-5">
                                            <div className="container table">
                                                <div align="center">
                                                <Spinner type="grow" color="success" /> </div>
                                            </div>
                                        </section>
                                    )
                                    :
                                    (

                                    <Fade in={true} tag="h5" className="mt-3" transition={{in: true, timeout: 750}} >
                                 {this.state.mostrarQR === true ?
                                             <Mod show={this.state.mostrarQR} onHide={() => this.setState({ mostrarQR: false })} backdrop="static" keyboard={false}>
                                             <Mod.Header closeButton>
                                               <Mod.Title> MARCAR SALIDA </Mod.Title>
                                             </Mod.Header>
                                             <Mod.Body>
                                                     <ModalSalidaQR  mostrarModal = {this.mostrarSalidaTarjeta}> </ModalSalidaQR>
                                             </Mod.Body>
                                             <Mod.Footer>
                                               <Button variant="secondary" id="Cancelar" onClick={() => this.setState({ mostrarQR: false })}>
                                                 Cancelar
                                               </Button>
                                             </Mod.Footer>
                                           </Mod> : ""
                                    }
                                    <CardBody>
                                        <div className="card__title">
                                        <h5 style={{"text-align": "right"}} className="bold-text"> Código: PL.SS.5.2  Versión: 04 </h5>
                                            <h4 className="bold-text"> Personas ingresadas hoy en la sucursal de {localStorage.getItem("nombre_sucursal") }</h4>
                                        </div>
                                  <div className="form__form-group" style={{ "container": "wrap" }} >
                                                <div className="table" style={{ "margin": "auto", "fontSize": "0.7rem" }} >
                                                    <BootstrapTable scrollTop={ 'Bottom' } wrapperClasses="table-responsive"  insertRow cellEdit={cellEditProp} options={options} data={this.state.ingresos} search={true}  searchPlaceholder={"Texto para búsqueda..."} hover  pagination >
                                                        <TableHeaderColumn width='90' isKey dataField='id' hiddenOnInsert hidden={true} >ID</TableHeaderColumn>
                                                        <TableHeaderColumn width='130' dataFormat= {this.formatoh5} dataSort={ true } editable={ false } dataField='fecha' > <h5> ENTRADA </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='100' dataFormat= {this.formatoh5}  dataSort={ true } editable={ false } dataField='rut' > <h5> RUT  </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataFormat= {this.formatoh5} dataSort={ true } editable={ false } dataField='nombre'> <h5> NOMBRE </h5></TableHeaderColumn>
                                                        <TableHeaderColumn width='120' dataFormat= {this.formatoh5} dataSort={ true } editable={ false } dataField='apellido'> <h5> APELLIDO</h5></TableHeaderColumn>
                                                        <TableHeaderColumn width='120' editable={ false } dataField='id_encuesta' dataFormat={this.existeEncuesta}  hiddenOnInsert > <h5> ENCUESTA </h5> </TableHeaderColumn>
                                                        { localStorage.getItem('visita') === "true"   ?
                                                            <TableHeaderColumn width='100'   dataField='visita'   dataFormat={this.usoMascarilla} > <h5> VISITA </h5> </TableHeaderColumn>
                                                            :
                                                            <TableHeaderColumn width='100'   dataField='visita'   dataFormat={this.usoMascarilla} hidden={true}  > <h5> VISITA </h5> </TableHeaderColumn>
                                                        }
                                                        <TableHeaderColumn width='120' editable={ false } dataSort={ true } dataField='fechaSalida' dataFormat={this.guardarFecha}  hiddenOnInsert >  <h5> SALIDA </h5> </TableHeaderColumn>
                                                        <TableHeaderColumn width='120' editable={ false } dataField='id' dataFormat={this.marcarSalida}  hiddenOnInsert > <h5> MARCAR SALIDA </h5> </TableHeaderColumn>
                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Fade>
                                )
                            }
                        </Card>
                    </Col>
                </React.Fragment >
                )
           }
}}
