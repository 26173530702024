import React from 'react';
import { Card, Col,CardBody, Container, Row } from 'reactstrap';
import RegistroSucursal from './components/RegistroSucursal';
const RegistrarSucursal = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
      </Col>
    </Row>
    <Row> 
      <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
        <RegistroSucursal />
      </CardBody>
                 </Card>
            </Col>
    </Row>
  </Container>
);

export default RegistrarSucursal;
