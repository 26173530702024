import React, { Component } from 'react';
import { Form, Button, Modal as Mod } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import QRCode from 'qrcode.react';

class ModalIngreso extends Component {
  constructor() {
    super();
    this.state = {
      rut: "",
      nombre: '',
      apellido: '',
      result: 'No result',
      cargo: '',
      empresa: '',
      patente: '',
      temperatura: '',
      mascarilla: '1',
      visita: '0',
      num: '0',
      motivo: '',
      link: 'https://accesos.sanjoseapps.cl/api/credenciales/sinfoto.png',
      rutcompletado: true,
      pregunta_A: "0",
      pregunta_B: "0",
      pregunta_C: "0",
      pregunta_D: "0",
      enfermedad: "",
      pais: "",
      sintomas: "",
      observación: '',
      activo: '0',
      fechaSalida: '',
      mostrarModalAcesso: false,
      mostrarModalEncuesta: false,
      mostrarModal: false,
      fecha: ''
    };
    this.formateaRut = this.formateaRut.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRut= this.handleChangeRut.bind(this);
    this.cargarUsuario = this.cargarUsuario.bind(this);
    this.handleMostrarModalEncuesta = this.handleMostrarModalEncuesta.bind(this)
    this.handleMostrarModal = this.handleMostrarModal.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this)
    this.handleChangeNombre = this.handleChangeNombre.bind(this);
    this.handleChangeApellido = this.handleChangeApellido.bind(this);
    this.handleChangeRut = this.handleChangeRut.bind(this);
    this.handleChangeCargo = this.handleChangeCargo.bind(this);
    this.handleChangePatente = this.handleChangePatente.bind(this);
    this.handleChangeObservacion = this.handleChangeObservacion.bind(this);
    this.handleChangeVisita = this.handleChangeVisita.bind(this);
    this.handleChangeMascarilla = this.handleChangeMascarilla.bind(this);
    this.handleChangeTemperatura = this.handleChangeTemperatura.bind(this);
    this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
    this.handleChangeNum = this.handleChangeNum.bind(this);
    this.handleChangeMotivo = this.handleChangeMotivo.bind(this);
    this.desformatearRut = this.desformatearRut.bind(this)
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  }

  handleMostrarModal(event) {
    event.preventDefault();
    this.setState({ mostrarModal: true });
  }

  desformatearRut(rut) {
    if (rut !== undefined) {
      var actual = rut.replace(/^0+/, "");
      if (actual !== '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        return actualLimpio
      }
    }
  }

  revisarImg(rut_usuario){
    const obj = { rut: this.desformatearRut(rut_usuario) };
    try {
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        axios.post('https://accesos.sanjoseapps.cl/api/buscarImagenRut.php',  obj,optionAxios).then(response => {
            const datos = response.data
            if (datos !== undefined) {
                console.log(datos);
                let valor = response.data;
                valor = "https://accesos.sanjoseapps.cl/api/credenciales/" + valor;
                this.setState({ link: valor })

            } else {
                //console.log("No existen codigos sin asignar");
            }
        })
    } catch (error) {
        console.error(error);
    }
  }

  handleScan = data => {
    if (data) {
      this.setState({ rut: this.formateaRut(data) })
      this.revisarImg(this.state.rut);
      this.setState({ mostrarModalAcesso: true })
    }
  }

  handleError = err => {
    console.error(err)
  }

  handleChangeEmpresa(event) {
    event.preventDefault();
    this.setState({ empresa: event.target.value });
  }

  handleChangeMotivo(event) {
    event.preventDefault();
    this.setState({ motivo: event.target.value });
  }

  handleChangeNum(event) {
    event.preventDefault();
    this.setState({ num: event.target.value });
  }

  handleChangeNombre(event) {
    event.preventDefault();
    this.setState({ nombre: event.target.value });
  }

  handleChangeApellido(event) {
    event.preventDefault();
    this.setState({ apellido: event.target.value });
  }

  handleChangeCargo(event) {
    event.preventDefault();
    this.setState({ cargo: event.target.value });
  }

  handleChangePatente(event) {
    event.preventDefault();
    this.setState({ patente: event.target.value });
  }

  handleChangeObservacion(event) {
    event.preventDefault();
    this.setState({ observación: event.target.value });
  }

  handleChangeVisita(event) {
    event.preventDefault();
    this.setState({ visita: event.target.value });
  }

  handleChangeMascarilla(event) {
    event.preventDefault();
    this.setState({ mascarilla: event.target.value });
  }

  handleChangeTemperatura(event) {
    event.preventDefault();
    this.setState({ temperatura: event.target.value });
  }


  handleMostrarModalEncuesta(event) {
    event.preventDefault();
    this.setState({ rutcompletado: true });
    this.setState({ mostrarModalEncuesta: true }, () => this.handleConcluir(event));
  }

  handleChangeRut(event) {
    event.preventDefault();
    this.setState({ rut: (this.formateaRut(event.target.value)) });
  }

  handleConcluir(event) {
    event.preventDefault();
    try {
      var optionAxios = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      const obj = { rut: this.state.rut, preguntaA: this.state.pregunta_A, preguntaB: this.state.pregunta_B, preguntaC: this.state.pregunta_C, preguntaD: this.state.pregunta_D, pais: this.state.pais, sintomas: this.state.sintomas, fecha: moment().format("YYYY-MM-DD HH:mm:ss"), enfermedad: this.state.enfermedad, manual: this.state.mostrarModalEncuesta }
      axios.post('https://accesos.sanjoseapps.cl/api/llenarEncuesta.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          this.setState({ btnDisabled: true });
          this.setState({ mostrarModalEncuesta: false });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  formateaRut(rut) {
    if (rut !== undefined) {
      var actual = rut.replace(/^0+/, "");
      if (actual !== '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 === 0 && j <= inicio.length - 1) {
            rutPuntos = "." + rutPuntos;
          }
          j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
        if (rutPuntos.length > 9) {
          this.cargarUsuario(rutPuntos);
          this.setState({ rutcompletado: false });
        }
      }
      return rutPuntos
    }
  }

  cargarUsuario(rut) {
    try {
      const obj = { rut: rut }
      var optionAxios = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post('https://accesos.sanjoseapps.cl/api/traerPersona.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos[0] !== undefined) {
          this.setState({ nombre: datos[0].nombre })
          this.setState({ apellido: datos[0].apellido })
          this.setState({ cargo: datos[0].cargo })
          this.setState({ empresa: datos[0].empresa })
          this.setState({ patente: datos[0].patente })
          this.setState({ activo: datos[0].activo })
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  getFieldValue() {
    const newRow = {};
    newRow["id"] = 0
    newRow["rut"] = this.state.rut;
    newRow["persona"] = this.state.nombre + " " + this.state.apellido
    newRow["nombre"] = this.state.nombre;
    newRow["apellido"] = this.state.apellido;
    newRow["cargo"] = this.state.cargo;
    newRow["empresa"] = this.state.empresa;
    newRow["patente"] = this.state.patente;
    newRow["temperatura"] = this.state.temperatura
    newRow["mascarilla"] = this.state.mascarilla
    newRow["visita"] = this.state.visita
    newRow["motivo"] = this.state.motivo
    newRow["num"] = this.state.num
    newRow["observación"] = this.state.observación
    newRow["fechaSalida"] = '30-11--0001 00:00';
    newRow["manual"] = this.state.mostrarModalEncuesta;
    let fecha = moment().format("YYYY-MM-DD HH:mm:ss");
    newRow["fecha"] = fecha;
    var optionAxios = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    const obj = { rut: this.state.rut, nombre: this.state.nombre, id_sucursal: localStorage.getItem("id_sucursal"), apellido: this.state.apellido, cargo: this.state.cargo, empresa: this.state.empresa, patente: this.state.patente, visita: this.state.visita, motivo: this.state.motivo, temperatura: this.state.temperatura, mascarilla: this.state.mascarilla, num: this.state.num, observacion: this.state.observación, fecha: moment().format("YYYY-MM-DD HH:mm:ss"), usuarioAutoriza: localStorage.getItem('idtbl_usuario') };
    try {
      axios.post('https://accesos.sanjoseapps.cl/api/registroIngreso.php', obj, optionAxios).then(response => {
        const datos = response.data;
        if (datos !== undefined) {
          newRow["id"] = datos;
          this.props.mostrarModal()
        };
      });
      this.setState({ mostrarModal:   true });
    } catch (error) {
      console.log(error);
    }
    return newRow;
  }

  render() {
    return (
      <div className='modal-body'>
        <div>
          <div className='form-group' key={1} >
            <div> <h4>FECHA</h4> </div>
            <Form.Control type="text" maxLength="50" field="fecha" ref={"FECHA"} value={moment().format("DD-MM-YYYY HH:mm:ss")} required />
          </div>
          <div className='form-group' key={4} >
            <div style={{ display: "flex" }} > <h4>RUT</h4> <h3 style={{ color: "red" }}> {" *"} </h3>  </div>
            <Form.Control type="text" maxLength="16" field="rut" placeholder="RUT" value={this.state.rut} onChange={this.handleChangeRut} ref={'rut'} required />
          </div>
          <div className='form-group' key={27} >
            <div style={{ display: "flex" }}> <h4>TEMPERATURA  </h4> <h3 style={{ color: "red" }}> {" *"} </h3>   </div>
            <Form.Control className="mobileBox" field="temperatura" placeholder="TEMPERATURA" ref={"temperatura"} onChange={this.handleChange} required type="number" maxLength={"5"} />
          </div>
          <div className='form-group' key={5} >
            <div> <h4>NOMBRE</h4> </div>
            <Form.Control type="text" maxLength="50" field="nombre" value={this.state.nombre} placeholder="NOMBRE" ref={"nombre"} onChange={this.handleChangeNombre} required />
          </div>
          <div className='form-group' key={6} >
            <div> <h4>APELLIDO</h4> </div>
            <Form.Control type="text" maxLength="50" field="aoellido" value={this.state.apellido} placeholder="APELLIDO" ref={"apellido"} onChange={this.handleChangeApellido} required />
          </div>
          <div className='form-group' key={7} >
            <div> <h4>CARGO</h4> </div>
            <Form.Control type="text" maxLength="50" field="cargo" value={this.state.cargo} placeholder="CARGO" ref={"cargo"} onChange={this.handleChangeCargo} required />
          </div>
          <div className='form-group' key={8} >
            <div> <h4> EMPRESA </h4> </div>
            <Form.Control type="text" maxLength="50" field="empresa" value={this.state.empresa} placeholder="EMPRESA" ref={"empresa"} onChange={this.handleChangeEmpresa} required />
          </div>
          {localStorage.getItem("id_sucursal") !== "12" ? <div className='form-group' key={9} >
            <div> <h4>PATENTE</h4> </div>
            <Form.Control type="text" maxLength="7" field="patente" value = {this.state.patente} placeholder="PATENTE" ref={"patente"} onChange={this.handleChangePatente} required />
          </div>
            : ""
          }
          {localStorage.getItem("id_sucursal") !== "12" ? <div className='form-group' key={10} >
              <div> <h4>MOTIVO VISITA</h4> </div>
                <Form.Control type="text" maxLength="50" field="motivo"  value = {this.state.motivo} placeholder="MOTIVO" ref={"motivo"} onChange={this.handleChangeMotivo} required />
             </div>
              : ""
          }
          <div className='form-group' key={2}>
            <div> <h4>USO MASCARILLA</h4> </div>
            <Form.Control as="select" field="mascarilla" onChange={this.handleChangeMascarilla} ref={"mascarilla"} placeholder="USO MASCARILLA" required >
              < option key={0} value={0}> {'NO'} </option>
              < option key={1} value={1} selected> {'SI'} </option>
            </Form.Control>
          </div>
          <div className='form-group' key={3}>
            <div> <h4>VISITA</h4> </div>
            <Form.Control as="select" field="visita" onChange={this.handleChangeVisita} id={3} key={3} required >
              < option key={3} value={0}> {'NO'} </option>
              < option key={4} value={1}> {'SI'} </option>
            </Form.Control>
          </div>
          {
            this.state.visita === "3" ?
              <div className='form-group' key={11} >
                <div> <h4>NÚMERO DE CREDENCIAL</h4> </div>
                <Form.Control type="text" maxLength="2" field="num" placeholder="NÚMERO CREDENCIAL" ref={"numero"} onChange={this.handleChange} required />
              </div>
              : ""
          }
          <div className='form-group' key={13} >
            <div> <h4> GENERAR ENCUESTA</h4> </div>
            <Button onClick={this.handleMostrarModal} disabled={this.state.rutcompletado}> Mostrar Encuesta  </Button>
            <Button onClick={this.handleMostrarModalEncuesta} disabled={this.state.rutcompletado}> Encuesta Manual </Button>
          </div>
          <Button onClick={() =>  this.getFieldValue() }>
              INGRESAR PERSONA
           </Button>
        </div>
        { this.state.mostrarModal === true ?
             (localStorage.getItem("id_sucursal") !== "1" ?
          <Mod show={this.state.mostrarModal} onHide={() => this.setState({ mostrarModal: false })} backdrop="static" keyboard={false}>
            <Mod.Header closeButton>
              <Mod.Title>CUESTIONARIO INGRESO A PLANTA (CONTINGENCIA COVID-19)</Mod.Title>
            </Mod.Header>
            <Mod.Body>
              <QRCode value={"https://www.accesos.sanjoseapps.cl/#/cuestionarioCovid_SGC_RG/?rut=" + this.state.rut} />
            </Mod.Body>
            <Mod.Footer>
              <Button variant="secondary" id="Cancelar" onClick={() => this.setState({ mostrarModal: false })}>
                Completado
              </Button>
            </Mod.Footer>
          </Mod>
           :
           <Mod show={this.state.mostrarModal} onHide={() => this.setState({ mostrarModal: false })} backdrop="static" keyboard={false}>
            <Mod.Header closeButton>
              <Mod.Title>CUESTIONARIO INGRESO A PLANTA (CONTINGENCIA COVID-19)</Mod.Title>
            </Mod.Header>
            <Mod.Body>
              <QRCode value={"https://www.accesos.sanjoseapps.cl/#/cuestionarioCovid/?rut=" + this.state.rut} />
            </Mod.Body>
            <Mod.Footer>
              <Button variant="secondary" id="Cancelar" onClick={() => this.setState({ mostrarModal: false })}>
                Completado
              </Button>
            </Mod.Footer>
          </Mod>
           )
          : ""
        }
      </div>
    );
  }
}


export default ModalIngreso;
