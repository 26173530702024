import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { Card, CardBody, Col, Input } from 'reactstrap';

class EditarPersona extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            rut: "",
            apellido: "",
            cargo: "",
            empresa: "",
            alertEnvioExitoso: false,
            patente: "",
            nombre: "",
            segundaPass: "",
            lista_usuarios: [],
            link: 'https://accesos.sanjoseapps.cl/api/credenciales/sinfoto.png',
            dv: "",
            admin: false,
            activo: false
        };
        this.handleChangeRut = this.handleChangeRut.bind(this);
        this.actualizarUsuario = this.actualizarUsuario.bind(this);
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.handleChangeApellido = this.handleChangeApellido.bind(this);
        this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
        this.usuarioAdministrador = this.usuarioAdministrador.bind(this);
        this.handleChangePatente = this.handleChangePatente.bind(this)
        this.handleChangeCargo = this.handleChangeCargo.bind(this);
        this.onChange = this.onChange.bind(this)
        this.handleChangePatente = this.handleChangePatente.bind(this)
        this.revisarImg = this.revisarImg.bind(this)
        this.usuarioActivo = this.usuarioActivo.bind(this);
        this.desformatearRut = this.desformatearRut.bind(this)
        this.traerUsuarios = this.traerUsuarios.bind(this);
        this.cargarDatos = this.cargarDatos.bind(this)
    }

    componentDidMount() {
        this.traerUsuarios();
    }

    traerUsuarios() {
        const obj = { idtbl_usuario: localStorage.getItem('idtbl_usuario') };
        try {
            var optionAxios = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            axios.post('https://accesos.sanjoseapps.cl/api/traerPersonasLista.php', obj,optionAxios).then(response => {
                const datos = response.data
                //     //console.log(datos);
                if (datos !== undefined) {
                    this.setState({ lista_usuarios: response.data })

                } else {
                    ////console.log("No existen codigos sin asignar");
                }
            })
        } catch (error) {
            console.error(error);
        }
    }


    usuarioActivo() {
        if (this.state.activo) {
            this.setState({ activo: false });
        } else {
            this.setState({ activo: true });
        }
    }


    desformatearRut(rut) {
        if (rut !== undefined) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");

                return actualLimpio
            }

        }

    }
    revisarImg(rut_usuario){
        const obj = { rut: this.desformatearRut(rut_usuario) };
        try {
            var optionAxios = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            axios.post('https://accesos.sanjoseapps.cl/api/buscarImagenRut.php',  obj,optionAxios).then(response => {
                const datos = response.data
                if (datos !== undefined) {
                    let valor = response.data;
                    valor = "https://accesos.sanjoseapps.cl/api/credenciales/" + valor;
                    this.setState({ link: valor })

                } else {
                    ////console.log("No existen codigos sin asignar");
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    cargarDatos(selectedOptions) {

        if(selectedOptions.length > 0 ){
            let valor =  selectedOptions[0];
            const parametros = valor.split(" ");
          //   this.setState({rut: parametros[0]},()=> this.obtenerIngresosDiarios() );

        let rut = parametros[0];
        let lista_usuarios = this.state.lista_usuarios
        let nombre_usuario
        let patente
        let apellido_usuario
        let empresa
        let rut_usuario
        let cargo
        let activo;
        lista_usuarios.map((lista) => { if (lista.rut === rut) { nombre_usuario = lista.nombre } });
        lista_usuarios.map((lista) => { if (lista.rut === rut) { apellido_usuario = lista.apellido } });
        lista_usuarios.map((lista) => { if (lista.rut === rut) { rut_usuario = lista.rut } });
        lista_usuarios.map((lista) => { if (lista.rut === rut) { empresa = lista.empresa } });
        lista_usuarios.map((lista) => { if (lista.rut === rut) { cargo = lista.cargo } });
        lista_usuarios.map((lista) => { if (lista.rut === rut) { patente = lista.patente } });
        lista_usuarios.map((lista) => { if (lista.rut === rut) { activo = lista.activo } });
        this.setState({ nombre: nombre_usuario });
        this.setState({ apellido: apellido_usuario });
        this.setState({ rut: rut_usuario });
        this.setState({ empresa: empresa });
        this.setState({ cargo: cargo });
        this.setState({patente:patente})
        this.revisarImg(rut_usuario)
        if( activo === "1"){
            this.setState({ activo : true});

        }else{
            this.setState({ activo : false});

        }
    }
    }

    handleChangeNombre(event) {
        event.preventDefault();
        this.setState({ nombre: event.target.value });
    }
    handleChangeRut(event) {
        event.preventDefault();
        this.setState({ rut: event.target.value });
    }
    handleChangeApellido(event) {
        event.preventDefault();
        this.setState({ apellido: event.target.value });
    }
    handleChangeCargo(event) {
        event.preventDefault();
        this.setState({ cargo: event.target.value });
    }
    handleChangeEmpresa(event) {
        event.preventDefault();
        this.setState({ empresa: event.target.value });
    }
    handleChangePatente(event) {
        event.preventDefault();
        this.setState({ patente: event.target.value });
    }
    usuarioAdministrador() {
        if (this.state.admin) {
            this.setState({ admin: false });
        } else {
            this.setState({ admin: true });
        }
    }

    formateaRut(rut) {
        if (rut !== undefined) {
            var actual = rut.replace(/^0+/, "");
            if (actual !== '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 === 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            this.setState({ rut: actualLimpio });
            this.setState({ dv: dv })
        }
    }

    actualizarUsuario(event) {
        event.preventDefault();
        let estado
        if (this.state.admin) {
            estado = 0
        } else {
            estado = 1
        }
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const obj = { patente: this.state.patente, rut: this.state.rut, nombre: this.state.nombre, apellido: this.state.apellido, cargo: this.state.cargo, empresa: this.state.empresa, activo : this.state.activo};
        try {
                axios.post('https://accesos.sanjoseapps.cl/api/actualizarPersona.php', obj, optionAxios).then(response => {
                    const datos = response.data;
                    if (datos !== undefined) {
                        this.setState({ alertEnvioExitoso: true });
                  //      //console.log(this.state.alertEnvioExitoso)
                        setTimeout(() => { this.setState({ alertEnvioExitoso: false }) }, 20000)
                        this.uploadFile(this.state.imagen)
                        let rut =this.desformatearRut(this.state.rut)
                        let nuevolink = 'https://accesos.sanjoseapps.cl/api/credenciales/' + rut + '.jpg';
                        this.setState({link: nuevolink })
                        this.traerUsuarios();
                     };
                });

        } catch (error) {
            ////console.log(error);
        }
    }

    onChange = e => {
        this.setState({
            imagen: e.target.files[0]
        })

    }
    async uploadFile(file) {
        const formData = new FormData();
        //console.log("actualizar imagen")
        let nombre = this.desformatearRut(this.state.rut)
        formData.append('avatar', file)
        formData.append('rut_foto', nombre)
        return await axios.post('https://accesos.sanjoseapps.cl/api/subirImagen.php', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });

    }
    render() {
        return (
            <React.Fragment>

                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">En esta pestaña se podrá editar un usuario</h5>
                            </div>
                            <Alert variant="success" show={this.state.alertEnvioExitoso} onClose={() => this.setState({ alertEnvioExitoso: false })} dismissible>
                                <Alert.Heading>¡Actualización Exitosa!</Alert.Heading>
                                <p>
                                    Se ha actualizado la persona {this.state.nombre}.
                                    </p>
                            </Alert>

                            <form onSubmit={this.actualizarUsuario} className="login__form active" id="register_">
                            <div className="row">
                                <div className="col" style={{ "marginLeft": "0" }}>
                                    <img src={this.state.link} style={{ width: "20%" }} ></img>
                                    <input type="file" accept=".jpg,.jpeg,.png"   onChange={this.onChange} name="id_proof" />
                                </div>
                            </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>
                                            <h4> USUARIOS </h4>
                                        </div>
                                        <div>

                                            <Form.Group controlId="dob">
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="Persona"
                              options={this.state.lista_usuarios.map( (r) => { return ( r.rut + " - " +r.nombre + " "  + r.apellido).toUpperCase() })}
                              placeholder="Escoger Persona..."
                              onChange={this.cargarDatos}
                           />
                            </Form.Group>

                                        </div>
                                    </div>
                                </div>
                                <br></br>


                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>
                                            <h4>     NOMBRES </h4>
                                        </div>
                                        <div>
                                            <Form.Control type="text" placeholder="Nombre" value={this.state.nombre} onChange={this.handleChangeNombre} maxLength={45} required />

                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>
                                            <h4>     APELLIDOS</h4>
                                        </div>
                                        <div>
                                            <Form.Control type="text" placeholder="Apellido" maxLength={45} value={this.state.apellido} onChange={this.handleChangeApellido} required />

                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>  <h4>  EMPRESA </h4></div>
                                        <div>
                                            <Form.Control type="text" placeholder="Empresa" value={this.state.empresa} onChange={this.handleChangeEmpresa} maxLength={45}  />
                                        </div>
                                    </div>
                                </div>     <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>  <h4>  CARGO </h4></div>
                                        <div>
                                            <Form.Control type="text" placeholder="Cargo" value={this.state.cargo} onChange={this.handleChangeCargo} maxLength={45}  />
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>  <h4>  PATENTE </h4></div>
                                        <div>
                                            <Form.Control type="text" placeholder="Patente" value={this.state.patente} onChange={this.handleChangePatente} maxLength={10}  />
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.usuarioActivo()} checked={this.state.activo} />  <h4> PESONA HABILITADA PARA EL INGRESO  </h4>
                                    </div>
                                </div>
                            </div>
                            <br></br>

                                <div className="row">
                                    <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                        <div>
                                            <Form.Control type="submit" value="Actualizar Persona" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}

export default EditarPersona;
