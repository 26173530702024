import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Form } from 'react-bootstrap';
import { Card, CardBody, Col, Input } from 'reactstrap';

class RegistroSucursal extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            nombre: "",
            cargo: false,
            empresa:false,
            mascarilla:false,
            observacion: false,
            patente: false,
            visita:false,
            temperatura: false,
            motivo:false,
            alertEnvioExitoso: false
        };
        this.registrarSucursales = this.registrarSucursales.bind(this);
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.pregCargo = this.pregCargo.bind(this);
        this.pregEmpresa = this.pregEmpresa.bind(this);
        this.pregMascarilla = this.pregMascarilla.bind(this);
        this.pregObservacion = this.pregObservacion.bind(this);
        this.pregPatente= this.pregPatente.bind(this);
        this.pregTemperatura = this.pregTemperatura.bind(this);
        this.pregVisita = this.pregVisita.bind(this);
        this.pregMotivo = this.pregMotivo.bind(this);
    }

    pregCargo() {
        if (this.state.cargo) {
            this.setState({ cargo: false });
        } else {
            this.setState({ cargo: true });
        }
    }


    pregMotivo(){
        if (this.state.motivo) {
            this.setState({ motivo: false });
        } else {
            this.setState({ motivo: true });
        }
    }

    pregEmpresa() {
        if (this.state.empresa) {
            this.setState({ empresa: false });
        } else {
            this.setState({ empresa: true });
        }
    }


    pregMascarilla () {
        if (this.state.mascarilla) {
            this.setState({ mascarilla: false });
        } else {
            this.setState({ mascarilla: true });
        }
    }


    pregObservacion() {
        if (this.state.observacion) {
            this.setState({ observacion: false });
        } else {
            this.setState({ observacion: true });
        }
    }


    pregPatente() {
        if (this.state.patente) {
            this.setState({ patente: false });
        } else {
            this.setState({ patente: true });
        }
    }


    pregTemperatura() {
        if (this.state.temperatura) {
            this.setState({ temperatura: false });
        } else {
            this.setState({ temperatura: true });
        }
    }

    pregVisita () {
        if (this.state.visita) {
            this.setState({ visita: false });
        } else {
            this.setState({ visita: true });
        }
    }

    registrarSucursales(event){
        event.preventDefault();
        const obj = { nombre : this.state.nombre, visita: this.state.visita, temperatura : this.state.temperatura, mascarilla: this.state.mascarilla, cargo : this.state.cargo, empresa :this.state.empresa, patente: this.state.patente, observacion: this.state.observacion, motivo : this.state.motivo };
        var optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
                axios.post('https://accesos.sanjoseapps.cl/api/registrarSucursal.php', obj, optionAxios).then(response => {
                    const datos = response.data;
                    if (datos !== undefined) {
                      this.setState({sucursales: datos});
                      alert("Sucursal ingresada")
                    };
                });
        } catch (error) {
            console.log(error);
        }
    }

    handleChangeNombre(event) {
        event.preventDefault();
        this.setState({ nombre: event.target.value });
    }


    render() {
        return (
            <React.Fragment>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h4 className="bold-text">En esta pestaña se podrá crear una sucursal</h4>
                            </div>
                            <Alert variant="success" show={this.state.alertEnvioExitoso} onClose={() => this.setState({ alertEnvioExitoso: false })} dismissible>
                                <Alert.Heading>¡Registro Exitoso!</Alert.Heading>
                                <p>
                                    Se ha registrado la sucursal {this.state.nombre}.
                                </p>
                            </Alert>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>
                                        <h4>  NOMBRE SUCURSAL </h4>
                                    </div>
                                    <div>
                                        <Form.Control type="text" placeholder="Nombre" value={this.state.nombre} onChange={this.handleChangeNombre} maxLength={45} required />
                                    </div>
                                </div>
                            </div>
                            <br></br> <br></br>
                            <h3>  CONFIGURACION DE CAMPOS DE INGRESO </h3>
                            <br></br> <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.pregTemperatura()} />  <h4> CUESTIONARIO: TEMPERATURA  </h4>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.pregMascarilla()} />  <h4> CUESTIONARIO: USO MASCARILLA  </h4>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.pregCargo()} />  <h4> CUESTIONARIO:  CARGO  </h4>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.pregEmpresa()} />  <h4> CUESTIONARIO: EMPRESA  </h4>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.pregPatente()} />  <h4> CUESTIONARIO: PATENTE  </h4>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.pregVisita()} />  <h4> CUESTIONARIO: VISITA  </h4>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.pregObservacion()} />  <h4> CUESTIONARIO: OBSERVACIÓN  </h4>
                                    </div>
                                </div>
                            </div>
                            <br></br>

                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>    </div>
                                    <div>
                                        <Input type="checkbox" onChange={e => this.pregMotivo()} />  <h4> CUESTIONARIO: MOTIVO  </h4>
                                    </div>
                                </div>
                            </div>                                    <br></br>
                            <div className="row">
                                <div className="col-md-6" style={{ "marginLeft": "0" }}>
                                    <div>
                                        <Form.Control onClick={this.registrarSucursales} type="submit" value="Registrar Sucursal" />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}

export default RegistroSucursal;
